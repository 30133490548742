import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC, useCallback } from "react";
import {
  muiStylesButtons,
  muiStylesDialog,
} from "./muiStylesEmployeeDisableDialogConfirmation";
import * as styles from "./employeeDisableConfirmationDialog.module.scss";
import { useDispatch } from "react-redux";
import { disableUsers } from "../../../../redux/actions/userDisablingActions";

const useButtonStyles = makeStyles(muiStylesButtons);
const useDialogStyles = makeStyles(muiStylesDialog);

interface AddEmployeeDialogProps {
  isOpen: boolean;
  ids: number | number[];
  onClose: () => void;
}

const EmployeeDisableConfirmationDialog: FC<AddEmployeeDialogProps> = ({
  ids,
  isOpen,
  onClose,
}) => {
  const buttonClasses = useButtonStyles();
  const classes = useDialogStyles();
  const dispatch = useDispatch();
  const close = useCallback(() => {
    onClose();
  }, [onClose]);

  const onConfirm = () => {
    if (Array.isArray(ids)) {
      dispatch(disableUsers({ ids }));
    } else {
      dispatch(disableUsers({ ids: [ids] }));
    }

    close();
  };

  return (
    <Dialog
      className={classes.backgroundDialog}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={close}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>
          Disabling User
          {Array.isArray(ids) && ids.length > 0 ? <span>s</span> : ""}{" "}
          confirmation
        </h5>
        <IconButton size="small" onClick={close}>
          <CloseIcon />
        </IconButton>
        <p className={styles.info}>
          Are you sure you want to disable the selected User
          {Array.isArray(ids) && ids.length > 0 ? <span>s</span> : ""} ?
        </p>
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={close}
          className={buttonClasses.secondaryButton}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          form="add-department-form"
          className={buttonClasses.warningButton}
        >
          Disable
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeDisableConfirmationDialog;

// extracted by mini-css-extract-plugin
export var h1Typography = "mobileSearchTopBar-module--h1-typography--3G0iF";
export var h2Typography = "mobileSearchTopBar-module--h2-typography--1DFaj";
export var h3Typography = "mobileSearchTopBar-module--h3-typography--1oUb5";
export var h4Typography = "mobileSearchTopBar-module--h4-typography--2SRoo";
export var h5Typography = "mobileSearchTopBar-module--h5-typography--_61DF";
export var h6Typography = "mobileSearchTopBar-module--h6-typography--Kuf5y";
export var pTypography = "mobileSearchTopBar-module--p-typography--Jdc9K";
export var info = "mobileSearchTopBar-module--info--2vd1h";
export var buttonTypography = "mobileSearchTopBar-module--button-typography--3kMas";
export var formLabelTypography = "mobileSearchTopBar-module--form-label-typography--2u13B";
export var menuTabTypography = "mobileSearchTopBar-module--menu-tab-typography--UF_fZ";
export var linkStyle = "mobileSearchTopBar-module--link-style--2id4W";
export var postTypography = "mobileSearchTopBar-module--post-typography--TF0pA";
export var topbarCustom = "mobileSearchTopBar-module--topbar-custom--3TkQp";
import React from "react";
import Seo from "../components/seo";
import AccountSettings from "../components/AccountSettings";
import StandardLayout from "../components/Layout/StandardLayout";

const AccountSettingsPage = () => (
  <StandardLayout>
    <Seo title="Account Settings" />
    <AccountSettings />
  </StandardLayout>
);

export default AccountSettingsPage;

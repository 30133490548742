// extracted by mini-css-extract-plugin
export var h1Typography = "userResult-module--h1-typography--2OVUY";
export var h2Typography = "userResult-module--h2-typography--2ldv7";
export var h3Typography = "userResult-module--h3-typography--2WFqz";
export var h4Typography = "userResult-module--h4-typography--1EVmV";
export var h5Typography = "userResult-module--h5-typography--1DAjU";
export var h6Typography = "userResult-module--h6-typography--37sDA";
export var pTypography = "userResult-module--p-typography--Q4g4G";
export var info = "userResult-module--info--3qEu3";
export var buttonTypography = "userResult-module--button-typography--2Qln9";
export var formLabelTypography = "userResult-module--form-label-typography--3x5KW";
export var menuTabTypography = "userResult-module--menu-tab-typography--3LT4o";
export var linkStyle = "userResult-module--link-style--3_V45";
export var postTypography = "userResult-module--post-typography--3MTxe";
export var container = "userResult-module--container--rLSE6";
export var userInfo = "userResult-module--user-info--1pbW6";
export var text = "userResult-module--text--1ZhO7";
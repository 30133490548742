import { Theme, createStyles } from "@material-ui/core/styles";
import {
  backgroundBadgeColor,
  primaryColor,
  secondaryColor,
} from "../../../styles/mui/theme";

const muiStylesUserAvatar = (theme: Theme) =>
  createStyles({
    root: {
      "display": "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    color: {
      "color": primaryColor,
      "backgroundColor": backgroundBadgeColor,
      "font-size": "18px",
    },
    colorChecked: {
      color: secondaryColor,
      backgroundColor: primaryColor,
    },
    large: {
      color: primaryColor,
      backgroundColor: backgroundBadgeColor,
      width: "150px",
      height: "150px",
      fontSize: "3em",
    },
  });

export default muiStylesUserAvatar;

// extracted by mini-css-extract-plugin
export var h1Typography = "userList-module--h1-typography--3Qg0m";
export var h2Typography = "userList-module--h2-typography--BAdHO";
export var h3Typography = "userList-module--h3-typography--UYlXp";
export var h4Typography = "userList-module--h4-typography--3a5u_";
export var h5Typography = "userList-module--h5-typography--2gdJp";
export var h6Typography = "userList-module--h6-typography--2LorO";
export var pTypography = "userList-module--p-typography--28BSe";
export var info = "userList-module--info--1e_Ln";
export var buttonTypography = "userList-module--button-typography--3kMsv";
export var formLabelTypography = "userList-module--form-label-typography--1Fm1H";
export var menuTabTypography = "userList-module--menu-tab-typography--ThHwn";
export var linkStyle = "userList-module--link-style--2R6g0";
export var postTypography = "userList-module--post-typography--238lP";
export var userListContainer = "userList-module--user-list-container--3L1A7";
export var paginationContainer = "userList-module--paginationContainer--3y45O";
export var paginationInfo = "userList-module--pagination-info--_MWm1";
export var navBar = "userList-module--nav-bar--1k-WO";
export var navButtonLast = "userList-module--nav-button-last--3YklO";
export var navButton = "userList-module--nav-button--2UtHN";
export var uncheck = "userList-module--uncheck--6adGV";
export var uncheckHidden = "userList-module--uncheck-hidden--1uP7z";
export var buttonContainer = "userList-module--button-container--5dVel";
import { RootState } from "../../store";
import { Comment } from "../../../api/pulseAPI";

export const selectPostComments = (postId: number) => (
  state: RootState
): Comment[] | undefined => {
  return state.pulseComments.comments?.[postId]?.comments;
};

export const selectCommentsFetchArgs = (postId: number) => (
  state: RootState
) => {
  return state.pulseComments.comments?.[postId]?.nextParams;
};

export const selectCommentsFetchLoading = (postId: number) => (
  state: RootState
) => {
  return state.pulseComments.comments?.[postId]?.fetch.loading;
};

import { RootState } from "../store";

export const selectEmailSendingSucceeded = (state: RootState) => {
  return state.resetPasswordEmail.processed;
};

export const selectEmailSendingInProgress = (state: RootState) => {
  return state.resetPasswordEmail.loading;
};

export const selectEmailSendingFailed = (state: RootState) => {
  return {
    error: state.resetPasswordEmail.error,
    errorMessage: state.resetPasswordEmail.errorMessage,
    errorDetails: state.resetPasswordEmail.errorDetails,
  };
};
export const selectPasswordResetSucceeded = (state: RootState) => {
  return state.resetPasswordConfirm.processed;
};

export const selectPasswordResetInProgress = (state: RootState) => {
  return state.resetPasswordConfirm.loading;
};

export const selectPasswordResetFailed = (state: RootState) => {
  return {
    error: state.resetPasswordConfirm.error,
    errorMessage: state.resetPasswordConfirm.errorMessage,
    errorDetails: state.resetPasswordConfirm.errorDetails,
  };
};

export const selectPasswordChangeLoading = (state: RootState) => {
  return state.passwordChange.loading;
};

export const selectPasswordChangeError = (state: RootState) => {
  return {
    error: state.passwordChange.error,
    message: state.passwordChange.errorMessage,
    details: state.passwordChange.errorDetails,
  };
};

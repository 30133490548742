import React from "react";
import { OrganizationStructure } from "../components/OrganizationStructure";
import Seo from "../components/seo";
import Loader from "../components/AppLoader";
import { useSelector } from "react-redux";
import { selectUsersLoading } from "../redux/selectors/usersSelectors";
import { selectDepartmentsLoading } from "../redux/selectors/departmentsSelectors";
import StandardLayout from "../components/Layout/StandardLayout";

export const OrganizationStructurePage = () => {
  const usersLoading = useSelector(selectUsersLoading);
  const departmentsLoading = useSelector(selectDepartmentsLoading);

  return (
    <StandardLayout>
      <Loader loading={usersLoading || departmentsLoading} />
      <Seo title="Organization structure" />
      <OrganizationStructure />
    </StandardLayout>
  );
};

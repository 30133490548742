import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { FC } from "react";
import SecondaryButton from "../../Buttons/SecondaryButton";
import CloseIcon from "@material-ui/icons/Close";
import { muiStylesPopup } from "../muiStylesPopup";
import TertiaryButton from "../../Buttons/TertiaryButton";

const useStyles = makeStyles(muiStylesPopup);

interface Props {
  content: string;
  header: string;
  open: boolean;
  deleteMode?: boolean;
  onAcceptButton: () => void;
  onCancelButton: () => void;
}

const DeletePopup: FC<Props> = ({
  header,
  content,
  open,
  deleteMode,
  onAcceptButton,
  onCancelButton,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={(event, reason) =>
        reason === "escapeKeyDown" && onCancelButton()
      }
      className={classes.backgroundDialog}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>{header}</h5>
        <IconButton size="small" onClick={onCancelButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>{content}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <SecondaryButton text="Cancel" event={onCancelButton} />
        <TertiaryButton
          text={deleteMode ? "Delete" : "Discard"}
          event={onAcceptButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopup;

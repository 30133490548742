import React, { ChangeEvent, FC } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { bulkUpload } from "../../redux/actions/bulkUploadActions";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { muiStylesEmployeesBulkUpload } from "./muistylesEmployeeBulkUpload";
import { dispatchEnqueueSnackbar } from "../../redux/actions/notificationsActions";
import { getProperCSVFile } from "../../utils";

const useStyles = makeStyles(muiStylesEmployeesBulkUpload);

export const EmployeeBulkUpload: FC<{
  onUpload?: () => void;
}> = ({ onUpload }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const fileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);

    const reader = new FileReader();

    reader.onload = () => {
      const content = reader.result as string;
      const properFile = getProperCSVFile(file, content);

      if (properFile) {
        onUpload && onUpload();
        dispatch(bulkUpload(properFile));
      } else {
        dispatchEnqueueSnackbar({
          message:
            "Error: The file is not recognized as csv or file's headers are incorrect.",
        });
      }
    };

    file && reader.readAsText(file);

    // clear selected file to enable the reload
    event.target.value = "";
    event.target.files = null;
  };

  return (
    <div>
      <label htmlFor="btn-upload">
        <input
          id="btn-upload"
          name="btn-upload"
          style={{ display: "none" }}
          type="file"
          accept=".csv"
          onChange={(e) => fileSelected(e)}
        />
        <>
          <Button
            className={clsx(classes.btnAddDepartment, classes.primaryButton)}
            variant="outlined"
            component="span"
          >
            Upload CSV
          </Button>
        </>
      </label>
    </div>
  );
};

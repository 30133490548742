import { createStyles } from "@material-ui/core";
import {
  modalBackgroundColor,
  textInputBackground,
  theme,
} from "../../../styles/mui/theme";

export const muiStylesPopup = () =>
  createStyles({
    backgroundDialog: {
      "& .MuiBackdrop-root": { "background-color": modalBackgroundColor },
    },
    dialogPaper: {
      borderRadius: 8,
    },
    dialogContent: {
      "padding": "0px 24px 16px 40px",
      [theme.breakpoints.down("sm")]: {
        "padding": "0 16px 16px",
        "text-align": "center",
      },
      "& .MuiTypography-paragraph": {
        padding: "0 1rem",
      },
      "& .MuiFormHelperText-root.Mui-error:first-letter": {
        textTransform: "uppercase",
      },
      "& .MuiFilledInput-root": {
        "background": textInputBackground,
        "&:hover": {
          background: textInputBackground,
        },
        "&:focus": {
          background: textInputBackground,
        },
        "&:autofill": {
          background: textInputBackground,
        },
        "&&&:before": {
          borderBottom: "none",
        },
      },
    },
    dialogTitle: {
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "flex-start",
      "paddingLeft": "40px",
      "& .MuiIconButton-root": {
        position: "relative",
        left: ".5rem",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "1rem",
      },
      "& h5": {
        marginTop: "1.75rem",
        marginBottom: "1rem",
        [theme.breakpoints.down("sm")]: {
          margin: 0,
        },
      },
    },
    dialogActions: {
      "padding": "20px 40px",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem",
      },
      "& .MuiButton-root": {
        padding: "6px 40px",
        [theme.breakpoints.down("sm")]: {
          padding: "6px 12px",
        },
      },
    },
  });

import { createStyles } from "@material-ui/core";
import { pulseFormBackground } from "../../../styles/mui/theme";

export const muiStylesCommentForm = () =>
  createStyles({
    bodyInput: {
      "width": "100%",
      "background": pulseFormBackground,
      "borderRadius": "2px",
      "& .MuiInput-root": {
        padding: "8px 16px",
      },
    },
    noBorderBottom: {
      "&&&:before": {
        borderBottom: "none",
      },
      "&&&:after": {
        borderBottom: "none",
      },
    },
  });

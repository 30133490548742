import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { fontColorPrimary, secondaryColor } from "../../../../styles/mui/theme";
import Menu from "@material-ui/core/Menu";
import { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles(() => ({
  root: {
    "&:focus": {
      "backgroundColor": secondaryColor,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: fontColorPrimary,
      },
    },
  },
}))(MenuItem);

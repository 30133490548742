import * as styles from "./employeeList.module.scss";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNetworkUsers } from "../../../redux/actions/networkUsersActions";
import User from "./Employee";
import NextButton from "../../../icons/button-next.svg";
import PreviousButton from "../../../icons/button-previous.svg";
import UncheckButton from "../../../icons/button-uncheck.svg";

import { makeStyles } from "@material-ui/core/styles";
import muiStylesEmployeeList from "./muiStylesEmployeeList";
import {
  selectNetworkUserCount,
  selectNetworkUsers,
} from "../../../redux/selectors/networkUsersSelectors";
import AddActivityForm from "../../Activity/ActivityForms/AddActivityForm";
import { Button, Dialog } from "@material-ui/core";
import {
  changeAttendees,
  removeAllAttendees,
} from "../../../redux/actions/activityFormActions";
import { selectAttendees } from "../../../redux/selectors/activityFormSelectors";
import { useIsSmall } from "../../../utils";
import EmployeeDisableConfirmationDialog from "./EmployeeDisableConfirmationDialog";
import { selectUserDisablingProcessed } from "../../../redux/selectors/userDisablingSelectors";
import clsx from "clsx";

interface UserListProps {
  activityFilter?: string | number;
  departmentFilter?: string | number;
}

const useStyles = makeStyles(muiStylesEmployeeList);
const pageLimit = 10;

const EmployeeList: FC<UserListProps> = ({
  activityFilter,
  departmentFilter,
}) => {
  const classes = useStyles();
  const isSmall = useIsSmall();
  const dispatch = useDispatch();

  const userData = useSelector(selectNetworkUsers);
  const userCount = useSelector(selectNetworkUserCount);
  const userDisablingProcessed = useSelector(selectUserDisablingProcessed);
  const initialCheckedUsers = useSelector(selectAttendees);

  const [curPage, setCurPage] = useState(1);
  const [checkedUsers, setCheckedUsers] = useState<number[]>(
    initialCheckedUsers
  );
  const modifyCheckedUsers = (add: boolean, userId: number) => {
    const modifiedCheckedUsers = [...checkedUsers];

    if (add) {
      modifiedCheckedUsers.push(userId);
      dispatch(changeAttendees(modifiedCheckedUsers));
      setAllUsersCheck(true);
    } else {
      modifiedCheckedUsers.splice(
        modifiedCheckedUsers.findIndex((id) => id === userId),
        1
      );

      if (modifiedCheckedUsers.length === 0) {
        setAllUsersCheck(false);
      }
      dispatch(changeAttendees(modifiedCheckedUsers));
    }
  };
  const [allUsersCheck, setAllUsersCheck] = React.useState(false);
  const handleAllUsersCheckboxChange = () => {
    setAllUsersCheck(false);
  };

  const [disableConfirmation, setDisableConfirmation] = useState(false);
  const openDisableConfirmation = () => {
    setDisableConfirmation(true);
  };
  const onDisableConfirmationClose = () => setDisableConfirmation(false);

  const [openForm, setOpenForm] = React.useState(false);

  const handleClickOpenForm = () => {
    setOpenForm(true);
  };

  const handleClickCloseForm = () => {
    setOpenForm(false);
  };

  const nextPage = () => {
    const nextPageParam = curPage + 1;

    if (userCount > curPage * pageLimit) {
      dispatch(
        fetchNetworkUsers({
          nextPageParam,
          pageLimit,
          activityFilter,
          departmentFilter,
        })
      );
      setCurPage(curPage + 1);
    }
  };

  const previousPage = () => {
    if (curPage > 1) {
      const nextPageParam = curPage - 1;

      dispatch(
        fetchNetworkUsers({
          nextPageParam,
          pageLimit,
          activityFilter,
          departmentFilter,
        })
      );
      setCurPage(curPage - 1);
    }
  };

  const reactToCheckboxChange = () => {
    if (!allUsersCheck) {
      dispatch(changeAttendees([]));
    }
  };

  const reactToFilterChange = () => {
    if (activityFilter) {
      setCurPage(1);
      const nextPageParam = 1;

      dispatch(
        fetchNetworkUsers({
          nextPageParam,
          pageLimit,
          activityFilter,
          departmentFilter,
        })
      );
    }
  };

  useEffect(() => {
    reactToFilterChange();
  }, [activityFilter, departmentFilter]);

  useEffect(() => {
    reactToCheckboxChange();
  }, [allUsersCheck, isSmall]);

  useEffect(() => {
    setCheckedUsers(initialCheckedUsers);
    setAllUsersCheck(initialCheckedUsers.length > 0);
  }, [initialCheckedUsers]);

  useEffect(() => {
    if (userDisablingProcessed) {
      setCurPage(1);
      const nextPageParam = 1;

      dispatch(
        fetchNetworkUsers({
          nextPageParam,
          pageLimit,
          activityFilter,
          departmentFilter,
        })
      );
    }
  }, [userDisablingProcessed]);

  useEffect(() => {
    return function cleanup() {
      dispatch(removeAllAttendees());
    };
  }, []);

  return (
    <>
      <EmployeeDisableConfirmationDialog
        ids={initialCheckedUsers}
        isOpen={disableConfirmation}
        onClose={onDisableConfirmationClose}
      />
      <div className={styles.userListContainer}>
        <div className={styles.navBar}>
          {!isSmall && (
            <>
              <div>
                <img
                  className={
                    allUsersCheck ? styles.uncheck : styles.uncheckHidden
                  }
                  role="button"
                  src={UncheckButton}
                  onClick={() => handleAllUsersCheckboxChange()}
                  alt="checkbox_unchecks_all_users"
                />
              </div>
              <span className={styles.buttonContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  className={
                    checkedUsers && checkedUsers.length > 0
                      ? classes.button
                      : classes.buttonHidden
                  }
                  onClick={handleClickOpenForm}
                >
                  New Activity
                </Button>

                <Button
                  variant="contained"
                  className={
                    checkedUsers && checkedUsers.length > 0
                      ? classes.buttonDisable
                      : classes.buttonHidden
                  }
                  onClick={openDisableConfirmation}
                >
                  Disable users
                </Button>
                <Dialog
                  fullScreen
                  open={openForm}
                  onClose={handleClickCloseForm}
                >
                  <AddActivityForm
                    open={openForm}
                    handleClose={handleClickCloseForm}
                  />
                </Dialog>
              </span>
            </>
          )}

          <div className={styles.paginationContainer}>
            <div className={styles.paginationInfo}>
              {curPage > 1 ? curPage - 1 : ""}1-
              {userCount < pageLimit * curPage
                ? userCount
                : pageLimit * curPage}{" "}
              of {userCount}
            </div>
            <img
              role="button"
              className={styles.navButton}
              src={PreviousButton}
              onClick={previousPage}
              alt="pagination_button_backward"
            />
            <img
              role="button"
              className={clsx(styles.navButton, styles.navButtonLast)}
              src={NextButton}
              onClick={nextPage}
              alt="pagination_button_forward"
            />
          </div>
        </div>
        <div>
          {userData.map((user) => {
            return (
              <User
                key={user.id}
                firstName={user.firstName}
                lastName={user.lastName}
                email={user.email}
                id={user.id}
                activityTypes={user.activityTypes}
                role={user.role}
                team={user.team}
                isActive={user.isActive}
                checkedUsers={checkedUsers}
                modifyCheckedUsers={modifyCheckedUsers}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EmployeeList;

import { Theme } from "@material-ui/core/styles";

export const desktopBreakpoint = (theme: Theme) => theme.breakpoints.up("lg");
export const mobileBreakpoint = (theme: Theme) => theme.breakpoints.down("md");

export const linkStyle = {
  textDecoration: "none",
  color: "inherit",
};

export const drawerWidth = "240px";

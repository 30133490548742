import * as styles from "./mobileCalendarTopBar.module.scss";
import React, { FC } from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CloseButton from "../../../../icons/button-close.svg";
import FilterButton from "../../../../icons/button-filter.svg";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import muiStylesMobileCalendarTopBar from "./muiStylesMobileCalendarTopBar";
import { executeScroll } from "../../../../utils";

const useStyles = makeStyles(muiStylesMobileCalendarTopBar);

interface Props {
  name?: string | null;
  toggleFiltering(): void;
  filterByMe: boolean;
  changeFilterByMe(arg0: boolean): void;
}

const MobileCalendarTopBar: FC<Props> = ({
  name,
  toggleFiltering,
  filterByMe,
  changeFilterByMe,
}) => {
  const classes = useStyles();

  const disableFiltering = () => {
    changeFilterByMe(false);
  };

  return (
    <AppBar className={classes.root}>
      <Toolbar className={styles.topBarCustom}>
        <div>
          <img
            role="button"
            className={styles.filterButton}
            src={FilterButton}
            alt="filter-button"
            onClick={toggleFiltering}
          />
        </div>
        <Typography variant="h6" className={classes.title}>
          {name}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          href="#outlined-buttons"
          className={classes.today}
          onClick={() => executeScroll(true, "day--info-today")}
        >
          Today
        </Button>
      </Toolbar>
      {filterByMe ? (
        <div className={styles.filterRemoveContainer}>
          <span>Showing only My Events</span>
          <img
            onClick={disableFiltering}
            className={styles.filterRemoveContainerButton}
            src={CloseButton}
            alt="Close button"
            role="button"
          />
        </div>
      ) : null}
    </AppBar>
  );
};

export default MobileCalendarTopBar;

import { Theme, createStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../styles/mui/theme";

const muiStylesMobileCalendar = (theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    moreContainer: {
      marginBottom: "150px",
    },
    more: {
      backgroundColor: primaryColor,
    },
  });

export default muiStylesMobileCalendar;

// extracted by mini-css-extract-plugin
export var h1Typography = "mobileFiltering-module--h1-typography--3ADYM";
export var h2Typography = "mobileFiltering-module--h2-typography--2TB9w";
export var h3Typography = "mobileFiltering-module--h3-typography--1453J";
export var h4Typography = "mobileFiltering-module--h4-typography--124i3";
export var h5Typography = "mobileFiltering-module--h5-typography--Npa6d";
export var h6Typography = "mobileFiltering-module--h6-typography--1QXh3";
export var pTypography = "mobileFiltering-module--p-typography--BFeWW";
export var info = "mobileFiltering-module--info--1iW-U";
export var buttonTypography = "mobileFiltering-module--button-typography--2aTZo";
export var formLabelTypography = "mobileFiltering-module--form-label-typography--1r5pT";
export var menuTabTypography = "mobileFiltering-module--menu-tab-typography--2EpqP";
export var linkStyle = "mobileFiltering-module--link-style--3yst7";
export var postTypography = "mobileFiltering-module--post-typography--Qysgh";
export var filteringContainer = "mobileFiltering-module--filtering--container--3g6CX";
export var filteringContainerInput = "mobileFiltering-module--filtering--container-input--1cftg";
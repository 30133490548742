import { RootState } from "../store";

export const selectUsers = (state: RootState) => state.users.users;

export const selectUserCount = (state: RootState) => state.users.userCount;

export const selectUserById = (userId: string) => (store: RootState) => {
  return store.users.users.find(({ id }) => id === Number(userId));
};

export const selectUsersLoading = (state: RootState) => state.users.loading;

import DeclineIcon from "../../../icons/activity-status/icon-decline.svg";
import GoingIcon from "../../../icons/activity-status/icon-going.svg";
import TentativeIcon from "../../../icons/activity-status/icon-tentative.svg";

interface Status {
  [key: string]: string;
}

export const statusIcons: Status = {
  ACCEPTED: GoingIcon,
  TENTATIVE: TentativeIcon,
  DECLINED: DeclineIcon,
  REJECTED: DeclineIcon,
};

export const statusText: Status = {
  ACCEPTED: "Going",
  TENTATIVE: "Tentative",
  DECLINED: "Not going",
  REJECTED: "Not going",
};

export const iconForStatus = (status: string): string => {
  status = status === "Rejected" ? "DECLINED" : status;

  return status.toUpperCase() === "ACCEPTED"
    ? (GoingIcon as string)
    : status.toUpperCase() === "DECLINED"
      ? (DeclineIcon as string)
      : (TentativeIcon as string);
};

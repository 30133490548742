import React, { FC } from "react";
import DownloadFileSvg from "../../../../../images/pulse/downlaodFile.svg";
import * as styles from "./fileCard.module.scss";
import { Link } from "gatsby";
import { extractFilenameFromURL } from "../../../../../utils/pulse";

interface Props {
  fileURL: string;
}

export const FileCard: FC<Props> = ({ fileURL }) => {
  return (
    <div className={styles.container}>
      <div className={styles.fileName}>{extractFilenameFromURL(fileURL)}</div>
      <Link to={fileURL} download target="_blank">
        <DownloadFileSvg className={styles.fileSvg} />
      </Link>
    </div>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";
import { muiStylesButtons, muiStylesDialog } from "./muiStylesMessageDialog";

const useButtonStyles = makeStyles(muiStylesButtons);
const useDialogStyles = makeStyles(muiStylesDialog);

interface RemoveDialogProps {
  isOpen: boolean;
  onClose: () => void;
  header: string;
  description: string;
}

export const MessageDialog: FC<RemoveDialogProps> = ({
  isOpen,
  onClose,
  header,
  description,
}) => {
  const buttonClasses = useButtonStyles();
  const classes = useDialogStyles();

  return (
    <Dialog
      className={classes.backgroundDialog}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>{header}</h5>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography paragraph color="textSecondary" variant="subtitle1">
          {description}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose} className={buttonClasses.primaryButton}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

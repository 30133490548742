import React, { FC } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { ButtonProps } from "../types";
import muiStylesButtons from "../muiStylesButtons";

const useStyles = makeStyles(muiStylesButtons);

const TertiaryButton: FC<ButtonProps> = ({ text, disabled, event }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.tertiaryButton}
      disabled={!!disabled}
      onClick={event}
    >
      {text}
    </Button>
  );
};

export default TertiaryButton;

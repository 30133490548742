import { Menu, makeStyles } from "@material-ui/core";
import React, { FC, MouseEvent } from "react";
import muiStylesKebabMenu from "./muiStylesKebabMenu";

const useStyles = makeStyles(muiStylesKebabMenu);

interface Props {
  anchor: null | HTMLElement;
  open: boolean;
  handleOpen: (event: MouseEvent<HTMLElement>, id: number) => void;
  handleClose: () => void;
}

const KebabMenu: FC<Props> = ({ anchor, open, handleClose, children }) => {
  const classes = useStyles();

  return (
    <>
      <Menu
        anchorEl={anchor}
        getContentAnchorEl={null}
        id="kebab-menu-dropdown"
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          elevation: 0,
          className: classes.paper,
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default KebabMenu;

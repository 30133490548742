import * as styles from "../comments.module.scss";
import React, { FC, MouseEvent } from "react";
import { Divider } from "@material-ui/core";
import UserAvatar from "../../../Common/UserAvatar";
import CommentContent from "../CommentContent";
import { Comment } from "../../../../api/pulseAPI";
import CreateComment from "../CreateComment";

interface Props {
  comment?: Comment;
  postId: number;
  edit?: boolean;
  handleMenuOpen: (event: MouseEvent<HTMLElement>, id?: number) => void;
  handleEditFormClose?: () => void;
}

const CommentWrapper: FC<Props> = (props) => {
  return (
    <>
      <Divider variant="middle" />
      {props.edit ? (
        <CreateComment
          postId={props.postId}
          comment={props.comment}
          handleEditFormClose={props.handleEditFormClose}
        />
      ) : (
        <div className={styles.commentContainer}>
          <UserAvatar
            firstName={props.comment?.owner.firstName}
            lastName={props.comment?.owner.lastName}
          />
          <CommentContent {...props} />
        </div>
      )}
    </>
  );
};

export default CommentWrapper;

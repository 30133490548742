// extracted by mini-css-extract-plugin
export var h1Typography = "passwordChange-module--h1-typography--GfDW0";
export var h2Typography = "passwordChange-module--h2-typography--2lFzg";
export var h3Typography = "passwordChange-module--h3-typography--1uGS2";
export var h4Typography = "passwordChange-module--h4-typography--3BX5v";
export var h5Typography = "passwordChange-module--h5-typography--2bx1U";
export var h6Typography = "passwordChange-module--h6-typography--D61Q8";
export var pTypography = "passwordChange-module--p-typography--2QbvB";
export var info = "passwordChange-module--info--38Pld";
export var buttonTypography = "passwordChange-module--button-typography--2sb86";
export var formLabelTypography = "passwordChange-module--form-label-typography--1kUQZ";
export var menuTabTypography = "passwordChange-module--menu-tab-typography--jHq4U";
export var linkStyle = "passwordChange-module--link-style--1M50Z";
export var postTypography = "passwordChange-module--post-typography--fT8LG";
export var passwordChangeContainer = "passwordChange-module--password-change-container--1bYkG";
export var returnContainer = "passwordChange-module--return-container--1sWj6";
export var returnText = "passwordChange-module--return-text--1TJkA";
export var passwordFormContainer = "passwordChange-module--password-form-container--yLoaP";
export var passwordForm = "passwordChange-module--password-form--2YXCc";
export var error = "passwordChange-module--error--2f1DS";
export var button = "passwordChange-module--button--1Er_i";
export var accountSettingsContainer = "passwordChange-module--account-settings-container--3w-wE";
export var buttonContainer = "passwordChange-module--button-container--2Kz7r";
export enum UserViewMode {
  Me = "me",
  All = "all",
}

export enum TimeViewMode {
  Month = "month",
  Week = "week",
  Day = "day",
}

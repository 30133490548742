// extracted by mini-css-extract-plugin
export var h1Typography = "layout-module--h1-typography--2_ijS";
export var h2Typography = "layout-module--h2-typography--3iBOj";
export var h3Typography = "layout-module--h3-typography--1NBMP";
export var h4Typography = "layout-module--h4-typography--bJEAh";
export var h5Typography = "layout-module--h5-typography--QTujb";
export var h6Typography = "layout-module--h6-typography--3w_8b";
export var pTypography = "layout-module--p-typography--2N0t1";
export var info = "layout-module--info--KLjX2";
export var buttonTypography = "layout-module--button-typography--3TEbY";
export var formLabelTypography = "layout-module--form-label-typography--Qp05G";
export var menuTabTypography = "layout-module--menu-tab-typography--BO9ml";
export var linkStyle = "layout-module--link-style--1VxJH";
export var postTypography = "layout-module--post-typography---uBSR";
export var wrapper = "layout-module--wrapper--1TNQx";
export var mainPanel = "layout-module--main-panel--2q0zp";
export var content = "layout-module--content--2drnz";
export var components = "layout-module--components--3Ioo9";
export var blueBackground = "layout-module--blue-background--1Jsle";
// extracted by mini-css-extract-plugin
export var h1Typography = "day-module--h1-typography--2mI0M";
export var h2Typography = "day-module--h2-typography--3EnFl";
export var h3Typography = "day-module--h3-typography--30azn";
export var h4Typography = "day-module--h4-typography--3mqU4";
export var h5Typography = "day-module--h5-typography--hwfDW";
export var h6Typography = "day-module--h6-typography--BaDeJ";
export var pTypography = "day-module--p-typography--3AGfw";
export var info = "day-module--info--2-jMC";
export var buttonTypography = "day-module--button-typography--3EIE_";
export var formLabelTypography = "day-module--form-label-typography--1oD_8";
export var menuTabTypography = "day-module--menu-tab-typography--2DAP0";
export var linkStyle = "day-module--link-style--CToDG";
export var postTypography = "day-module--post-typography--3EwQf";
export var day = "day-module--day--D34hL";
export var dayInfo = "day-module--day--info--1zNjd";
export var dayInfoNumber = "day-module--day--info-number--23VBB";
export var dayInfoToday = "day-module--day--info-today--39S9R";
export var dayInfoDay = "day-module--day--info-day--1tchI";
export var dayContainer = "day-module--day--container--2acEN";
import { Theme, createStyles } from "@material-ui/core/styles";
import { desktopBreakpoint } from "../../../styles/utils";

const paddingSize = "32px";

const muiStylesTopBar = (theme: Theme) =>
  createStyles({
    "top-bar": {
      position: "sticky",
      border: "0",
      borderBottom: "0",
      marginBottom: "0",
      width: "100%",
      backgroundColor: "white",
      color: "black",
      minHeight: "64px",
      display: "block",
    },
    "container": {
      minHeight: "64px",
      marginRight: "auto",
      marginLeft: "auto",
      width: "inherit",
      [desktopBreakpoint(theme)]: {
        paddingLeft: paddingSize,
      },
    },
  });

export default muiStylesTopBar;

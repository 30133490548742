import { createStyles } from "@material-ui/core";
import {
  modalBackgroundColor,
  textInputBackground,
  inputMobileBackgroundColor,
  theme,
} from "../../../../styles/mui/theme";

export const muiStyles = () =>
  createStyles({
    disabled: {},
    backgroundDialog: {
      "& .MuiBackdrop-root": {
        "background-color": modalBackgroundColor,
      },
      "& .MuiDialog-container": {
        [theme.breakpoints.up("sm")]: {
          "align-items": "flex-start",
          "margin-top": "152px",
        },
      },
    },
    dialogPaper: {
      "border-radius": 8,
      "max-width": "560px",
      [theme.breakpoints.up("lg")]: {
        "margin-left": "272px", // center the popup in relation to the content of the Pulse page, when left-menu is visible (left-menu width + content's inner padding 240px+32px)
      },
      [theme.breakpoints.down("xs")]: {
        "margin": 0,
        "width": "100%",
        "height": "100%",
        "max-height": "100%",
        "border-radius": 0,
      },
    },
    dialogContent: {
      "padding": "0px 16px",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
      "& .MuiTypography-paragraph": {
        padding: "0 1rem",
      },
      "& .MuiFormHelperText-root.Mui-error:first-letter": {
        textTransform: "uppercase",
      },
      "& .MuiFilledInput-root": {
        "padding": "10px 16px",
        "gap": "12px",
        "display": "flex",
        "flex-direction": "column",
        "background": textInputBackground,
        [theme.breakpoints.down("xs")]: {
          background: inputMobileBackgroundColor,
        },
        "&:hover:not($disabled)": {
          background: textInputBackground,
          [theme.breakpoints.down("xs")]: {
            background: inputMobileBackgroundColor,
          },
        },
        "&:focus:not($disabled)": {
          background: textInputBackground,
          [theme.breakpoints.down("xs")]: {
            background: inputMobileBackgroundColor,
          },
        },
        "&:autofill": {
          background: textInputBackground,
          [theme.breakpoints.down("xs")]: {
            background: inputMobileBackgroundColor,
          },
        },
        "&&&:before": {
          borderBottom: "none",
        },
      },
    },
    divider: {
      "margin-bottom": "5px",
    },
    textfield: {
      "height": "100%",
      "& .MuiInputBase-root": {
        "display": "flex",
        "flex-direction": "column",
        "justify-content": "space-between",
        "height": "100%",
      },
    },
    dialogTitle: {
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "flex-start",
      "paddingLeft": "16px",
      "& .MuiIconButton-root": {
        position: "relative",
        left: ".5rem",
      },
      "& h5": {
        margin: 0,
      },
    },
    dialogActions: {
      "justifyContent": "center",
      "padding": "20px",
      [theme.breakpoints.down("sm")]: {
        "margin-top": "10px",
        "padding": "1rem",
      },
      "& .MuiButton-root": {
        width: "100%",
      },
    },
  });

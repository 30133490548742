import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Input,
  TextField,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import React, { FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateTeamInDepartment,
  EditTeamInDepartment,
  KeyOfTeamInDepartment,
  Team,
  createOrUpdateTeamInDepartamentKeys,
} from "../../api/departmentsAPI";
import {
  addTeam,
  clearDepartmentsErrors,
  editTeam,
} from "../../redux/actions/departmentsActions";
import { fetchUsers } from "../../redux/actions/userActions";
import {
  selectDepartmentsError,
  selectDepartmentsLoading,
} from "../../redux/selectors/departmentsSelectors";
import { selectUsers } from "../../redux/selectors/usersSelectors";
import {
  muiStylesButtons,
  muiStylesDialog,
  muiStylesFormControlLabel,
} from "./muiStylesOrganizationStructure";
import { addUpdateTeamSchema } from "./validationSchemas";

const useButtonStyles = makeStyles(muiStylesButtons);
const useDialogStyles = makeStyles(muiStylesDialog);
const useInputStyles = makeStyles(muiStylesFormControlLabel);

interface AddUpdateTeamDialogProps {
  isOpen: boolean;
  onClose: () => void;
  departmentId: number;
  team?: Team;
}

export const AddUpdateTeamDialog: FC<AddUpdateTeamDialogProps> = ({
  isOpen,
  onClose,
  departmentId,
  team,
}) => {
  const isUpdate = !!team;
  const buttonClasses = useButtonStyles();
  const inputClasses = useInputStyles();
  const classes = useDialogStyles();
  const dispatch = useDispatch();
  const backendLoading = useSelector(selectDepartmentsLoading);
  const backendError = useSelector(selectDepartmentsError);
  const userData = useSelector(selectUsers);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful, isValid, isSubmitting },
    setError,
    getValues,
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<CreateTeamInDepartment & EditTeamInDepartment>({
    mode: "onChange",
    resolver: yupResolver(addUpdateTeamSchema),
    defaultValues: {
      departmentId,
      ...(isUpdate ? {} : { members: [], name: "" }),
    },
  });
  const addUpdateTeamSubmit = handleSubmit((data) => {
    if (isUpdate) {
      dispatch(editTeam(data));
    } else {
      dispatch(addTeam(data));
    }
  });
  const close = useCallback(() => {
    onClose();
    reset({ members: [], name: "" });
  }, [onClose, reset]);

  useEffect(() => {
    createOrUpdateTeamInDepartamentKeys.forEach((k) => clearErrors(k));
    dispatch(clearDepartmentsErrors());
  }, [clearErrors, dispatch, isOpen]);

  useEffect(() => {
    if (team) {
      setValue("name", team.name);
      setValue("id", team.id);
      setValue("members", team.members);
    } else {
      reset({ members: [], name: "" });
    }

    setValue("departmentId", departmentId);
  }, [departmentId, team, setValue, isOpen, getValues, reset]);

  useEffect(() => {
    if (!backendLoading && isSubmitSuccessful && !backendError.error) {
      close();
    } else if (backendError.error) {
      if (backendError.message === "Validation failed") {
        Object.entries(backendError.details || {})
          .filter(([k]) =>
            createOrUpdateTeamInDepartamentKeys.includes(
              k as KeyOfTeamInDepartment
            )
          )
          .forEach(([key, keyErrors]) =>
            setError(key as KeyOfTeamInDepartment, {
              message: keyErrors.join(" "),
            })
          );
      } else {
        // TODO: handle errors
        // eslint-disable-next-line no-console
        console.log(backendError);
      }
    }
  }, [
    backendError,
    backendLoading,
    close,
    isSubmitSuccessful,
    setError,
    isOpen,
  ]);

  useEffect(() => {
    dispatch(fetchUsers({}));
  }, [dispatch]);

  const action = isUpdate ? "Update" : "Add";

  return (
    <Dialog
      className={classes.backgroundDialog}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={close}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>{`${action} department`}</h5>
        <IconButton size="small" onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form id="add-update-departament-form" onSubmit={addUpdateTeamSubmit}>
          <Input {...register("departmentId")} type="hidden" />
          <Input {...register("id")} type="hidden" />
          <Controller
            control={control}
            name="name"
            render={({ field: { onChange, value: formValue, ref } }) => (
              <FormControlLabel
                control={
                  <TextField
                    ref={ref}
                    onChange={onChange}
                    type="text"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="filled"
                    value={formValue}
                    fullWidth
                  />
                }
                classes={inputClasses}
                labelPlacement="top"
                label="Department name"
              />
            )}
          />
          <Controller
            control={control}
            name="members"
            render={({ field: { onChange, value: formValue, ref } }) => (
              <Autocomplete
                ref={ref}
                autoSelect
                multiple
                freeSolo
                value={formValue}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}`
                }
                onChange={(_event, value, reason) => {
                  switch (reason) {
                    case "create-option":
                    case "select-option":
                    case "remove-option":
                    case "clear":
                      onChange(value);
                      break;

                    default:
                      break;
                  }
                }}
                className={classes.input}
                options={userData}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={`${option.firstName} ${option.lastName}`}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    placeholder="Add members"
                    className={classes.search}
                  />
                )}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={close}
          className={buttonClasses.secondaryButton}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          type="submit"
          form="add-update-departament-form"
          className={buttonClasses.primaryButton}
          disabled={!isValid || isSubmitting}
        >
          {action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

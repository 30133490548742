import React, { FC } from "react";
import ReactPlayer from "react-player";

interface Props {
  url: string;
  thumbnail?: string;
  videoPlaying: boolean;
  setVideoPlaying: (playing: boolean) => void;
}

export const VideoPlayer: FC<Props> = ({ url, videoPlaying, setVideoPlaying, thumbnail }) => {
  return (
    <ReactPlayer
      url={url}
      playing={videoPlaying}
      controls
      width="100%"
      height="500px"
      pip={false}
      light={thumbnail ? thumbnail : undefined}
      onClickPreview={() => setVideoPlaying(true)}
      onStart={() => setVideoPlaying(true)}
      onPause={() => setVideoPlaying(false)}
      onEnded={() => setVideoPlaying(false)}
      style={{
        background: "black",
      }}
      config={{
        file: {
          attributes: {
            controlsList: "nodownload noplaybackrate",
            disablePictureInPicture: true,
          },
        },
      }}
    />
  );
};

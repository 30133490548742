import { Theme, createStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  secondaryColor,
  secondaryColorHover,
} from "../../../styles/mui/theme";

const muiStylesActivityPreview = (theme: Theme) =>
  createStyles({
    input: {
      width: "100%",
      marginLeft: "20px",
    },
    inputTitle: {
      width: "50%",
      margin: "0 auto",
    },
    inputDate: {
      "margin-left": "20px",
      "text-align": "center",
      "width": "fit-content",
    },
    select: {
      marginLeft: "20px",
    },
    btnCancel: {
      "margin-right": "15px",
    },
    iconClose: {
      "width": "fit-content",
      "margin-right": "15px",
    },
    buttonAdd: {
      color: "white",
      textTransform: "capitalize",
      fontWeight: 900,
      fontSize: "1.05em",
      marginLeft: theme.spacing(2),
    },
    buttonDelete: {
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "margin-right": "10px",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
      "& + &": {
        marginLeft: "auto",
        padding: "6px 40px",
      },
    },
  });

export default muiStylesActivityPreview;

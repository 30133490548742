import { Theme, createStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  primaryColorHover,
  secondaryColor,
  secondaryColorHover,
  warningColor,
  warningColorHover,
} from "../../styles/mui/theme";

const muiStylesAllEmployees = (theme: Theme) =>
  createStyles({
    btnAddDepartment: {
      marginBottom: "1.45rem",
    },
    primaryButton: {
      "color": secondaryColor,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
    },
    secondaryButton: {
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
    },
    warningButton: {
      "color": secondaryColor,
      "border": `1px solid ${warningColor}`,
      "backgroundColor": warningColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: warningColorHover,
      },
    },
  });

export default muiStylesAllEmployees;

import { Theme, createStyles } from "@material-ui/core/styles";
import { primaryColor, secondaryColor } from "../../../styles/mui/theme";

const muiStylesMobileFiltering = (theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    apply: {
      width: "250px",
      height: "60px",
      backgroundColor: primaryColor,
      color: secondaryColor,
    },
  });

export default muiStylesMobileFiltering;

// extracted by mini-css-extract-plugin
export var h1Typography = "uploadFileCard-module--h1-typography--2bQZN";
export var h2Typography = "uploadFileCard-module--h2-typography--1z2fn";
export var h3Typography = "uploadFileCard-module--h3-typography--3khG3";
export var h4Typography = "uploadFileCard-module--h4-typography--2q853";
export var h5Typography = "uploadFileCard-module--h5-typography--3UZy9";
export var h6Typography = "uploadFileCard-module--h6-typography--2Dzkt";
export var pTypography = "uploadFileCard-module--p-typography--2VQfA";
export var info = "uploadFileCard-module--info--IS8RO";
export var buttonTypography = "uploadFileCard-module--button-typography--12iAT";
export var fileName = "uploadFileCard-module--file-name--3i5YH";
export var formLabelTypography = "uploadFileCard-module--form-label-typography--SUrf3";
export var menuTabTypography = "uploadFileCard-module--menu-tab-typography--3WWzq";
export var linkStyle = "uploadFileCard-module--link-style--2_ubR";
export var postTypography = "uploadFileCard-module--post-typography--1e-mf";
export var container = "uploadFileCard-module--container--26av6";
import React, { FC } from "react";
import DesktopCalendar from "./DesktopCalendar";
import MobileCalendar from "./MobileCalendar";
import { useIsSmall } from "../../utils";

const Calendar: FC = () => {
  const isSmall = useIsSmall();

  return <>{isSmall ? <MobileCalendar /> : <DesktopCalendar />}</>;
};

export default Calendar;

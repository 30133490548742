// extracted by mini-css-extract-plugin
export var h1Typography = "activityPreview-module--h1-typography--ag7IK";
export var h2Typography = "activityPreview-module--h2-typography--3j-EY";
export var h3Typography = "activityPreview-module--h3-typography--3dUb7";
export var h4Typography = "activityPreview-module--h4-typography--1jiRQ";
export var h5Typography = "activityPreview-module--h5-typography--2eME5";
export var h6Typography = "activityPreview-module--h6-typography--3lwpG";
export var pTypography = "activityPreview-module--p-typography--2_Zf5";
export var info = "activityPreview-module--info--1ietH";
export var buttonTypography = "activityPreview-module--button-typography--1xUBC";
export var formLabelTypography = "activityPreview-module--form-label-typography--1eXTM";
export var menuTabTypography = "activityPreview-module--menu-tab-typography--30H9T";
export var linkStyle = "activityPreview-module--link-style--2x1MD";
export var postTypography = "activityPreview-module--post-typography--GnXEY";
export var icon = "activityPreview-module--icon--3FeaJ";
export var pTime = "activityPreview-module--p-time--fWGN5";
export var textDetails = "activityPreview-module--text-details--1uBnw";
export var iconClose = "activityPreview-module--icon-close--2weQB";
export var closeContainer = "activityPreview-module--close-container--1g6Zn";
export var container = "activityPreview-module--container--9NzxY";
export var previewTime = "activityPreview-module--preview-time--lpAcU";
export var previewTitle = "activityPreview-module--preview-title--3ZoHQ";
export var previewActivity = "activityPreview-module--preview-activity--QGgZU";
export var previewUsers = "activityPreview-module--preview-users--8slax";
export var previewLocation = "activityPreview-module--preview-location--3xlaX";
export var previewDescription = "activityPreview-module--preview-description--38S9Z";
export var previewButtons = "activityPreview-module--preview-buttons--XKYVZ";
export var previewClosed = "activityPreview-module--preview-closed--1tck-";
export var containerTime = "activityPreview-module--container-time--3lULw";
import * as styles from "./TopBar.module.scss";
import React, { FC, MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddActivityButton from "../../../icons/button-add-activity.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "../../../icons/button-search.svg";
import UserAvatar from "../../Common/UserAvatar";
import muiStyles from "./muiStylesTopBar";
import AddActivityForm from "../../Activity/ActivityForms/AddActivityForm";
import MobileSearchTransition from "../../SearchBar/MobileSearch/Transition";
import MobileSearch from "../../SearchBar/MobileSearch";
import DesktopSearch from "../../SearchBar/DesktopSearch";
import {
  AppBar,
  Dialog,
  Divider,
  Hidden,
  Menu,
  MenuItem,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import { Link } from "gatsby";
import { fetchUsers } from "../../../redux/actions/userActions";
import { logout } from "../../../redux/actions/authActions";
import { selectAuthUser } from "../../../redux/selectors/authSelectors";
import { accountSettingsPath, pulsePath } from "../../../utils/paths";
import { useLocation } from "@reach/router";

const useStyles = makeStyles(muiStyles);

interface Props {
  clearSearchInput: () => void;
  handleClickOpenSearch: () => void;
  handleDrawerToggle: () => void;
  handleSearchInputChanges: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  searchValue: string;
  userId?: number;
}

const Bar: FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector(selectAuthUser);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openForm, setOpenForm] = useState(false);
  const [openMobileSearch, setOpenMobileSearch] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchUsers({}));
  }, [dispatch]);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenForm = () => {
    setOpenForm(true);
  };

  const handleClickCloseForm = () => {
    setOpenForm(false);
  };

  const handleClickOpenMobileSearch = () => {
    setOpenMobileSearch(true);
  };

  const handleClickCloseMobileSearch = () => {
    setOpenMobileSearch(false);
  };

  const isMenuOpen = Boolean(anchorEl);
  const dropdownMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      getContentAnchorEl={null}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link to={accountSettingsPath} className={styles.linkStyle}>
        <MenuItem style={{ width: "200px" }} onClick={handleMenuClose}>
          Account Settings
        </MenuItem>
      </Link>
      <div className={styles.linkStyle}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            dispatch(logout());
          }}
        >
          Log out
        </MenuItem>
      </div>
    </Menu>
  );

  return (
    <AppBar className={classes["top-bar"]} position="fixed" elevation={0}>
      <Toolbar className={classes.container}>
        <div className={styles.menuContainer}>
          <Hidden lgUp implementation="css">
            <div className={styles.items}>
              <div className={styles.itemsHeader}>
                <MenuIcon
                  color="primary"
                  aria-label="open sidebar"
                  onClick={props.handleDrawerToggle}
                />
                {pathname === pulsePath && <h4>Pulse</h4>}
              </div>
              <div className={styles.item}>
                <img
                  src={SearchIcon}
                  alt="search-button"
                  role="button"
                  onClick={handleClickOpenMobileSearch}
                />
                <Dialog
                  fullScreen
                  open={openMobileSearch}
                  onClose={handleClickCloseMobileSearch}
                  TransitionComponent={MobileSearchTransition}
                >
                  <MobileSearch handleClose={handleClickCloseMobileSearch} />
                </Dialog>
                <img
                  role="button"
                  tabIndex={0}
                  src={AddActivityButton}
                  alt="add-activity-button"
                  onClick={handleClickOpenForm}
                />
                <Dialog
                  fullScreen
                  open={openForm}
                  onClose={handleClickCloseForm}
                >
                  <AddActivityForm
                    open={openForm}
                    handleClose={handleClickCloseForm}
                    editMode={false}
                  />
                </Dialog>
              </div>
            </div>
          </Hidden>
          <Hidden mdDown implementation="css">
            <div className={styles.items}>
              <div className={styles.searchSection}>
                <DesktopSearch
                  clearSearchInput={props.clearSearchInput}
                  handleClickOpenSearch={props.handleClickOpenSearch}
                  handleSearchInputChanges={props.handleSearchInputChanges}
                  searchValue={props.searchValue}
                />
              </div>
              <Divider orientation="vertical" flexItem />
              <div
                className={styles.userSection}
                onClick={handleMenuOpen}
                role="button"
              >
                <div className="user-logos">
                  <UserAvatar
                    firstName={userData?.firstName}
                    lastName={userData?.lastName}
                  />
                </div>
                <div className={styles.userInfo}>
                  <span className={styles.userName}>
                    {`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}
                  </span>
                  <span className={styles.userJob}>{userData?.role ?? ""}</span>
                </div>
                <ArrowDropDownIcon />
              </div>
              {dropdownMenu}
            </div>
          </Hidden>
        </div>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default Bar;

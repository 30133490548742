// extracted by mini-css-extract-plugin
export var h1Typography = "mobileFiltering-module--h1-typography--172sA";
export var h2Typography = "mobileFiltering-module--h2-typography--3Gfda";
export var h3Typography = "mobileFiltering-module--h3-typography--fQKx9";
export var h4Typography = "mobileFiltering-module--h4-typography--3JPQs";
export var h5Typography = "mobileFiltering-module--h5-typography--3i1nm";
export var h6Typography = "mobileFiltering-module--h6-typography--1sTi6";
export var pTypography = "mobileFiltering-module--p-typography--2g9bU";
export var info = "mobileFiltering-module--info--JA_r8";
export var buttonTypography = "mobileFiltering-module--button-typography--2LEA5";
export var formLabelTypography = "mobileFiltering-module--form-label-typography--2LEw_";
export var menuTabTypography = "mobileFiltering-module--menu-tab-typography--1Mnqg";
export var linkStyle = "mobileFiltering-module--link-style--1yRhq";
export var postTypography = "mobileFiltering-module--post-typography--3SYkD";
export var mobileFilteringContainer = "mobileFiltering-module--mobile-filtering-container--16M8F";
export var filteringContainer = "mobileFiltering-module--filtering--container--1pNlG";
export var filteringContainerInput = "mobileFiltering-module--filtering--container-input--3-1PB";
// extracted by mini-css-extract-plugin
export var h1Typography = "pulse-module--h1-typography--2xcdX";
export var h2Typography = "pulse-module--h2-typography--cvcRg";
export var h3Typography = "pulse-module--h3-typography--Et-53";
export var h4Typography = "pulse-module--h4-typography--3p6dg";
export var h5Typography = "pulse-module--h5-typography--1LI-9";
export var h6Typography = "pulse-module--h6-typography--2pbgA";
export var pTypography = "pulse-module--p-typography--2Iaxa";
export var info = "pulse-module--info--3nkDk";
export var buttonTypography = "pulse-module--button-typography--UenYT";
export var formLabelTypography = "pulse-module--form-label-typography--1_Jn-";
export var menuTabTypography = "pulse-module--menu-tab-typography--1rDnr";
export var linkStyle = "pulse-module--link-style--3OlSo";
export var postTypography = "pulse-module--post-typography--1MaVE";
export var container = "pulse-module--container--3RaCR";
export var warningText = "pulse-module--warning-text--3wVml";
export var postsListContainer = "pulse-module--posts-list-container--2Jb-D";
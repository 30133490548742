import { createStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  primaryColorHover,
  secondaryColor,
  secondaryColorHover,
  textInputBackground,
} from "../../../styles/mui/theme";
import { Theme } from "@material-ui/core";

export const muiStyleFormControlLabel = () =>
  createStyles({
    root: {
      alignItems: "start",
      paddingBottom: "1em",
    },
  });

export const muiStylePasswordChange = (theme: Theme) =>
  createStyles({
    root: {
      alignItems: "start",
      paddingBottom: "1em",
    },
    disabled: {},
    textField: {
      "background": textInputBackground,
      "&:hover:not($disabled)": {
        background: textInputBackground,
      },
      "&:focus:not($disabled)": {
        background: textInputBackground,
      },
      "&:autofill": {
        background: textInputBackground,
      },
      "&&&:before": {
        borderBottom: "none",
      },
    },
    button: {
      backgroundColor: primaryColor,
      marginTop: "auto",
    },
    buttonSave: {
      "color": secondaryColor,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
      "top": 10,
      "left": 0,
      "right": -175,
      "margin": "0 auto",
      "width": 150,
    },
    buttonCancel: {
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
      "& + &": {
        marginLeft: "auto",
        padding: "6px 40px",
      },
      "top": 10,
      "left": 0,
      "right": 175,
      "borderColor": "",
      "margin": "0 auto",
      "width": 150,
    },
    buttonDesktop: {
      "marginTop": "auto",
      "alignSelf": "flex-end",
      "width": "140px",
      "position": "absolute",
      "top": 25,
      "right": 50,
      "color": secondaryColor,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
    },
    appBar: {
      paddingTop: "10px",
      paddingBottom: "10px",
      top: "auto",
      bottom: 0,
      backgroundColor: secondaryColor,
    },
  });

import * as styles from "./user.module.scss";
import { Badge } from "@material-ui/core";
import { FC } from "react";
import StatusBadge from "./StatusBadge";
import { Attendee } from "../../../api/usersAPI";
import { iconForStatus } from "../AttendeeStatus/statusIcons";
import UserAvatar from "../../Common/UserAvatar";

interface Props {
  user: Attendee;
  isOrganizer: boolean;
}

const UserBox: FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.userPhoto}>
        {props.user.status === null ? (
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <UserAvatar
              firstName={props.user.firstName}
              lastName={props.user.lastName}
            />
          </Badge>
        ) : (
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={
              <StatusBadge
                src={
                  props.isOrganizer
                    ? iconForStatus("ACCEPTED")
                    : iconForStatus(props.user.status)
                }
              />
            }
          >
            <UserAvatar
              firstName={props.user.firstName}
              lastName={props.user.lastName}
            />
          </Badge>
        )}
      </div>
      <div className={styles.userInfo}>
        <span>
          {props.user.firstName} {props.user.lastName}
        </span>
        {props.isOrganizer ? (
          <span className={styles.organizer}>Organizer</span>
        ) : null}
      </div>
    </div>
  );
};

export default UserBox;

import * as React from "react";
import "./eventWrapper.scss";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectAuthUser } from "../../../../redux/selectors/authSelectors";
import { EventWrapperProps } from "react-big-calendar";
import { CustomEvent } from "../Event";
import { isUserAllowedInActivity } from "../../../../utils";

const CustomEventWrapper: FC<EventWrapperProps<CustomEvent>> = (props) => {
  const curUser = useSelector(selectAuthUser);

  return (
    <div
      className={`activity ${
        isUserAllowedInActivity(
          props.event.closed,
          props.event.owner.id,
          props.event.attendees,
          curUser?.id
        )
          ? `activity-active`
          : ""
      }`}
    >
      {props.children}
    </div>
  );
};

export default CustomEventWrapper;

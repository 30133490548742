/*
 * Function replacing removed Emojipedia CDN provider with a current one.
 * Should be used for body of the post, when provided to ReactQuill.
 */
export const replaceOldCDN = (postBody?: string): string | undefined => {
  if (postBody) {
    return postBody.replaceAll(
      "https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/72/apple/285/",
      "https://em-content.zobj.net/thumbs/160/apple/325/"
    );
  }

  return postBody;
};

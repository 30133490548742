import { createStyles } from "@material-ui/core/styles";

const muiStylesDropdown = () =>
  createStyles({
    dropdownControl: {
      "minWidth": "150px",
      "& .MuiOutlinedInput-input": {
        padding: "0px",
      },
    },
    icon: {
      "margin-right": "10px",
      "min-width": "0px",
    },
  });

export default muiStylesDropdown;

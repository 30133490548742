// extracted by mini-css-extract-plugin
export var h1Typography = "profile-module--h1-typography--3LKIx";
export var h2Typography = "profile-module--h2-typography--34cU1";
export var h3Typography = "profile-module--h3-typography--3k58C";
export var h4Typography = "profile-module--h4-typography--2v8m3";
export var h5Typography = "profile-module--h5-typography--3UitW";
export var h6Typography = "profile-module--h6-typography--2bINs";
export var pTypography = "profile-module--p-typography--Bx1eV";
export var info = "profile-module--info--3b5KP";
export var buttonTypography = "profile-module--button-typography--3KHJn";
export var formLabelTypography = "profile-module--form-label-typography--12AI9";
export var menuTabTypography = "profile-module--menu-tab-typography--1WDZt";
export var linkStyle = "profile-module--link-style--Phq5Y";
export var postTypography = "profile-module--post-typography--37t17";
export var container = "profile-module--container--1XiQh";
export var navigation = "profile-module--navigation--2qTfQ";
export var calendar = "profile-module--calendar--34eBF";
export var profileContent = "profile-module--profile-content--2cMOw";
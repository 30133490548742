import * as styles from "./sportBadge.module.scss";
import React, { FC } from "react";
import { customActivityTypeName, fetchActivityIcon } from "../../../utils";

interface Props {
  sport?: string;
}

const SportBadge: FC<Props> = (props) => {
  return (
    <div className={styles.badge}>
      {props.sport === undefined ? null : props.sport !==
        customActivityTypeName ? (
          <>
            <img
              src={fetchActivityIcon(props.sport, "#0C3DEB")}
              alt="icon-sport"
            />
            <span className={styles.sport}>{props.sport}</span>
          </>
        ) : (
          <span className={styles.sport}>{props.sport}</span>
        )}
    </div>
  );
};

export default SportBadge;

export interface Member {
  id: number;
  firstName: string;
  lastName: string;
}

export interface OnboardingTeam {
  name: string;
}

export interface Team extends OnboardingTeam {
  id: number;
  employeeCount: number;
  members: Member[];
}

export interface CreateDepartament {
  name: string;
}

export const createDepartamentKeys: readonly (keyof CreateDepartament)[] = [
  "name",
] as const;

export interface OnboardingDepartament extends CreateDepartament {
  teams: OnboardingTeam[];
}

export interface Departament extends CreateDepartament {
  id: number;
  teams: Team[];
}

export interface TeamInDepartment {
  departmentId: number;
  id: number;
}

export interface CreateTeamInDepartment {
  departmentId: number;
  members: Member[];
  name: string;
}

export interface EditTeamInDepartment extends CreateTeamInDepartment {
  id: number;
}

export type KeyOfTeamInDepartment =
  | keyof EditTeamInDepartment
  | keyof CreateTeamInDepartment;
export const createOrUpdateTeamInDepartamentKeys: readonly KeyOfTeamInDepartment[] = [
  "name",
  "members",
  "id",
  "departmentId",
] as const;

import { createStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  primaryColorHover,
  secondaryColor,
} from "../../styles/mui/theme";

export const muiStylesEmployeesBulkUpload = () =>
  createStyles({
    btnAddDepartment: {
      marginBottom: "1.45rem",
    },
    primaryButton: {
      "color": secondaryColor,
      "minWidth": 120,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
    },
  });

import { createStyles } from "@material-ui/core/styles";
import { inputBackgroundColor } from "../../../styles/mui/theme";

const muiStylesDesktopSearch = () =>
  createStyles({
    root: {
      position: "sticky",
      top: "0",
    },
    search: {
      "minWidth": "140px",
      "maxWidth": "640px",
      "width": "100%",
      "margin-bottom": "7px",
    },
    input: {
      "& .MuiFilledInput-input": {
        padding: "15px 10px",
      },
      "& .MuiFilledInput-adornedStart": {
        "padding-left": "0px",
      },
      "& .MuiFilledInput-root": {
        background: inputBackgroundColor,
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": "1px solid transparent",
      },
    },
  });

export default muiStylesDesktopSearch;

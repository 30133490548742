import * as styles from "./layout.module.scss";
import React, { ReactNode, useState } from "react";
import SideBar from "../SideBar";
import TopBar from "../TopBar";
import backgroundImage from "../../../images/motko-background.png";
import { selectAuthUser } from "../../../redux/selectors/authSelectors";
import { useSelector } from "react-redux";
import SearchResults from "../../SearchBar/SearchResults";
import { User } from "../../../api/usersAPI";
import { selectUsers } from "../../../redux/selectors/usersSelectors";
import { useIsSmall } from "../../../utils";

interface Props {
  children: ReactNode;
}

const StandardLayout = ({ children }: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [colorActive] = useState("blue");
  const userData = useSelector(selectAuthUser);
  const isSmall = useIsSmall();

  const users = useSelector(selectUsers);

  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState<User[]>([]);

  const handleSearchInputChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchedString = event.target.value;

    setSearchValue(searchedString);

    const searchResults = users.filter((user: User) =>
      `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`.includes(
        searchedString.toLowerCase()
      )
    );

    setResults(searchResults);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickOpenSearch = () => {
    setOpenSearch(true);
  };

  const handleClickCloseSearch = () => {
    setOpenSearch(false);
  };

  const clearSearchInput = () => {
    handleClickCloseSearch();
    setSearchValue("");
  };

  const PageContent = (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage as string})`,
        }}
        className={styles.blueBackground}
      />
      <div className={styles.components}>{children}</div>
    </>
  );

  return (
    <div className={styles.wrapper}>
      <SideBar
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={colorActive}
      />
      <div className={styles.mainPanel}>
        <TopBar
          clearSearchInput={clearSearchInput}
          handleDrawerToggle={handleDrawerToggle}
          handleClickOpenSearch={handleClickOpenSearch}
          handleSearchInputChanges={handleSearchInputChanges}
          searchValue={searchValue}
          userId={userData?.id}
        />
        <div className={styles.content}>
          {!isSmall && openSearch ? (
            <SearchResults
              results={results}
              searchValue={searchValue}
              handleClose={handleClickCloseSearch}
            />
          ) : (
            PageContent
          )}
        </div>
      </div>
    </div>
  );
};

export default StandardLayout;

// extracted by mini-css-extract-plugin
export var h1Typography = "employee-module--h1-typography--1VjVJ";
export var h2Typography = "employee-module--h2-typography--3JEJY";
export var h3Typography = "employee-module--h3-typography--3gW0a";
export var h4Typography = "employee-module--h4-typography--DZDuw";
export var h5Typography = "employee-module--h5-typography--3zK6c";
export var h6Typography = "employee-module--h6-typography--1zqHM";
export var pTypography = "employee-module--p-typography--35Mq_";
export var info = "employee-module--info--3Pd-R";
export var buttonTypography = "employee-module--button-typography--66DQP";
export var formLabelTypography = "employee-module--form-label-typography--3Zs_9";
export var menuTabTypography = "employee-module--menu-tab-typography--2LJJ3";
export var linkStyle = "employee-module--link-style--1x_oC";
export var postTypography = "employee-module--post-typography--37KOB";
export var userContainer = "employee-module--user-container--1B3Cl";
export var userContainerChecked = "employee-module--user-container-checked--28b-q";
export var checkboxContainer = "employee-module--checkbox-container--3oMP6";
export var avatar = "employee-module--avatar--2aMEe";
export var userInfo = "employee-module--user-info--1aP7W";
export var isUserActiveCircle = "employee-module--is-user-active-circle--EZVXd";
export var active = "employee-module--active--1lKZv";
export var pending = "employee-module--pending--3FCLx";
export var disableUser = "employee-module--disable-user--_560s";
export var userName = "employee-module--user-name--1Yj3i";
export var userDepartment = "employee-module--user-department--27JsF";
export var menuButton = "employee-module--menu-button--1b684";
export var activityContainer = "employee-module--activity-container--3m_v7";
export var isUserActiveContainer = "employee-module--is-user-active-container--3ieSo";
// extracted by mini-css-extract-plugin
export var h1Typography = "fileCard-module--h1-typography--Pl7gh";
export var h2Typography = "fileCard-module--h2-typography--2rz-9";
export var h3Typography = "fileCard-module--h3-typography--7f5N_";
export var h4Typography = "fileCard-module--h4-typography--25kCR";
export var h5Typography = "fileCard-module--h5-typography--1AlZm";
export var h6Typography = "fileCard-module--h6-typography--oB4WG";
export var pTypography = "fileCard-module--p-typography--w1y_4";
export var info = "fileCard-module--info--o68Go";
export var buttonTypography = "fileCard-module--button-typography--3UTql";
export var fileName = "fileCard-module--file-name--2u9tI";
export var formLabelTypography = "fileCard-module--form-label-typography--pX-MS";
export var menuTabTypography = "fileCard-module--menu-tab-typography--1tw_H";
export var linkStyle = "fileCard-module--link-style--1HyL3";
export var postTypography = "fileCard-module--post-typography--2bpGJ";
export var container = "fileCard-module--container--1xmxn";
export var fileSvg = "fileCard-module--file-svg--sHwos";
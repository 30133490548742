import React, { FC } from "react";
import { Checkbox } from "@material-ui/core";
import { inactiveCheckboxColor, primaryColor } from "../../../styles/mui/theme";
import { muiStylesCheckbox } from "./muiStylesCheckbox";
import { makeStyles } from "@material-ui/core/styles";
import { CheckboxProps } from "@material-ui/core/Checkbox/Checkbox";

const useStyles = makeStyles(muiStylesCheckbox());

interface Props extends CheckboxProps {
  uiScale?: number;
}

export const StyledCheckbox: FC<Props> = ({
  uiScale = 1.25,
  ...checkboxProps
}) => {
  const classes = useStyles();
  const checked = !!checkboxProps?.value;

  const getCheckboxColor = () => {
    return checked ? primaryColor : inactiveCheckboxColor;
  };

  return (
    <Checkbox
      className={classes.checkbox}
      style={{
        color: getCheckboxColor(),
        transform: `scale(${uiScale})`,
      }}
      inputProps={{ "aria-label": "secondary checkbox" }}
      checked={checked}
      {...checkboxProps}
    />
  );
};

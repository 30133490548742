// extracted by mini-css-extract-plugin
export var h1Typography = "SideBar-module--h1-typography--1FVTi";
export var h2Typography = "SideBar-module--h2-typography--3PggZ";
export var h3Typography = "SideBar-module--h3-typography--raqI4";
export var h4Typography = "SideBar-module--h4-typography--2YVvE";
export var h5Typography = "SideBar-module--h5-typography--1BCi6";
export var h6Typography = "SideBar-module--h6-typography--DxRPG";
export var pTypography = "SideBar-module--p-typography--3oBVQ";
export var info = "SideBar-module--info--2gytw";
export var buttonTypography = "SideBar-module--button-typography--WpKU7";
export var formLabelTypography = "SideBar-module--form-label-typography--x81Gc";
export var menuTabTypography = "SideBar-module--menu-tab-typography--3Cm7s";
export var linkStyle = "SideBar-module--link-style--euFpj";
export var postTypography = "SideBar-module--post-typography--2vVtF";
export var logoSection = "SideBar-module--logo-section--2UKGf";
export var logo = "SideBar-module--logo--3LLSO";
export var user = "SideBar-module--user--2xpQg";
export var userInfo = "SideBar-module--user-info--2rpoT";
export var userName = "SideBar-module--user-name--l_pyP";
export var userJob = "SideBar-module--user-job--26_RR";
export var icon = "SideBar-module--icon--2VSG7";
export var iconActive = "SideBar-module--icon-active--N7h4n";
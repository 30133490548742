import * as yup from "yup";

export const schema = yup.object({
  currPassword: yup.string().required("Password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[A-Za-z]+/, "Password must contain at least one letter")
    .matches(/[0-9]/, "Password must contain at least one number"),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("New password confirmation is required"),
});

import React from "react";
import StandardLayout from "../components/Layout/StandardLayout";
import Profile from "../components/Profile";

const ProfileCalendarPage = () => {
  return (
    <StandardLayout>
      <Profile calendarProfile />
    </StandardLayout>
  );
};

export default ProfileCalendarPage;

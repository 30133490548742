import { Theme, createStyles } from "@material-ui/core/styles";
import { primaryColor, secondaryColor } from "../../../../styles/mui/theme";

const muiStylesMobileCalendarTopBar = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: "sticky",
      backgroundColor: secondaryColor,
      boxShadow: "none",
      display: "contents",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textTransform: "capitalize",
    },
    today: {
      color: primaryColor,
      textTransform: "capitalize",
      fontWeight: 900,
      fontSize: "1.05em",
    },
  });

export default muiStylesMobileCalendarTopBar;

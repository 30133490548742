import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { ReactElement, Ref, forwardRef } from "react";

const MobileSearchTransition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement },
  ref: Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default MobileSearchTransition;

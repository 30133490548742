// extracted by mini-css-extract-plugin
export var h1Typography = "allEmployees-module--h1-typography--1tbNS";
export var h2Typography = "allEmployees-module--h2-typography--2G3xJ";
export var h3Typography = "allEmployees-module--h3-typography--Sr_B6";
export var h4Typography = "allEmployees-module--h4-typography--OcAux";
export var h5Typography = "allEmployees-module--h5-typography--2UbSV";
export var h6Typography = "allEmployees-module--h6-typography--1L97k";
export var pTypography = "allEmployees-module--p-typography--2Sa4v";
export var info = "allEmployees-module--info--96f5c";
export var buttonTypography = "allEmployees-module--button-typography--wHFYQ";
export var formLabelTypography = "allEmployees-module--form-label-typography--9ApW5";
export var menuTabTypography = "allEmployees-module--menu-tab-typography--3LzUL";
export var linkStyle = "allEmployees-module--link-style--7AA3L";
export var postTypography = "allEmployees-module--post-typography--3EadL";
export var header = "allEmployees-module--header--31T8N";
export var headerDesktop = "allEmployees-module--header-desktop--wsTeG";
export var addEmployeeContainer = "allEmployees-module--add-employee-container--3cCMS";
export var dropdownContainer = "allEmployees-module--dropdown-container--3J-qs";
export var filterButton = "allEmployees-module--filter-button--2V8Zg";
export var networkContainer = "allEmployees-module--network-container--3XzIY";
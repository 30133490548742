// extracted by mini-css-extract-plugin
export var h1Typography = "comments-module--h1-typography--1CXOx";
export var h2Typography = "comments-module--h2-typography--L7lhC";
export var h3Typography = "comments-module--h3-typography--2okDz";
export var h4Typography = "comments-module--h4-typography--1ROxX";
export var h5Typography = "comments-module--h5-typography--14bQt";
export var h6Typography = "comments-module--h6-typography--2CZil";
export var pTypography = "comments-module--p-typography--1nYT1";
export var info = "comments-module--info--179wi";
export var buttonTypography = "comments-module--button-typography--dhEp4";
export var commentFetchMore = "comments-module--comment-fetch-more--2z6NF";
export var formLabelTypography = "comments-module--form-label-typography--1e4BE";
export var menuTabTypography = "comments-module--menu-tab-typography--14-dG";
export var linkStyle = "comments-module--link-style--3eInj";
export var postTypography = "comments-module--post-typography--2a3UM";
export var commentHeader = "comments-module--comment-header--2VnWt";
export var time = "comments-module--time--3U-JR";
export var commentText = "comments-module--comment-text--2oQ4a";
export var commentContainer = "comments-module--comment-container--1K2W0";
export var username = "comments-module--username--2w3M3";
export var warningText = "comments-module--warning-text--1z916";
export var commentForm = "comments-module--comment-form--3ZldJ";
export var commentFormButtons = "comments-module--comment-form-buttons--12uZs";
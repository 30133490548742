import React from "react";
import { CompanySettings } from "../components/CompanySettings";
import StandardLayout from "../components/Layout/StandardLayout";
import Seo from "../components/seo";
import Loader from "../components/AppLoader";
import { useSelector } from "react-redux";
import { selectCompanyLoading } from "../redux/selectors/companySelectors";

export const CompanySettingsPage = () => {
  const companyLoading = useSelector(selectCompanyLoading);

  return (
    <StandardLayout>
      <Loader loading={companyLoading} />
      <Seo title="Company Settings" />
      <CompanySettings />
    </StandardLayout>
  );
};

import { Theme, createStyles } from "@material-ui/core/styles";
import {
  fontColorTertiary,
  modalBackgroundColor,
  primaryColor,
  primaryColorHover,
  secondaryColor,
  secondaryColorHover,
  textInputBackground,
  warningColor,
  warningColorHover,
} from "../../../../styles/mui/theme";

export const muiStylesButtons = (theme: Theme) =>
  createStyles({
    primaryButton: {
      "color": secondaryColor,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
    },
    secondaryButton: {
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
    },
    warningButton: {
      "color": secondaryColor,
      "border": `1px solid ${warningColor}`,
      "backgroundColor": warningColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: warningColorHover,
      },
    },
  });

export const muiStylesDialog = (theme: Theme) =>
  createStyles({
    backgroundDialog: {
      "& .MuiBackdrop-root": { "background-color": modalBackgroundColor },
    },
    dialogPaper: {
      "borderRadius": 8,
      "& .MuiDialogContent-root": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    dialogContent: {
      "padding": "0 40px 16px 24px",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
      "& .MuiTypography-paragraph": {
        padding: "0 1rem",
      },
      "& .MuiFormHelperText-root.Mui-error:first-letter": {
        textTransform: "uppercase",
      },
      "& .MuiFilledInput-root": {
        "background": textInputBackground,
        "&:hover:not($disabled)": {
          background: textInputBackground,
        },
        "&:focus:not($disabled)": {
          background: textInputBackground,
        },
        "&:autofill": {
          background: textInputBackground,
        },
        "&&&:before": {
          borderBottom: "none",
        },
      },
    },
    dialogTitle: {
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "flex-start",
      "paddingLeft": "40px",
      "paddingBottom": "40px",
      "& .MuiIconButton-root": {
        position: "relative",
        left: ".5rem",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "1rem",
      },
      "& h5": {
        marginTop: "1.75rem",
        marginBottom: "1rem",
        [theme.breakpoints.down("sm")]: {
          margin: 0,
        },
      },
    },
    bulkInfo: {
      "padding": "20px",
      "margin": "0 20px 20px",
      "backgroundColor": secondaryColorHover,
      "display": "flex",
      "justifyContent": "space-between",
      "maxHeight": "80px",
      "& > p": {
        margin: 0,
        color: fontColorTertiary,
      },
      "& .MuiButtonBase-root": {
        flexShrink: 0,
        alignSelf: "center",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
          margin: "8px auto 0 0",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        margin: 0,
      },
    },
    dialogActions: {
      "padding": "20px 40px",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem",
      },
      "& .MuiButton-root": {
        padding: "6px 40px",
        [theme.breakpoints.down("sm")]: {
          padding: "6px 12px",
        },
      },
    },
    input: {
      marginLeft: "16px",
    },
    disabled: {},
  });

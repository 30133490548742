import React, { FC, useEffect } from "react";
import * as styles from "./passwordChange.module.scss";
import PreviousButton from "../../../icons/button-previous.svg";
import {
  Button,
  FormControlLabel,
  TextField,
  makeStyles,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schemas";
import {
  muiStyleFormControlLabel,
  muiStylePasswordChange,
} from "./muiStylePasswordChange";
import {
  Passwords,
  changePassword,
} from "../../../redux/actions/passwordChangeActions";
import { selectPasswordChangeError } from "../../../redux/selectors/passwordSelectors";

import { executeScroll, useIsSmall } from "../../../utils";

interface PasswordChangeProps {
  togglePasswordChange(): void;
  userId: number;
}

const useFormControlLabelStyles = makeStyles(muiStyleFormControlLabel);
const useStyles = makeStyles(muiStylePasswordChange);

const PasswordChange: FC<PasswordChangeProps> = ({
  togglePasswordChange,
  userId,
}) => {
  const isSmall = useIsSmall();

  const classes = useStyles();
  const formControlLabelClasses = useFormControlLabelStyles();

  const dispatch = useDispatch();
  const backendErrors = useSelector(selectPasswordChangeError);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Passwords>({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((data): void => {
    dispatch(changePassword({ passwords: data, userId }));
  });

  useEffect(() => {
    if (backendErrors.message === "Incorrect credentials") {
      setError("currPassword", {
        message:
          "Current password entered is incorrect, please provide the correct password",
      });
    }
  }, [backendErrors]);

  useEffect(() => {
    executeScroll(false, "password-change");
  }, [togglePasswordChange]);

  return (
    <>
      <div className={styles.passwordChangeContainer}>
        <div
          className={styles.returnContainer}
          id="password-change"
          onClick={togglePasswordChange}
        >
          <img
            role="button"
            src={PreviousButton}
            alt="pagination_button_backward"
          />
          <span className={styles.returnText}>Change Password</span>
        </div>
        <div className={styles.passwordFormContainer}>
          <form onSubmit={onSubmit} className={styles.passwordForm}>
            <FormControlLabel
              control={
                <TextField
                  {...register("currPassword")}
                  type="password"
                  error={!!errors.currPassword}
                  helperText={errors?.currPassword?.message}
                  variant="filled"
                  InputProps={{ className: classes.textField }}
                  fullWidth
                />
              }
              classes={formControlLabelClasses}
              labelPlacement="top"
              label="CURRENT PASSWORD"
            />

            <FormControlLabel
              control={
                <TextField
                  {...register("newPassword")}
                  type="password"
                  error={!!errors.newPassword}
                  helperText={errors?.newPassword?.message}
                  variant="filled"
                  InputProps={{ className: classes.textField }}
                  fullWidth
                />
              }
              classes={formControlLabelClasses}
              labelPlacement="top"
              label="NEW PASSWORD"
            />

            <FormControlLabel
              control={
                <TextField
                  {...register("newPasswordConfirm")}
                  type="password"
                  error={!!errors.newPasswordConfirm}
                  helperText={errors?.newPasswordConfirm?.message}
                  variant="filled"
                  InputProps={{ className: classes.textField }}
                  fullWidth
                />
              }
              classes={formControlLabelClasses}
              labelPlacement="top"
              label="CONFIRM NEW PASSWORD"
            />
            {isSmall ? (
              <AppBar
                position="fixed"
                color="primary"
                className={classes.appBar}
              >
                <Toolbar>
                  <Button
                    onClick={togglePasswordChange}
                    className={`${classes.button} ${classes.buttonCancel}`}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className={`${classes.button} ${classes.buttonSave}`}
                    color="primary"
                  >
                    Save
                  </Button>
                </Toolbar>
              </AppBar>
            ) : (
              <div className={styles.buttonContainer}>
                <Button
                  type="submit"
                  className={classes.buttonDesktop}
                  color="primary"
                >
                  Save
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;

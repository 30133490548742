import * as styles from "./pulse.module.scss";
import React, { FC, useState } from "react";
import PostsList from "./Posts";
import CreatePost from "./Posts/CreatePost";
import { PostModal } from "./Posts/PostModal";

const PulseFeed: FC = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={styles.container}>
      <CreatePost onClick={() => setOpenModal(true)} />
      <PostsList />
      <PostModal open={openModal} onClose={() => setOpenModal(false)} />
    </div>
  );
};

export default PulseFeed;

import * as styles from "./userProfile.module.scss";
import {
  Avatar,
  Button,
  Dialog,
  Divider,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import muiStyles from "./muiStylesUserProfile";
import SportBadge from "../../Activity/SportBadge";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivityTypes } from "../../../redux/actions/activityTypesActions";
import { selectActivityTypes } from "../../../redux/selectors/activityTypesSelectors";
import { User as UserAPI } from "../../../api/usersAPI";
import AddActivityForm from "../../Activity/ActivityForms/AddActivityForm";
import {
  changeAttendees,
  removeAllAttendees,
} from "../../../redux/actions/activityFormActions";

const useStyles = makeStyles(muiStyles);

interface Props {
  user: UserAPI;
}

const UserProfile: FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const types = useSelector(selectActivityTypes);

  const [openForm, setOpenForm] = useState(false);

  const handleClickOpenForm = () => {
    if (props.user.isActive) {
      dispatch(changeAttendees([props.user.id]));
    }
    setOpenForm(true);
  };

  const handleClickCloseForm = () => {
    setOpenForm(false);
  };

  useEffect(() => {
    dispatch(fetchActivityTypes());
  }, [dispatch]);

  useEffect(() => {
    return function cleanup() {
      dispatch(removeAllAttendees());
    };
  }, []);

  return (
    <div className={styles.userContainer}>
      <div className={styles.userInfo}>
        <Avatar
          className={classes.userAvatar}
        >{`${props.user.firstName[0]}${props.user.lastName[0]}`}</Avatar>
        <div className={styles.userName}>
          <Typography
            variant="h5"
            className={classes.textName}
          >{`${props.user.firstName} ${props.user.lastName}`}</Typography>
          <Typography className={classes.text} variant="overline">
            {props.user.role}
          </Typography>
        </div>
        <Button
          className={classes.buttonActivity}
          color="primary"
          variant="outlined"
          onClick={handleClickOpenForm}
        >
          New Activity
        </Button>
        <Dialog fullScreen open={openForm} onClose={handleClickCloseForm}>
          <AddActivityForm open={openForm} handleClose={handleClickCloseForm} />
        </Dialog>
      </div>
      <Divider />
      <div className={styles.userData}>
        <Typography className={classes.text} variant="overline">
          Email
        </Typography>
        <Typography className={classes.textData} variant="body1">
          {props.user.email}
        </Typography>
      </div>
      <Divider />
      <div className={styles.userData}>
        <Typography className={classes.text} variant="overline">
          Phone number
        </Typography>
        <Typography className={classes.textData} variant="body1">
          {props.user.phoneNumber}
        </Typography>
      </div>
      <Divider />
      <div className={styles.userData}>
        <Typography className={classes.text} variant="overline">
          Company / Department
        </Typography>
        <Typography className={classes.textData} variant="body1">
          {props.user.department} - {props.user.team}
        </Typography>
      </div>
      <Divider />
      <div className={styles.userData}>
        <Typography className={classes.text} variant="overline">
          Interested in...
        </Typography>
        <div className={styles.userSports}>
          {props.user.activityTypes?.map((sport) => (
            <SportBadge
              key={sport}
              sport={
                types.find((activityType) => activityType.id === sport)?.name
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

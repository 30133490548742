import * as yup from "yup";

export const addDepartmentSchema = yup.object().shape({
  name: yup.string().min(1).required("This field is required"),
});

export const addUpdateTeamSchema = yup.object().shape({
  id: yup.number().min(1).optional(),
  departmentId: yup.number().min(1).required("This field is required"),
  name: yup.string().min(1).required("This field is required"),
  members: yup
    .array()
    .of(
      yup.object({
        id: yup.number().min(1).optional(),
        firstName: yup.string().min(1).required("This field is required"),
        lastName: yup.string().min(1).required("This field is required"),
      })
    )
    .required("This field is required"),
});

// extracted by mini-css-extract-plugin
export var h1Typography = "postFooter-module--h1-typography--2U8Gr";
export var h2Typography = "postFooter-module--h2-typography--21VvQ";
export var h3Typography = "postFooter-module--h3-typography--2VQqe";
export var h4Typography = "postFooter-module--h4-typography--1_Jnu";
export var h5Typography = "postFooter-module--h5-typography--6jFMG";
export var h6Typography = "postFooter-module--h6-typography--3FaO-";
export var pTypography = "postFooter-module--p-typography--1fRrn";
export var info = "postFooter-module--info--Hx79b";
export var buttonTypography = "postFooter-module--button-typography--3MguB";
export var formLabelTypography = "postFooter-module--form-label-typography--hWiBR";
export var menuTabTypography = "postFooter-module--menu-tab-typography--140oK";
export var linkStyle = "postFooter-module--link-style--sE7CL";
export var postTypography = "postFooter-module--post-typography--3xce5";
export var infoSection = "postFooter-module--info-section--1CawA";
export var buttonContainer = "postFooter-module--button-container--3_Te2";
export var hideItem = "postFooter-module--hide-item--2nMw3";
export var highlightedHighFive = "postFooter-module--highlighted-high-five--2fq7D";
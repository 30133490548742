// extracted by mini-css-extract-plugin
export var h1Typography = "employeeDisableConfirmationDialog-module--h1-typography--UTyay";
export var h2Typography = "employeeDisableConfirmationDialog-module--h2-typography--3BL-F";
export var h3Typography = "employeeDisableConfirmationDialog-module--h3-typography--3s0KY";
export var h4Typography = "employeeDisableConfirmationDialog-module--h4-typography--3n0wp";
export var h5Typography = "employeeDisableConfirmationDialog-module--h5-typography--3PHPC";
export var h6Typography = "employeeDisableConfirmationDialog-module--h6-typography--2l3Et";
export var pTypography = "employeeDisableConfirmationDialog-module--p-typography--3oJLc";
export var info = "employeeDisableConfirmationDialog-module--info--3jJ5k";
export var buttonTypography = "employeeDisableConfirmationDialog-module--button-typography--1xDMI";
export var formLabelTypography = "employeeDisableConfirmationDialog-module--form-label-typography--3iMam";
export var menuTabTypography = "employeeDisableConfirmationDialog-module--menu-tab-typography--3Bs9y";
export var linkStyle = "employeeDisableConfirmationDialog-module--link-style--J9Amu";
export var postTypography = "employeeDisableConfirmationDialog-module--post-typography--213NZ";
import { createStyles } from "@material-ui/core/styles";

export const muiStylesKebabMenu = () =>
  createStyles({
    paper: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 6px #00000029)",
    },
  });

export default muiStylesKebabMenu;

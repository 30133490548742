import { Theme, createStyles } from "@material-ui/core/styles";
import {
  desktopBreakpoint,
  drawerWidth,
  mobileBreakpoint,
} from "../../../styles/utils";
import {
  backgroundBadgeColor,
  fontColorPrimary,
  fontColorTertiary,
  modalBackgroundColor,
  primaryColor,
  primaryColorHover,
  secondaryColor,
} from "../../../styles/mui/theme";

const muiStylesSideBar = (theme: Theme) =>
  createStyles({
    "drawer": {
      "width": drawerWidth,
      "flexShrink": 0,
      "& .MuiBackdrop-root": { "background-color": modalBackgroundColor },
    },
    "drawer-paper": {
      position: "fixed",
      top: "0",
      bottom: "0",
      left: "0",
      width: drawerWidth,
      [desktopBreakpoint(theme)]: {
        width: drawerWidth,
        position: "fixed",
        height: "100%",
      },
      [mobileBreakpoint(theme)]: {
        width: drawerWidth,
        height: "100vh",
        top: "0",
        right: "auto",
        left: "0",
        zIndex: "-1",
      },
    },
    "user-photo": {
      height: "60px",
      width: "60px",
      color: primaryColor,
      backgroundColor: backgroundBadgeColor,
    },
    "menu-item": {
      "marginTop": 20,
      "marginBottom": 20,
      "color": fontColorTertiary,
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    "btn-new-activity": {
      "width": "100%",
      "color": secondaryColor,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
    },
    "toolbar": theme.mixins.toolbar,
    "listItemIcon": {
      minWidth: "40px",
    },
    "dropDownText": {
      marginLeft: "-15px",
      color: fontColorTertiary,
    },
    "nested": {
      paddingLeft: theme.spacing(7),
      color: fontColorTertiary,
    },
    "menuHighlight": {
      "border-right-color": primaryColor,
      "border-right-width": "2px",
      "border-right-style": "solid",
      "& .MuiListItemIcon-root": {
        color: primaryColor,
      },
      "& .MuiTypography-root": {
        color: fontColorPrimary,
      },
      "&:hover": {
        "background-color": "rgba(150, 61, 235, 0.1)",
      },
    },
    "menuText": {
      "color": fontColorTertiary,
      "& MuiSvgIcon-root": {
        color: fontColorTertiary,
      },
    },
  });

export default muiStylesSideBar;

import React, { FC } from "react";
import * as styles from "./uploadFileCard.module.scss";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  filename: string;
  onDelete: () => void;
}

export const UploadFileCard: FC<Props> = ({ filename, onDelete }) => {
  return (
    <div className={styles.container}>
      <div className={styles.fileName}>{filename}</div>
      <IconButton size="small" onClick={onDelete}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

import * as styles from "./statusDropdown.module.scss";
import {
  FormControl,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import React, { ChangeEvent, FC } from "react";
import muiStylesDropdown from "./muiStylesDropdown";
import { statusIcons, statusText } from "../../AttendeeStatus/statusIcons";

const useStyles = makeStyles(muiStylesDropdown);

interface Props {
  handleStatusChange?: (event: ChangeEvent<{ value: unknown }>) => void;
  status?: string;
}

const Placeholder = ({ children }: { children: string }) => {
  return <div className={styles.placeholder}>{children}</div>;
};

const Dropdown: FC<Props> = ({ handleStatusChange, status }) => {
  const classes = useStyles();

  status = status?.toUpperCase();

  return (
    <FormControl className={classes.dropdownControl} variant="outlined">
      <Select
        displayEmpty
        placeholder="join?"
        value={status}
        onChange={handleStatusChange}
        renderValue={
          status !== ""
            ? () => (
              <>
                <MenuItem value={status}>
                  <ListItemIcon className={classes.icon}>
                    <img src={statusIcons[status ?? ""]} alt="status" />
                  </ListItemIcon>
                  <ListItemText primary={statusText[status ?? ""]} />
                </MenuItem>
              </>
            )
            : () => <Placeholder>join?</Placeholder>
        }
      >
        <MenuItem value="ACCEPTED">
          <ListItemIcon className={classes.icon}>
            <img src={statusIcons.ACCEPTED} alt="goin icon" />
          </ListItemIcon>
          <ListItemText primary={statusText.ACCEPTED} />
        </MenuItem>

        <MenuItem value="TENTATIVE">
          <ListItemIcon className={classes.icon}>
            <img src={statusIcons.TENTATIVE} alt="tentative icon" />
          </ListItemIcon>
          <ListItemText primary={statusText.TENTATIVE} />
        </MenuItem>

        <MenuItem value="DECLINED">
          <ListItemIcon className={classes.icon}>
            <img src={statusIcons.DECLINED} alt="decline icon" />
          </ListItemIcon>
          <ListItemText primary={statusText.DECLINED} />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default Dropdown;

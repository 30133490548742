import * as styles from "./postLoader.module.scss";
import React, { FC } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

interface Props {
  text: string;
}

const PulseContentLoader: FC<Props> = ({ text }) => {
  return (
    <div className={styles.loaderContainer}>
      <CircularProgress size={20} thickness={2.8} color="inherit" />
      <p>{text}</p>
    </div>
  );
};

export default PulseContentLoader;

import * as styles from "./profile.module.scss";
import React, { FC } from "react";
import UserProfile from "./UserProfile";
import UserProfileTopBar from "./UserProfile/UserProfileTopBar";
import { selectUserById } from "../../redux/selectors/usersSelectors";
import { useParams } from "@reach/router";
import { useSelector } from "react-redux";
import MobileCalendar from "../Calendar/MobileCalendar";
import DesktopCalendar from "../Calendar/DesktopCalendar";
import { useIsSmall } from "../../utils";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { selectAuthUser } from "../../redux/selectors/authSelectors";
import { Link } from "gatsby";
import { ArrowForwardIos } from "@material-ui/icons";
import { allEmployeesPath, networkStructurePath } from "../../utils/paths";

interface Props {
  calendarProfile: boolean;
}

const Profile: FC<Props> = (props) => {
  const { userId } = useParams();
  const userData = useSelector(selectUserById(userId));
  const userAuth = useSelector(selectAuthUser);

  const isSmall = useIsSmall();

  return (
    <div className={styles.container}>
      {isSmall ? (
        userData ? (
          <UserProfileTopBar
            user={userData}
            calendarProfile={props.calendarProfile}
          />
        ) : null
      ) : (
        <Breadcrumbs
          className={styles.navigation}
          separator={<ArrowForwardIos fontSize="small" />}
        >
          {userAuth?.isCompanyAdmin ? (
            <Link className={styles.linkStyle} to={allEmployeesPath}>
              All Employees
            </Link>
          ) : (
            <Link className={styles.linkStyle} to={networkStructurePath}>
              Network
            </Link>
          )}
          <Typography>
            {userData ? `${userData?.firstName} ${userData?.lastName}` : ""}
          </Typography>
        </Breadcrumbs>
      )}

      <div className={styles.profileContent}>
        {userData ? (
          props.calendarProfile ? (
            <MobileCalendar userProfile user={userData} />
          ) : (
            <UserProfile user={userData} />
          )
        ) : null}
        {userData ? (
          isSmall ? null : (
            <div className={styles.calendar}>
              <DesktopCalendar userProfile user={userData} />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Profile;

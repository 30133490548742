import React, { FC } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { ButtonProps } from "../types";
import muiStylesButtons from "../muiStylesButtons";

const useStyles = makeStyles(muiStylesButtons);

const SecondaryButton: FC<ButtonProps> = ({ text, disabled, event }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.secondaryButton}
      disabled={!!disabled}
      onClick={event}
      variant="outlined"
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;

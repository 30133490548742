import * as styles from "./mobileCalendar.module.scss";
import MobileFiltering, { MobileFilteringProps } from "./mobileFiltering";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  selectMobileCalendarActivities,
  selectNextMobileCalendarRequestParams,
} from "../../../redux/selectors/activitiesSelectors";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import MobileCalendarTopBar from "./mobileCalendarTopBar";
import Month from "./month";
import { fetchActivities } from "../../../redux/actions/activitiesActions";
import { makeStyles } from "@material-ui/core/styles";
import muiStylesMobileCalendar from "./muiStylesMobileCalendar";
import { selectAuthUser } from "../../../redux/selectors/authSelectors";
import { selectCalendarFilters } from "../../../redux/selectors/calendarFiltersSelectors";
import {
  calendarSetFilters,
  calendarSetLimitOffset,
  calendarSetUserId,
} from "../../../redux/actions/calendarFiltersActions";
import { MinifiedUser, User } from "../../../api/usersAPI";
import { selectActivityCRUDprocessed } from "../../../redux/selectors/activityCRUDSelectors";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(muiStylesMobileCalendar);

interface Props {
  userProfile?: boolean;
  user?: MinifiedUser;
}

const MobileCalendarActivitiesBatchSize = 25;

const MobileCalendar: FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let userData: User | MinifiedUser | undefined = useSelector(selectAuthUser);

  if (props.userProfile) {
    userData = props.user;
  }

  const calendarFilters = useSelector(selectCalendarFilters);
  const activityModified = useSelector(selectActivityCRUDprocessed);

  // initialize filters
  useEffect(() => {
    const today = new Date();

    today.setHours(0, 0, 0, 0);

    dispatch(
      calendarSetFilters({
        startDate: today,
        limit: MobileCalendarActivitiesBatchSize,
        offset: 0,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set filter for user's profile
  useEffect(() => {
    dispatch(calendarSetUserId({ userId: userData?.id }));
  }, [dispatch, userData]);

  // trigger fetching activities on filters change
  useEffect(() => {
    // make sure current user data or user's profile data is defined before fetching activities
    // - required for proper filters work during page reloading
    userData && dispatch(fetchActivities());
  }, [dispatch, calendarFilters, userData]);

  // trigger fetching activities after activity modification
  useEffect(() => {
    if (activityModified) {
      dispatch(fetchActivities());
    }
  }, [dispatch, activityModified]);

  const processedData = useSelector(selectMobileCalendarActivities);
  const [isFilteringActive, setIsFilteringActive] = useState<boolean>(false);
  const toggleFiltering = () => {
    setIsFilteringActive(!isFilteringActive);
  };
  const changeFilterByMe = (onlyMyEvents: boolean) => {
    onlyMyEvents
      ? dispatch(calendarSetUserId({ userId: userData?.id }))
      : dispatch(calendarSetUserId({ userId: undefined }));
  };

  const mobileFilteringProps: MobileFilteringProps = {
    toggleFiltering,
    changeFilterByMe,
    filterByMe: calendarFilters.userId === userData?.id,
  };

  const ref = useRef<HTMLDivElement>(null);
  const handleScroll = () => {
    if (ref.current == null) {
      return;
    }

    const { scrollTop } = ref.current;
    let sum = 0;

    for (const node of Array.from(ref.current.children)) {
      sum += (node as HTMLElement).offsetHeight;

      if (sum > scrollTop) {
        const name = node.getAttribute("data-name");

        if (name != null && name !== curYearAndMonth) {
          setCurYearAndMonth(name);
        }

        break;
      }
    }
  };
  const [curYearAndMonth, setCurYearAndMonth] = useState<string | null>();

  const selectorParams = useSelector(selectNextMobileCalendarRequestParams);

  const showDownloadMoreButton =
    selectorParams && selectorParams.limit && selectorParams.offset;

  useEffect(() => {
    if (processedData && processedData.length > 0) {
      if (!curYearAndMonth) {
        const [month] = processedData;

        setCurYearAndMonth(`${month.monthName} ${month.year}`);
      }
    }
  }, [processedData]);

  return (
    <>
      {isFilteringActive ? (
        <MobileFiltering {...mobileFilteringProps} />
      ) : (
        <div className={styles.calendarWrapper}>
          {props.userProfile ? null : (
            <MobileCalendarTopBar
              name={curYearAndMonth}
              filterByMe={calendarFilters.userId === userData?.id}
              changeFilterByMe={changeFilterByMe}
              toggleFiltering={toggleFiltering}
            />
          )}
          {processedData.length > 0 ? (
            <div
              className={styles.calendarBackground}
              ref={ref}
              onScroll={handleScroll}
            >
              {processedData.map((month) => {
                return (
                  <Month
                    key={`month_${month.year}_${month.monthNumber}`}
                    {...month}
                  />
                );
              })}
              {showDownloadMoreButton && (
                <div
                  className={`${classes.root} ${classes.center} ${classes.moreContainer}`}
                >
                  <Button
                    className={classes.more}
                    onClick={() => {
                      dispatch(
                        calendarSetLimitOffset({
                          limit: selectorParams.limit,
                          offset: selectorParams.offset,
                        })
                      );
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Download more
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div className={styles.noActivities}>
              <Divider light />
              <p>No activities found matching selected search criteria.</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MobileCalendar;

import * as styles from "./navigation.module.scss";
import React, { FC } from "react";
import { ToolbarProps } from "react-big-calendar";
import NextButton from "../../../../icons/button-next.svg";
import PreviousButton from "../../../../icons/button-previous.svg";
import { IconButton } from "@material-ui/core";
import { muiNavigationStyles } from "./muiStylesNavigation";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(muiNavigationStyles);

export const CustomToolbar: FC<ToolbarProps<Event>> = (props) => {
  const classes = useStyles();

  return (
    <div className={styles.navigationToolbar}>
      <IconButton onClick={() => props.onNavigate("PREV")} classes={classes}>
        <img src={PreviousButton} alt="previous" />
      </IconButton>

      <IconButton onClick={() => props.onNavigate("NEXT")} classes={classes}>
        <img src={NextButton} alt="next" />
      </IconButton>

      <h3 className={styles.navigationLabel}>{props.label}</h3>
    </div>
  );
};

export const UserProfileToolbar: FC<ToolbarProps<Event>> = (props) => {
  const classes = useStyles();

  return (
    <div className={styles.navigationToolbar}>
      <IconButton onClick={() => props.onNavigate("PREV")} classes={classes}>
        <img src={PreviousButton} alt="previous" />
      </IconButton>

      <IconButton onClick={() => props.onNavigate("NEXT")} classes={classes}>
        <img src={NextButton} alt="next" />
      </IconButton>

      <h5 className={styles.userProfileNavigationLabel}>{props.label}</h5>
    </div>
  );
};

import React, { FC, useEffect } from "react";
import * as styles from "./postFooter.module.scss";
import { Divider } from "@material-ui/core";
import IconButton from "../../../../Common/Buttons/IconButton";
import HighfiveIcon from "../../../../../images/pulse/highfive.svg";
import HighlightedHighFiveIcon from "../../../../../images/pulse/highfive-highlighted.svg";
import CommentIcon from "../../../../../images/pulse/comment.svg";
import { User } from "../../../../../api/usersAPI";
import { useRequest } from "../../../../../utils/hooks/useRequest";
import { PostData, postsURL } from "../../../../../api/pulseAPI";
import { useDispatch } from "react-redux";
import { updateSinglePostData } from "../../../../../redux/reducers/pulse/pulsePostReducers";
import { Tooltip } from "@material-ui/core";

interface Props {
  postId: number;
  toggleComments: () => void;
  currentUser?: User;
  likes?: number[];
  commentsNumber?: number;
}

export const PostFooter: FC<Props> = ({
  postId,
  likes,
  currentUser,
  toggleComments,
  commentsNumber = 0,
}) => {
  const likesURL = `${postsURL}${postId}/like/`;

  const dispatch = useDispatch();
  const [addLikeResponse, addLike] = useRequest<PostData>({
    url: likesURL,
    method: "post",
  });
  const [removeLikeResponse, removeLike] = useRequest<PostData>({
    url: likesURL,
    method: "delete",
  });

  const showLikes = likes?.length;
  const showComments = commentsNumber > 0;
  const currentUserLikesThePost =
    currentUser && likes?.includes(currentUser.id);

  useEffect(() => {
    if (!addLikeResponse.isLoading && addLikeResponse.data) {
      dispatch(updateSinglePostData(addLikeResponse.data));
    }
  }, [addLikeResponse]);

  useEffect(() => {
    if (!removeLikeResponse.isLoading && removeLikeResponse.data) {
      dispatch(updateSinglePostData(removeLikeResponse.data));
    }
  }, [removeLikeResponse]);

  return (
    <>
      {(showLikes || showComments) && (
        <div className={styles.infoSection}>
          <p className={showLikes ? undefined : styles.hideItem}>
            {likes?.length} High Five{likes?.length !== 1 && "s"}
          </p>
          <Tooltip title="To view the comments, please click the Comments button below.">
            <p className={showComments ? undefined : styles.hideItem}>
              {commentsNumber} Comment{commentsNumber > 1 && "s"}
            </p>
          </Tooltip>
        </div>
      )}
      <Divider variant="middle" />
      <div className={styles.buttonContainer}>
        <IconButton
          icon={
            currentUserLikesThePost ? (
              <HighlightedHighFiveIcon className={styles.highlightedHighFive} />
            ) : (
              <HighfiveIcon />
            )
          }
          text="High Five"
          disabled={addLikeResponse.isLoading || removeLikeResponse.isLoading}
          event={() => (currentUserLikesThePost ? removeLike() : addLike())}
        />
        <IconButton
          icon={<CommentIcon />}
          text="Comments"
          event={toggleComments}
        />
      </div>
    </>
  );
};

import React, { ChangeEvent, FC } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { MenuProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import muiStylesNetworkFilterDropdown from "./muiStylesNetworkFilterDropdown";
import { ActivityTypeOption } from "../../../redux/selectors/activityTypesSelectors";
import { DepartamentOption } from "../../../redux/selectors/departmentsSelectors";
import clsx from "clsx";

export interface NetworkFilterDropdownProps {
  mobile?: boolean;
  dropdownOptions: ActivityTypeOption[] | DepartamentOption[];
  filter: string | number;
  handleFilter?(argo0: ChangeEvent<{ value: unknown }>): void;
  lastChild?: boolean;
}

const dropdownDownAnchor: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const useStyles = makeStyles(muiStylesNetworkFilterDropdown);

const NetworkFilterDropdown: FC<NetworkFilterDropdownProps> = ({
  mobile,
  dropdownOptions,
  filter,
  handleFilter,
  lastChild,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      variant="outlined"
      className={clsx(
        mobile ? classes.formControlMobile : classes.formControl,
        lastChild && classes.formControlLastChild
      )}
    >
      <Select
        MenuProps={dropdownDownAnchor}
        value={filter}
        onChange={handleFilter}
      >
        {dropdownOptions.map(
          (option: ActivityTypeOption | DepartamentOption) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default NetworkFilterDropdown;

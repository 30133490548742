import * as styles from "../pulse.module.scss";
import { MenuItem } from "@material-ui/core";
import React, {
  FC,
  useState,
  MouseEvent,
  useRef,
  useEffect,
  Children,
} from "react";
import KebabMenu from "../../Common/KebabMenu";
import DeletePopup from "../../Common/Popups/DeletePopup";
import Post from "./Post";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPosts,
  selectPostsFetchLoading,
} from "../../../redux/selectors/pulse/pulsePostsSelectors";
import EmptyStatePosts from "./EmptyStatePosts";
import { PostModal } from "./PostModal";
import {
  deletePost,
  fetchPosts,
} from "../../../redux/actions/pulse/pulsePostActions";
import { LimitOffsetFilters } from "../../../redux/actions/calendarFiltersActions";
import PulseContentLoader from "../ContentLoader";
import "./quill.custom-snow.scss";
import { selectAuthUser } from "../../../redux/selectors/authSelectors";
import { PostData } from "../../../api/pulseAPI";

const postFetchingNumber = 10;

const PostsList: FC = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);

  const currentPost = useRef<undefined | number>(undefined);

  const isMenuOpen = Boolean(anchorEl);
  const currentUser = useSelector(selectAuthUser);
  const posts = useSelector(selectPosts);
  const postsLoading = useSelector(selectPostsFetchLoading);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>, id?: number) => {
    setAnchorEl(event.currentTarget);
    currentPost.current = id;
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleDeletePost = (id?: number) => {
    id && dispatch(deletePost(id));
    setOpenDeletePopup(false);
    handleMenuClose();
  };

  const handleFetchPosts = ({ limit, offset }: LimitOffsetFilters) =>
    dispatch(fetchPosts({ limit, offset }));

  useEffect(() => {
    handleFetchPosts({ limit: postFetchingNumber });
  }, []);

  const kebabMenuContent = () => {
    const currentPostData = posts.reduce<PostData | undefined>((prev, curr) => {
      if (currentPost.current === curr.id) {
        return curr;
      }

      return prev;
    }, undefined);

    if (!currentPostData) {
      return null;
    }

    const editMenuItem = (
      <MenuItem
        onClick={() => {
          handleMenuClose();
          setOpenEditPopup(true);
        }}
      >
        Edit post
      </MenuItem>
    );

    const deleteMenuItem = (
      <span className={styles.warningText}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setOpenDeletePopup(true);
          }}
        >
          Delete post
        </MenuItem>
      </span>
    );

    if (currentPostData.owner.id === currentUser?.id) {
      return (
        <div>
          {editMenuItem}
          {deleteMenuItem}
        </div>
      );
    } else if (currentUser?.isSuperuser || currentUser?.isCompanyAdmin) {
      return <div>{deleteMenuItem}</div>;
    }

    return null;
  };

  return (
    <>
      <div className={styles.postsListContainer}>
        {
          posts.length > 0 && (
            Children.toArray(
              posts.map((post, index, { length }) => (
                <>
                  <Post
                    key={post.id}
                    id={post.id}
                    isLastElement={index === length - 1}
                    handleMenuOpen={handleMenuOpen}
                    handleFetchPosts={handleFetchPosts}
                  />
                </>
              ))
            )
          )
        }
        {!posts.length && !postsLoading && <EmptyStatePosts />}
        {postsLoading && <PulseContentLoader text="Loading new posts..." />}
      </div>

      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
      <DeletePopup
        content="Are you sure you'd like to delete this post?"
        header="Delete Post?"
        open={openDeletePopup}
        deleteMode
        onAcceptButton={() => handleDeletePost(currentPost.current)}
        onCancelButton={() => setOpenDeletePopup(false)}
      />
      <PostModal
        open={openEditPopup}
        onClose={() => setOpenEditPopup(false)}
        postId={currentPost.current ?? undefined}
      />
    </>
  );
};

export default PostsList;

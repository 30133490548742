import "react-big-calendar/lib/css/react-big-calendar.css";
import "./stylesReactBigCalendar.scss";
import * as styles from "./desktopCalendar.module.scss";
import {
  Calendar,
  View,
  momentLocalizer,
  stringOrDate,
} from "react-big-calendar";
import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Dialog, MenuProps } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { fetchActivities } from "../../../redux/actions/activitiesActions";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import muiStylesDesktopCalendar from "./muiStylesDesktopCalendar";
import { selectAuthUser } from "../../../redux/selectors/authSelectors";
import { selectDesktopCalendarActivities } from "../../../redux/selectors/activitiesSelectors";
import ActivityPreview from "../../Activity/ActivityPreview";
import { MinifiedUser, User } from "../../../api/usersAPI";
import { TimeViewMode, UserViewMode } from "../../../utils/enums";
import {
  calendarSetFilters,
  calendarSetStartEndDate,
  calendarSetUserId,
} from "../../../redux/actions/calendarFiltersActions";
import { selectCalendarFilters } from "../../../redux/selectors/calendarFiltersSelectors";
import { selectActivityCRUDprocessed } from "../../../redux/selectors/activityCRUDSelectors";
import { CustomToolbar, UserProfileToolbar } from "./Navigation";
import CustomEvent, { CustomEventAdditionalData } from "./Event";
import CustomEventWrapper from "./EventWrapper";
import { changeMode } from "../../../redux/actions/desktopCalendarActions";
import clsx from "clsx";

const localizer = momentLocalizer(moment);
const useStyles = makeStyles(muiStylesDesktopCalendar);

interface Props {
  userProfile?: boolean;
  user?: MinifiedUser;
}

const dropdownDownAnchor: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

interface MonthFirstAndLastDays {
  firstDay: Date;
  lastDay: Date;
}

const getMonthFirstAndLastDays = (date?: Date): MonthFirstAndLastDays => {
  if (!date) {
    date = new Date();
  }
  const y = date.getFullYear();
  const m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);

  return { firstDay, lastDay };
};

/*
 * Function that sets firstDay back by 7 days and sets LastDay forward 7 days, it's like this to make sure
 * that enough data is downloaded to show everything in the calendar (calendar shows a little more than just
 * the current month)
 */
const widenMonthDates = (
  month: MonthFirstAndLastDays
): MonthFirstAndLastDays => {
  const { firstDay } = month;
  const { lastDay } = month;
  const monthPadding = 7;
  const newFirstDay = new Date(
    firstDay.setDate(firstDay.getDate() - monthPadding)
  );
  const newLastDay = new Date(
    lastDay.setDate(lastDay.getDate() + monthPadding)
  );

  return {
    firstDay: newFirstDay,
    lastDay: newLastDay,
  };
};

/*
 * Gets current month start and end dates AND widens the month
 * range to display everything in the calendar properly
 */
const getMonthStartAndEndDates = (date?: Date): MonthFirstAndLastDays => {
  const monthFirstAndLastDays = getMonthFirstAndLastDays(date);

  return widenMonthDates(monthFirstAndLastDays);
};

const views: View[] = ["month", "day", "week"];

const DesktopCalendar: FC<Props> = (props) => {
  const classes = useStyles();
  const processedActivities: CustomEventAdditionalData[] = useSelector(
    selectDesktopCalendarActivities
  );
  const calendarFilters = useSelector(selectCalendarFilters);
  const activityModified = useSelector(selectActivityCRUDprocessed);
  let userData: User | MinifiedUser | undefined = useSelector(selectAuthUser);

  if (props.userProfile) {
    userData = props.user;
  }

  const [openActivity, setOpenActivity] = React.useState(false);
  const [activityToPreview, setActivityToPreview] = React.useState<number>(0);

  const dispatch = useDispatch();

  // initialize filters
  useEffect(() => {
    const month = getMonthStartAndEndDates();

    dispatch(
      calendarSetFilters({
        startDate: month.firstDay,
        endDate: month.lastDay,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set filter for user's profile
  useEffect(() => {
    dispatch(calendarSetUserId({ userId: userData?.id }));
  }, [dispatch, userData]);

  // trigger fetching activities on filters change
  useEffect(() => {
    // make sure current user data or user's profile data is defined before fetching activities
    // - required for proper filters work during page reloading
    userData && dispatch(fetchActivities());
  }, [dispatch, calendarFilters, userData]);

  // trigger fetching activities after activity modification
  useEffect(() => {
    if (activityModified) {
      dispatch(fetchActivities());
    }
  }, [dispatch, activityModified]);

  useEffect(() => {
    return function cleanup() {
      dispatch(changeMode("month"));
    };
  }, []);

  // Activity preview handlers
  const handleOpenActivity = (event: { id: React.SetStateAction<number> }) => {
    setActivityToPreview(event.id);
    setOpenActivity(true);
  };

  const handleCloseActivity = () => {
    setOpenActivity(false);
  };

  // Calendar handlers
  const [viewTimeMode, setViewTimeMode] = React.useState<View>(
    TimeViewMode.Month
  );

  const handleViewUserChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    event.target.value === UserViewMode.Me
      ? dispatch(calendarSetUserId({ userId: userData?.id }))
      : dispatch(calendarSetUserId());
  };

  const onViewChange = (e: string | ((prevState: View) => View)) => {
    if (e === "day") {
      setViewTimeMode(e);
    }
  };

  const onViewModeChange = (value: string) => {
    setViewTimeMode(value as View);
    dispatch(changeMode(value));
  };

  const onNavigate = (e: Date) => {
    checkForMonthChange(e);
  };

  const onCalendarRangeChange = (
    event: Date[] | { start: stringOrDate; end: stringOrDate }
  ) => {
    if ("start" in event) {
      let startDate;
      let endDate;

      if (typeof event.start === "string") {
        startDate = new Date(event.start);
      } else {
        startDate = event.start;
      }

      if (typeof event.end === "string") {
        endDate = new Date(event.end);
      } else {
        endDate = event.end;
      }

      dispatch(calendarSetStartEndDate({ startDate, endDate }));
    } else if (Array.isArray(event)) {
      const [curDate] = event;

      checkForMonthChange(curDate);
    }
  };

  const checkForMonthChange = (checkedDate: Date) => {
    const monthStartAndEndDates: MonthFirstAndLastDays = getMonthStartAndEndDates(
      checkedDate
    );

    if (
      monthStartAndEndDates.firstDay.getTime() !==
      calendarFilters.startDate?.getTime()
    ) {
      dispatch(
        calendarSetStartEndDate({
          startDate: monthStartAndEndDates.firstDay,
          endDate: monthStartAndEndDates.lastDay,
        })
      );
    }
  };

  return (
    <div className={styles.calendarContainer}>
      <div className={styles.dropdownContainer}>
        {props.userProfile ? null : (
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              MenuProps={dropdownDownAnchor}
              value={
                calendarFilters.userId === userData?.id
                  ? UserViewMode.Me
                  : UserViewMode.All
              }
              onChange={handleViewUserChange}
            >
              <MenuItem value={UserViewMode.Me}>Me</MenuItem>
              <MenuItem value={UserViewMode.All}>Everyone</MenuItem>
            </Select>
          </FormControl>
        )}
        <FormControl
          variant="outlined"
          className={clsx(classes.formControl, classes.formControlLastChild)}
        >
          <Select
            MenuProps={dropdownDownAnchor}
            value={viewTimeMode}
            onChange={(event) => onViewModeChange(event.target.value as string)}
          >
            <MenuItem value={TimeViewMode.Month}>Month</MenuItem>
            <MenuItem value={TimeViewMode.Week}>Week</MenuItem>
            <MenuItem value={TimeViewMode.Day}>Day</MenuItem>
          </Select>
        </FormControl>
      </div>

      <Calendar
        localizer={localizer}
        events={processedActivities}
        components={{
          toolbar: props.userProfile ? UserProfileToolbar : CustomToolbar,
          event: CustomEvent,
          eventWrapper: CustomEventWrapper,
        }}
        onRangeChange={onCalendarRangeChange}
        onNavigate={onNavigate}
        className={props.userProfile ? "calendar--main user" : "calendar--main"}
        views={views}
        view={viewTimeMode ? viewTimeMode : "month"}
        onView={onViewChange}
        onSelectEvent={handleOpenActivity}
      />
      <Dialog fullScreen open={openActivity} onClose={handleCloseActivity}>
        <ActivityPreview
          open={openActivity}
          handleClose={handleCloseActivity}
          id={activityToPreview}
        />
      </Dialog>
    </div>
  );
};

export default DesktopCalendar;

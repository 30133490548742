import * as React from "react";
import * as styles from "./event.module.scss";
import { FC } from "react";
import { Event, EventProps } from "react-big-calendar";
import { MinifiedUser } from "../../../../api/usersAPI";
import { useSelector } from "react-redux";
import { selectCalendarMode } from "../../../../redux/selectors/desktopCalendarSelectors";

export interface CustomEventAdditionalData {
  id: number;
  owner: MinifiedUser;
  attendees: MinifiedUser[];
  closed: boolean;
}

export interface CustomEvent extends Event, CustomEventAdditionalData {}

const CustomEvent: FC<EventProps<CustomEvent>> = (props) => {
  const getStartTime = () => {
    const time = props.event.start;

    return new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    }).format(time);
  };

  const calendarMode = useSelector(selectCalendarMode);

  return (
    <>
      <div className={styles.activityName}>
        <span className={styles.activityTime}>
          {calendarMode === "month" ? getStartTime() : ""}
        </span>{" "}
        {props.title}
      </div>
    </>
  );
};

export default CustomEvent;

import * as React from "react";
import * as styles from "./activity.module.scss";
import { FC, useState } from "react";
import { Dialog } from "@material-ui/core";
import ActivityPreview from "../../../Activity/ActivityPreview/index";
import { useSelector } from "react-redux";
import { selectAuthUser } from "../../../../redux/selectors/authSelectors";
import { MinifiedUser, User } from "../../../../api/usersAPI";
import { isUserAllowedInActivity } from "../../../../utils";

export interface ActivityProps {
  id: number;
  title: string;
  startTime: string;
  endTime: string;
  attendees: MinifiedUser[];
  owner: MinifiedUser;
  closed: boolean;
}

const Activity: FC<ActivityProps> = (props) => {
  const curUser = useSelector(selectAuthUser);
  const [openActivity, setOpenActivity] = useState(false);

  const handleOpenActivity = () => {
    setOpenActivity(true);
  };

  const handleCloseActivity = () => {
    setOpenActivity(false);
  };

  return (
    <>
      <div
        className={`${styles.activity} ${
          isUserAllowedInActivity(
            props.closed,
            props.owner.id,
            props.attendees,
            curUser?.id
          )
            ? styles.activityActive
            : ""
        }`}
        onClick={handleOpenActivity}
        role="button"
      >
        <div className={styles.activityName}>{props.title}</div>
        <div className={styles.activityTime}>
          {props.startTime} - {props.endTime}
        </div>
      </div>
      <Dialog fullScreen open={openActivity} onClose={handleCloseActivity}>
        <ActivityPreview
          handleClose={handleCloseActivity}
          id={props.id}
          open={openActivity}
        />
      </Dialog>
    </>
  );
};

export default Activity;

// extracted by mini-css-extract-plugin
export var h1Typography = "searchResults-module--h1-typography--3EkMf";
export var h2Typography = "searchResults-module--h2-typography--3hzW6";
export var h3Typography = "searchResults-module--h3-typography--3RPv1";
export var h4Typography = "searchResults-module--h4-typography--j3nC9";
export var h5Typography = "searchResults-module--h5-typography--3Tshv";
export var h6Typography = "searchResults-module--h6-typography--Uf6aH";
export var pTypography = "searchResults-module--p-typography--29u0g";
export var info = "searchResults-module--info--1hYcj";
export var buttonTypography = "searchResults-module--button-typography--14pGr";
export var formLabelTypography = "searchResults-module--form-label-typography--217fg";
export var menuTabTypography = "searchResults-module--menu-tab-typography--wLVtj";
export var linkStyle = "searchResults-module--link-style--19XJn";
export var postTypography = "searchResults-module--post-typography--3dn3A";
export var container = "searchResults-module--container--2K-FV";
import { Activity } from "../../components/Activity/ActivityPreview";
import { RootState } from "../store";

export const selectActivityById = (activityId?: number) => (store: {
  activities: { activities: Activity[] };
}) => {
  return store.activities.activities.find(({ id }) => id === activityId);
};

export const selectActivityCRUDprocessed = (store: RootState) => {
  return store.activityCRUD.processed;
};

export const selectActivityModificationLoading = (store: RootState) => {
  return store.activityCRUD.loading;
};

import Activity, { ActivityProps } from "../activity";
import React, { FC } from "react";
import * as styles from "./day.module.scss";

export interface DayProps {
  dayNumber: number;
  dayName: string;
  isToday: boolean;
  activities: ActivityProps[];
}

const Day: FC<DayProps> = (props) => {
  return (
    <div className={styles.dayContainer}>
      <div className={styles.dayInfo}>
        <div className={styles.dayInfoDay}>{props.dayName}</div>
        <div
          className={`${styles.dayInfoNumber} ${
            props.isToday ? styles.dayInfoToday : ""
          }`}
          id={props.isToday ? "day--info-today" : ""}
        >
          {props.dayNumber}
        </div>
      </div>
      <div className={styles.day}>
        {props.activities.map((activity) => {
          return <Activity key={`activity_${activity.id}`} {...activity} />;
        })}
      </div>
    </div>
  );
};

export default Day;

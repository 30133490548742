import { createStyles } from "@material-ui/core/styles";
import { primaryColor, secondaryColor } from "../../../styles/mui/theme";

const muiStylesNetwork = () =>
  createStyles({
    button: {
      backgroundColor: primaryColor,
      color: secondaryColor,
      textTransform: "capitalize",
      width: "150px",
      height: "45px",
      fontSize: "17px",
    },
    buttonHidden: {
      visibility: "hidden",
    },
  });

export default muiStylesNetwork;

import * as styles from "./activityTopBar.module.scss";
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { ChangeEvent, FC, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import muiStylesActivityTopBar from "./muiStylesActivityTopBar";
import { deleteActivity } from "../../../redux/actions/activityCRUDActions";
import { useDispatch, useSelector } from "react-redux";
import { selectActivityById } from "../../../redux/selectors/activityCRUDSelectors";
import { Dialog } from "@material-ui/core";
import Dropdown from "./StatusDropdown";
import UpdateActivityForm from "../ActivityForms/UpdateActivityForm";
import { User } from "../../../api/usersAPI";
import { muiStylesButtons } from "../../CompanySettings/muiStylesCompanySettings";

const useStyles = makeStyles(muiStylesActivityTopBar);
const useButtonStyles = makeStyles(muiStylesButtons);

interface Props {
  activity?: number;
  closed?: boolean;
  editForm?: boolean;
  handleClose: () => void;
  handleStatusChange?: (event: ChangeEvent<{ value: unknown }>) => void;
  organizer?: boolean;
  preview?: boolean;
  status?: string;
  userData?: User;
}

const ActivityTopBar: FC<Props> = (props) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();

  const activity = useSelector(selectActivityById(props.activity));
  const [openForm, setOpenForm] = useState(false);

  // show attendee dropdown only for open activities which are not finished yet
  const isDropdownVisible =
    activity?.startDate && Date.parse(activity.endDate) > new Date().getTime()
      ? activity?.attendees.some(({ id }) => id === props.userData?.id) ||
        !activity?.closed
      : false;

  // show Edit-Delete buttons only to organizer for activities which are not finished yet
  const isEditDeleteVisible =
    activity?.startDate && Date.parse(activity.endDate) > new Date().getTime()
      ? props.organizer
      : false;

  const handleDeleteActivity = () => {
    if (props.activity) {
      dispatch(deleteActivity(props.activity));
      props.handleClose();
    }
  };

  const handleEditActivity = () => {
    setOpenForm(true);
  };

  const handleCloseEditActivity = () => {
    setOpenForm(false);
  };

  return (
    <AppBar className={classes.root} elevation={0}>
      <Toolbar className={styles.topbarCustom}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={props.handleClose}
          aria-label="close"
          className={classes.iconClose}
        >
          <CloseIcon />
        </IconButton>

        {props.preview ? (
          isEditDeleteVisible ? (
            <div>
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonDelete}
                form="activity-form"
                onClick={handleDeleteActivity}
              >
                Delete
              </Button>
              <Button
                className={buttonClasses.primaryButton}
                form="update-activity-form"
                onClick={handleEditActivity}
              >
                Edit
              </Button>
              <Dialog
                fullScreen
                open={openForm}
                onClose={handleCloseEditActivity}
              >
                <UpdateActivityForm
                  activity={activity}
                  open={openForm}
                  handleClose={handleCloseEditActivity}
                  editMode
                />
              </Dialog>
            </div>
          ) : props.closed ? (
            isDropdownVisible ? (
              <Dropdown
                handleStatusChange={props.handleStatusChange}
                status={props.status}
              />
            ) : null
          ) : (
            <Dropdown
              handleStatusChange={props.handleStatusChange}
              status={props.status}
            />
          )
        ) : (
          <>
            {props.editForm ? (
              <>
                <Typography variant="h6">Edit Activity</Typography>
                <Button
                  className={buttonClasses.primaryButton}
                  type="submit"
                  form="update-activity-form"
                >
                  Update
                </Button>
              </>
            ) : (
              <>
                <Typography variant="h6">New Activity</Typography>
                <Button
                  className={buttonClasses.primaryButton}
                  type="submit"
                  form="activity-form"
                >
                  Save
                </Button>
              </>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default ActivityTopBar;

// extracted by mini-css-extract-plugin
export var h1Typography = "month-module--h1-typography--1llxH";
export var h2Typography = "month-module--h2-typography--1liW1";
export var h3Typography = "month-module--h3-typography--3dfWE";
export var h4Typography = "month-module--h4-typography--1ovkB";
export var h5Typography = "month-module--h5-typography--IVhuQ";
export var h6Typography = "month-module--h6-typography--2KH5E";
export var pTypography = "month-module--p-typography--3YsJZ";
export var info = "month-module--info--3VqC0";
export var buttonTypography = "month-module--button-typography--iWb5h";
export var formLabelTypography = "month-module--form-label-typography--1zE7Z";
export var menuTabTypography = "month-module--menu-tab-typography--1G7OD";
export var linkStyle = "month-module--link-style--sH_91";
export var postTypography = "month-module--post-typography--2gVNI";
export var month = "month-module--month--2Ttaw";
export var monthHeader = "month-module--month--header--24m7H";
// extracted by mini-css-extract-plugin
export var h1Typography = "form-module--h1-typography--3XZVl";
export var h2Typography = "form-module--h2-typography--2Gt4X";
export var h3Typography = "form-module--h3-typography--3SsIq";
export var h4Typography = "form-module--h4-typography--34JVR";
export var h5Typography = "form-module--h5-typography--1vWQb";
export var h6Typography = "form-module--h6-typography--1WyNH";
export var pTypography = "form-module--p-typography--2VvcW";
export var info = "form-module--info--3mAPB";
export var buttonTypography = "form-module--button-typography--2elWf";
export var formLabelTypography = "form-module--form-label-typography--vMMMG";
export var menuTabTypography = "form-module--menu-tab-typography--3TKsc";
export var linkStyle = "form-module--link-style--14JRp";
export var postTypography = "form-module--post-typography--311jo";
export var formContainer = "form-module--form-container--2MXaK";
export var icon = "form-module--icon--_hFWV";
export var iconClose = "form-module--icon-close--1L3SL";
export var closeContainer = "form-module--close-container--ieMhP";
export var inputDate = "form-module--inputDate--kbVji";
export var form = "form-module--form--1-SSk";
export var formTime = "form-module--form-time--1tAT2";
export var formActivity = "form-module--form-activity--cuFfV";
export var formActivityAttendees = "form-module--form-activity-attendees--2NENQ";
export var formActivityShowToAll = "form-module--form-activity-show-to-all--3IdGf";
export var formActivityShowToAllDisabled = "form-module--form-activity-show-to-all-disabled--1kkcG";
export var formLocation = "form-module--form-location--3WXwk";
export var formDescription = "form-module--form-description--3Mkps";
export var formButtons = "form-module--form-buttons--3HJ1L";
export var formClosed = "form-module--form-closed--2b65w";
export var topBarCustom = "form-module--top--bar-custom--Y5xPk";
export var ownerContainer = "form-module--owner-container--21y2p";
export var attendeeContainer = "form-module--attendee-container--YQaTn";
export var attendeeName = "form-module--attendee-name--1Ierz";
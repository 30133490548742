// extracted by mini-css-extract-plugin
export var h1Typography = "event-module--h1-typography--2C5dG";
export var h2Typography = "event-module--h2-typography--nmXNN";
export var h3Typography = "event-module--h3-typography--1hOLH";
export var h4Typography = "event-module--h4-typography--15lW0";
export var h5Typography = "event-module--h5-typography--1M_lC";
export var h6Typography = "event-module--h6-typography--2r-yY";
export var pTypography = "event-module--p-typography--2d_RY";
export var info = "event-module--info--SMvqA";
export var buttonTypography = "event-module--button-typography--13Zoz";
export var formLabelTypography = "event-module--form-label-typography--3baKw";
export var menuTabTypography = "event-module--menu-tab-typography--2-fFN";
export var linkStyle = "event-module--link-style--O9jZ0";
export var postTypography = "event-module--post-typography--1tPX9";
export var activityName = "event-module--activity--name--nUhVa";
export var activityTime = "event-module--activity--time--31aP6";
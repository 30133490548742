import { Theme, createStyles } from "@material-ui/core/styles";
import { mobileBreakpoint } from "../../../styles/utils";
import {
  borderColor,
  fontColorTertiary,
  inputBackgroundColor,
  inputBorderBottomColor,
  primaryColor,
  secondaryColor,
  secondaryColorHover,
} from "../../../styles/mui/theme";

export const muiStylesForm = (theme: Theme) =>
  createStyles({
    form: theme.mixins.toolbar,
    input: {
      "width": "100%",
      "marginLeft": "20px",
      "& .MuiFilledInput-root": {
        background: inputBackgroundColor,
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": "1px solid transparent",
      },
      "& .MuiFilledInput-input": {
        padding: "19px 12px 19px",
      },
      "& .MuiAutocomplete-inputRoot": {
        paddingTop: "9px",
        paddingBottom: "9px",
      },
      "& .MuiFilledInput-multiline": {
        padding: "0",
      },
    },
    inputTitle: {
      "width": "50%",
      "margin": "0 auto",
      [mobileBreakpoint(theme)]: {
        width: "80%",
      },
      "& .MuiInput-underline:before": {
        "border-bottom-color": inputBorderBottomColor,
      },
      "& .MuiFilledInput-underline:hover": {
        "border-bottom": `1px solid ${inputBorderBottomColor}`,
      },
    },
    select: {
      "marginLeft": "20px",
      "& .MuiSelect-filled.MuiSelect-filled": {
        "padding-right": "32px",
        "display": "flex",
        "width": "fit-content",
        "align-items": "center",
      },
      "& .MuiFilledInput-root": {
        background: inputBackgroundColor,
        paddingTop: 0,
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": "1px solid transparent",
      },
      "& .MuiFilledInput-underline:after": {
        "border-bottom": "1px solid transparent",
      },

      "& .MuiFilledInput-input": {
        padding: "10px 12px 10px",
      },
    },
    icon: {
      "margin-right": "10px",
      "min-width": "0px",
    },
    btnCancel: {
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "margin-right": "10px",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
      "& + &": {
        marginLeft: "auto",
        padding: "6px 40px",
      },
    },
    iconClose: {
      "width": "fit-content",
      "margin-right": "15px",
    },
    removeAttendeeButton: {
      color: fontColorTertiary,
      marginLeft: "auto",
    },
    removeAttendeeIcon: {
      fontSize: "medium",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      textTransform: "capitalize",
    },
    titleSize: {
      "fontSize": "32px",
      "font-weight": "900",
    },
    switch: {
      "marginLeft": "15px",
      "& .MuiSwitch-track": {
        background: borderColor,
        opacity: 1,
      },
    },
  });

export default muiStylesForm;

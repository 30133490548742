// extracted by mini-css-extract-plugin
export var h1Typography = "user-module--h1-typography--1YO6c";
export var h2Typography = "user-module--h2-typography--gq6zQ";
export var h3Typography = "user-module--h3-typography--gMnQG";
export var h4Typography = "user-module--h4-typography--1Kmeh";
export var h5Typography = "user-module--h5-typography--3anzW";
export var h6Typography = "user-module--h6-typography--SMtyC";
export var pTypography = "user-module--p-typography--2auC1";
export var info = "user-module--info--wvRyb";
export var buttonTypography = "user-module--button-typography--3mXGs";
export var formLabelTypography = "user-module--form-label-typography--2MVa6";
export var menuTabTypography = "user-module--menu-tab-typography--3lYw8";
export var linkStyle = "user-module--link-style--Sy1Bg";
export var postTypography = "user-module--post-typography--14P6m";
export var userContainer = "user-module--user-container--1OnbB";
export var userContainerChecked = "user-module--user-container-checked--2kGpi";
export var checkboxContainer = "user-module--checkbox-container--8XAUW";
export var avatar = "user-module--avatar--3kLrD";
export var userInfo = "user-module--user-info--14wIB";
export var userName = "user-module--user-name--3N5Er";
export var userDepartment = "user-module--user-department--2Mfy7";
export var menuButton = "user-module--menu-button--1Mqyt";
export var activityContainer = "user-module--activity-container--3jR2O";
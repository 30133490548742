import React, { ChangeEvent, FC } from "react";
import * as styles from "./mobileFiltering.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import muiStylesMobileFiltering from "./muistylesMobileFiltering";
import Button from "@material-ui/core/Button";
import NetworkFilterDropdown from "../NetworkFilterDropdown";
import { ActivityTypeOption } from "../../../redux/selectors/activityTypesSelectors";
import { DepartamentOption } from "../../../redux/selectors/departmentsSelectors";

export interface MobileFilteringProps {
  toggleFiltering(): void;
  handleActivityFilter?(arg0: ChangeEvent<{ value: unknown }>): void;
  activityFilter: string | number;
  activityFilters: ActivityTypeOption[];
  handleDepartmentFilter?(arg0: ChangeEvent<{ value: unknown }>): void;
  departmentFilter: string | number;
  departmentFilters: DepartamentOption[];
}

const useStyles = makeStyles(muiStylesMobileFiltering);

const MobileFiltering: FC<MobileFilteringProps> = ({
  activityFilter,
  toggleFiltering,
  handleActivityFilter,
  activityFilters,
  departmentFilter,
  handleDepartmentFilter,
  departmentFilters,
}) => {
  const classes = useStyles();

  return (
    <div className={styles.filteringContainer}>
      <div>
        <div className={styles.filteringContainerInput}>
          <div>
            <NetworkFilterDropdown
              mobile
              dropdownOptions={departmentFilters}
              filter={departmentFilter}
              handleFilter={handleDepartmentFilter}
            />
          </div>
          <div>
            <NetworkFilterDropdown
              mobile
              dropdownOptions={activityFilters}
              filter={activityFilter}
              handleFilter={handleActivityFilter}
            />
          </div>
        </div>

        <div>
          <div className={classes.root}>
            <Button
              onClick={toggleFiltering}
              className={classes.apply}
              variant="contained"
              color="primary"
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFiltering;

import * as styles from "./post.module.scss";
import { IconButton as MUIIconButton } from "@material-ui/core";
import React, { FC, MouseEvent, useEffect, useRef, useState } from "react";
import UserAvatar from "../../../Common/UserAvatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useSelector } from "react-redux";
import {
  selectPostById,
  selectPostsFetchArgs,
} from "../../../../redux/selectors/pulse/pulsePostsSelectors";
import { selectAuthUser } from "../../../../redux/selectors/authSelectors";
import { useIntersectionObserver } from "usehooks-ts";
import moment from "moment";
import { LimitOffsetFilters } from "../../../../redux/actions/calendarFiltersActions";
import ReactQuill from "react-quill";
import CommentsList from "../../Comments";
import { PostFooter } from "./PostFooter";
import { FileCard } from "./FileCard";
import {
  extractFilenameFromURL,
  isCodecSupportedByBrowser,
  SUPPORTED_IMG_SUFFIXES,
  SUPPORTED_VIDEO_SUFFIXES,
} from "../../../../utils/pulse";
import { VideoPlayer } from "./VideoPlayer";
import { replaceOldCDN } from "../../../../utils/emoji";

interface Props {
  id: number;
  isLastElement: boolean;
  handleFetchPosts: ({ limit, offset }: LimitOffsetFilters) => void;
  handleMenuOpen: (event: MouseEvent<HTMLElement>, id?: number) => void;
}

const Post: FC<Props> = ({
  id,
  isLastElement,
  handleMenuOpen,
  handleFetchPosts,
}) => {
  const post = useSelector(selectPostById(id));

  let youtubeUrl = null;

  if (post?.body) {
    const matches = Array.from(
      post.body.matchAll(
        /<a.+?href="((http(s)?:\/\/)?((w){3}\.|(m\.))?youtu(be|.be)?(\.com)?\/.+?)"/g
      )
    );

    youtubeUrl = matches.length === 1 && matches[0][1];
  }

  const currentUser = useSelector(selectAuthUser);
  const nextFetchParams = useSelector(selectPostsFetchArgs);

  const [videoPlaying, setVideoPlaying] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, { freezeOnceVisible: true });

  const isPostVisible = !!entry?.isIntersecting;
  const isAuthor = currentUser?.id === post?.owner.id;

  const [commentsOpen, setCommentsOpen] = useState(false);

  const toggleComments = () => setCommentsOpen((value) => !value);

  // If a user scrolls to the last post on the page, new ones will be fetch
  useEffect(() => {
    isLastElement &&
      isPostVisible &&
      nextFetchParams &&
      handleFetchPosts(nextFetchParams);
  }, [isPostVisible]);

  // display medium depending on it's extension
  let mediumComponent = null;

  if (post?.medium) {
    if (
      SUPPORTED_IMG_SUFFIXES.some((suffix) =>
        extractFilenameFromURL(post.medium?.toLowerCase())?.endsWith(
          suffix.toLowerCase()
        )
      )
    ) {
      mediumComponent = (
        <div className={styles.imgContainer}>
          <img alt="post's medium" src={post.medium} />
        </div>
      );
    } else if (
      SUPPORTED_VIDEO_SUFFIXES.some((suffix) =>
        extractFilenameFromURL(post.medium?.toLowerCase())?.endsWith(
          suffix.toLowerCase()
        )
      ) &&
      isCodecSupportedByBrowser(post.videoCodec)
    ) {
      mediumComponent = (
        <VideoPlayer
          url={post.medium}
          videoPlaying={videoPlaying}
          setVideoPlaying={setVideoPlaying}
          thumbnail={post.thumbnail}
        />
      );
    } else {
      mediumComponent = <FileCard fileURL={post.medium} />;
    }
  } else if (youtubeUrl) {
    mediumComponent = (
      <VideoPlayer
        url={youtubeUrl}
        videoPlaying={videoPlaying}
        setVideoPlaying={setVideoPlaying}
      />
    );
  }

  return (
    <>
      <div className={styles.container} ref={ref}>
        <div className={styles.topbar}>
          <div className={styles.topbarUser}>
            <UserAvatar
              firstName={post?.owner.firstName}
              lastName={post?.owner.lastName}
            />
            <p className={styles.username}>
              {`${post?.owner.firstName ?? ""} ${post?.owner.lastName ?? ""}`}
            </p>
            <p className={styles.time}>
              {moment(post?.creationDate).fromNow()}
            </p>
          </div>
          {(isAuthor ||
            currentUser?.isSuperuser ||
            currentUser?.isCompanyAdmin) && (
            <MUIIconButton onClick={(event) => handleMenuOpen(event, post?.id)}>
              <MoreVertIcon />
            </MUIIconButton>
          )}
        </div>
        <div className="pulse-posts-react-quill-read-only-container">
          <ReactQuill
            // react-quill styles are located in `src/components/Pulse/Posts/quill.custom-snow.scss,
            // are imported in PostsList component and are global for the application
            theme="snow"
            value={replaceOldCDN(post?.body)}
            readOnly
            modules={{ toolbar: [] }}
          />
        </div>
        {mediumComponent}
        {post && (
          <PostFooter
            postId={post.id}
            likes={post.likes}
            currentUser={currentUser}
            toggleComments={toggleComments}
            commentsNumber={post.commentsNum}
          />
        )}
        {commentsOpen && <CommentsList postId={id} />}
      </div>
    </>
  );
};

export default Post;

import { createStyles } from "@material-ui/core";

const muiStylesUserTopBar = () =>
  createStyles({
    root: {
      position: "sticky",
      top: "0",
    },
    toolbar: {
      "color": "black",
      "background-color": "white",
      "justify-content": "space-between",
      "width": "inherit",
      "margin-left": "auto",
      "margin-right": "auto",
    },
  });

export default muiStylesUserTopBar;

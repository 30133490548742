import { DialogActions, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { muiStyles } from "../muiStyles";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";

const useStyles = makeStyles(muiStyles);

interface Props {
  submit: () => void;
  submitDisabled: boolean;
}

export const Footer: FC<Props> = ({ submit, submitDisabled }) => {
  const classes = useStyles();

  return (
    <DialogActions className={classes.dialogActions}>
      <PrimaryButton
        text="Let's Go!"
        event={submit}
        disabled={submitDisabled}
      />
    </DialogActions>
  );
};

import React, { FC } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { ButtonProps } from "../types";
import muiStylesButtons from "../muiStylesButtons";

const useStyles = makeStyles(muiStylesButtons);

interface Props extends ButtonProps {
  submit?: boolean;
}

const PrimaryButton: FC<Props> = ({ text, disabled, event, submit }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.primaryButton}
      disabled={!!disabled}
      onClick={event}
      type={submit ? "submit" : "button"}
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;

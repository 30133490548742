import React from "react";
import StandardLayout from "../components/Layout/StandardLayout";
import Profile from "../components/Profile";

const ProfilePage = () => {
  return (
    <StandardLayout>
      <Profile calendarProfile={false} />
    </StandardLayout>
  );
};

export default ProfilePage;

import * as styles from "../comments.module.scss";
import React, { FC, MouseEvent } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { IconButton as MUIIconButton } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectAuthUser } from "../../../../redux/selectors/authSelectors";
import moment from "moment";
import {Comment} from "../../../../api/pulseAPI";

interface Props {
  comment?: Comment;
  handleMenuOpen: (event: MouseEvent<HTMLElement>, id?: number) => void;
}

const CommentContent: FC<Props> = ({ comment, handleMenuOpen }) => {
  const currentUser = useSelector(selectAuthUser);
  const isAuthor = currentUser?.id === comment?.owner.id;

  return (
    <>
      <div>
        <div className={styles.commentHeader}>
          <p className={styles.username}>{`${comment?.owner.firstName ?? ""} ${
            comment?.owner.lastName ?? ""
          }`}</p>
          <p className={styles.time}>
            {moment(comment?.creationDate).fromNow()}
          </p>
        </div>
        <p className={styles.commentText}>{comment?.body}</p>
      </div>
      {(isAuthor || currentUser?.isSuperuser || currentUser?.isCompanyAdmin) && (
        <MUIIconButton onClick={(event) => handleMenuOpen(event, comment?.id)}>
          <MoreVertIcon />
        </MUIIconButton>
      )}
    </>
  );
};

export default CommentContent;

import Calendar from "../components/Calendar";

import React from "react";
import Seo from "../components/seo";
import Loader from "../components/AppLoader";
import { useSelector } from "react-redux";
import { selectAuthUserLoading } from "../redux/selectors/authSelectors";
import { selectActivitiesLoading } from "../redux/selectors/activitiesSelectors";
import { selectActivityModificationLoading } from "../redux/selectors/activityCRUDSelectors";
import StandardLayout from "../components/Layout/StandardLayout";

const CalendarPage = () => {
  const authUserLoading = useSelector(selectAuthUserLoading);
  const activitiesLoading = useSelector(selectActivitiesLoading);
  const activityIsBeingModified = useSelector(
    selectActivityModificationLoading
  );

  return (
    <StandardLayout>
      <Loader
        loading={
          authUserLoading || activitiesLoading || activityIsBeingModified
        }
      />
      <Seo title="Calendar" />
      <Calendar />
    </StandardLayout>
  );
};

export default CalendarPage;

// extracted by mini-css-extract-plugin
export var h1Typography = "activities-module--h1-typography--1enoU";
export var h2Typography = "activities-module--h2-typography--Fm--A";
export var h3Typography = "activities-module--h3-typography--XEBQu";
export var h4Typography = "activities-module--h4-typography--17U4b";
export var h5Typography = "activities-module--h5-typography--38mjm";
export var h6Typography = "activities-module--h6-typography--3YlrM";
export var pTypography = "activities-module--p-typography--2EEre";
export var info = "activities-module--info--1DhKF";
export var buttonTypography = "activities-module--button-typography--1yw5E";
export var formLabelTypography = "activities-module--form-label-typography--28BFY";
export var menuTabTypography = "activities-module--menu-tab-typography--2vhKR";
export var linkStyle = "activities-module--link-style--oMaHN";
export var postTypography = "activities-module--post-typography--3lKLh";
export var activitiesContainer = "activities-module--activities-container--2KfOJ";
export var activityContainer = "activities-module--activity-container--2L8jX";
export var activityIcon = "activities-module--activity-icon--Xr41f";
export var activityIconText = "activities-module--activity-icon-text--35olx";
import { TextField, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React, { FC } from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import {
  ActivityData,
  CreateActivityData,
} from "../../../../redux/actions/activityCRUDActions";
import muiStylesPickers from "./muiStylesPickers";

const useStyles = makeStyles(muiStylesPickers);

interface Props {
  control: Control<ActivityData | CreateActivityData>;
  errors: DeepMap<ActivityData | CreateActivityData, FieldError>;
}

const Pickers: FC<Props> = ({ control, errors }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Controller
        name="startDate"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <KeyboardDatePicker
            allowKeyboardControl
            autoOk
            className={classes.inputDate}
            disablePast
            disableToolbar
            format="MMM DD, YYYY"
            inputVariant="filled"
            mask="__/__/____"
            variant="inline"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            {...rest}
          />
        )}
      />

      <Controller
        name="startTime"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            className={classes.inputTime}
            error={!!errors.startTime}
            helperText={errors?.startTime?.message}
            type="time"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            {...rest}
          />
        )}
      />

      <p>to</p>

      <Controller
        name="endTime"
        control={control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            className={classes.inputTime}
            error={!!errors.endTime}
            helperText={errors?.endTime?.message}
            type="time"
            variant="filled"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 300, // 5 min
            }}
            {...rest}
          />
        )}
      />
    </div>
  );
};

export default Pickers;

import { DialogContent, makeStyles, Divider, Hidden } from "@material-ui/core";
import React, { FC, useEffect, useRef, useState } from "react";
import { muiStyles } from "../muiStyles";
import CustomIconButton from "../../../../Common/Buttons/IconButton";
import MediaSvg from "../../../../../images/pulse/media.svg";
import EmojiSvg from "../../../../../images/pulse/emoji.svg";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { UploadFileCard } from "../../UploadFileCard";
import { useSelector } from "react-redux";
import { selectAuthUser } from "../../../../../redux/selectors/authSelectors";
import smiley from "../../../../../images/grinning-face_1f600.png";
import Picker, { IEmojiData } from "emoji-picker-react";
import emojiData from "../../../../../utils/emoji/data.json";
import * as styles from "./content.module.scss";
import ReactQuill from "react-quill";
import emojiUnicode from "emoji-unicode";
import emojiRegex from "emoji-regex";
import * as Quill from "quill";
import "quill-paste-smart";

const useStyles = makeStyles(muiStyles);

export interface PostForm {
  body: string;
  medium?: FileList | null;
}

interface Props {
  register: UseFormRegister<PostForm>;
  setValue: UseFormSetValue<PostForm>;
  body: string;
  mediumName?: string;
}

export const Content: FC<Props> = ({
  register,
  setValue,
  body,
  mediumName,
}) => {
  const classes = useStyles();
  const [showPicker, setShowPicker] = useState(false);
  const medium = register("medium", { required: false }); // explicit register of the form field to control onChange
  const userData = useSelector(selectAuthUser);
  const quillRef = useRef<ReactQuill>(null);
  const lastKnownCursorPosition = useRef(0);

  // autofocus on text editor
  useEffect(() => {
    quillRef.current?.focus();
  }, []);

  // store last position of the cursor in the editor, to insert emoji
  useEffect(() => {
    if (!quillRef.current) {
      return;
    }
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();

    if (selection?.index || selection?.index === 0) {
      lastKnownCursorPosition.current = selection.index;
    }
  });

  const insertEmoji = (emoji: IEmojiData) => {
    if (!quillRef.current) {
      return;
    }
    const editor = quillRef.current.getEditor();
    const cursorPosition =
      editor.getSelection()?.index || lastKnownCursorPosition.current;

    editor.insertText(cursorPosition, emoji.emoji, "silent"); // source: make the React Quill think the emoji is inserted by "silent" instead of "api" for emoji formatting
    editor.setSelection(
      {
        index: cursorPosition + 1,
        length: 0,
      },
      "silent"
    );
  };

  // replace emojis with img tags for each apple defined emoji
  const onQuillChange = (
    value: string,
    delta: Quill.Delta,
    source: Quill.Sources
  ) => {
    if (source !== "api") {
      // update the body, if the user was the reason for change
      setValue(
        "body",
        value.replaceAll(emojiRegex(), (match) => {
          const emojiCode = emojiUnicode(match).replaceAll(" ", "-");

          // find the emoji's URL
          const emojiURL = Object.values(emojiData).find((v) => {
            return v.search(new RegExp(`[^-]${emojiCode}[._]`)) !== -1;
          });

          return emojiURL ? `<img alt="emoji" src="${emojiURL}" >` : match;
        })
      );
    } else {
      // don't update the body in other cases to prevent an infinite loop
      setValue("body", value);
    }
  };

  return (
    <DialogContent className={classes.dialogContent}>
      <form id="post-modal-form" className={styles.form}>
        <div className={styles.textFieldContainer} data-text-editor="body">
          <ReactQuill
            // react-quill styles are located in `src/components/Pulse/Posts/quill.custom-snow.scss,
            // are imported in PostsList component and are global for the application
            ref={quillRef}
            className={styles.reachTextEditor}
            theme="snow"
            value={body}
            placeholder={`Go for it, ${userData ? userData.firstName : ""}`}
            onChange={onQuillChange}
            onBlur={(previousRange) => {
              // update lastKnownCursorPosition, when the Editor looses focus
              lastKnownCursorPosition.current = previousRange.index;
            }}
            bounds={`[data-text-editor="body"]`} // make sure the tooltips are displayed correctly
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ color: [] }, { background: [] }],
                [{ script: "sub" }, { script: "super" }],
                ["blockquote", "code-block"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
                ["link"],
                ["clean"],
              ],
            }}
            formats={[
              "background",
              "bold",
              "color",
              "font",
              "code",
              "italic",
              "link",
              "size",
              "strike",
              "script",
              "underline",
              "blockquote",
              "header",
              "indent",
              "list",
              "align",
              "direction",
              "code-block",
              "formula",
              "image",
            ]} // enable all formats except for 'video'
          />
        </div>
        <div className={styles.uploadFileCardContainer}>
          {mediumName && (
            <UploadFileCard
              filename={mediumName}
              onDelete={() => {
                setValue("medium", undefined);
              }}
            />
          )}
        </div>
        <input
          id="postModal-form-medium-upload"
          name={medium.name}
          ref={medium.ref}
          onChange={(e) => {
            // make sure pressing Cancel in file selection window, after initial selection won't remove currently selected file
            e.target.value && medium.onChange(e);
          }}
          onBlur={medium.onBlur}
          type="file"
          accept="application/pdf,image/*,video/*"
          className={styles.fileInput}
        />
        <div>
          <Hidden smUp>
            <Divider className={classes.divider} />
          </Hidden>
          <div className={styles.buttons}>
            <label htmlFor="postModal-form-medium-upload">
              <CustomIconButton
                icon={<MediaSvg />}
                text={"Add Photo/Video/File"}
                component="span"
              />
            </label>
            {showPicker && (
              <Picker
                pickerStyle={{
                  zIndex: "100",
                  position: "absolute",
                  bottom: "110px",
                  width: "277px",
                  height: "275px",
                }}
                onEmojiClick={(e, emoji) => {
                  insertEmoji(emoji);
                  setShowPicker((value) => !value);
                }}
              />
            )}
            <Hidden xsDown>
              <CustomIconButton
                icon={<EmojiSvg />}
                text={"Add Emoji"}
                event={() => setShowPicker((value) => !value)}
              />
            </Hidden>
          </div>
          <div className={styles.videoHelpText}>
            <span>
              Using iPhone? For a to-the-moon-experience, please share
              landscape-format videos
            </span>
            <img alt="" src={smiley} />
          </div>
        </div>
      </form>
    </DialogContent>
  );
};

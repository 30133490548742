import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { FC } from "react";
import muiStylesUserTopBar from "./muiStylesUserTopBar";
import BackIcon from "../../../../icons/navigation/icon-back.svg";
import CalendarIcon from "../../../../icons/icon-calendar.svg";
import { Link, navigate } from "@reach/router";
import { User } from "../../../../api/usersAPI";
import { profilePath } from "../../../../utils/paths";

const useStyles = makeStyles(muiStylesUserTopBar);

interface Props {
  calendarProfile: boolean;
  user: User;
}

const UserProfileTopBar: FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="close"
          onClick={() => navigate(-1)}
        >
          <img src={BackIcon} alt="icon-back" />
        </IconButton>
        <Typography>{`${props.user.firstName} ${props.user.lastName}`}</Typography>

        {props.calendarProfile ? (
          <div />
        ) : (
          <Link to={`${profilePath}/${props.user.id}/calendar`}>
            <IconButton edge="end" color="inherit" aria-label="close">
              <img src={CalendarIcon} alt="icon-calendar" />
            </IconButton>
          </Link>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default UserProfileTopBar;

import {isMobileSafari, isSafari} from "react-device-detect";

export const SUPPORTED_IMG_SUFFIXES = [".jpg", ".jpeg", ".svg", ".png", ".gif", ".webp"];
export const SUPPORTED_VIDEO_SUFFIXES = [".mp4", ".m4v", ".mov"];

export const extractFilenameFromURL = (
  fileURL?: string
): string | undefined => {
  if (fileURL) {
    // remove everything before the last '/' and everything after the first '?'
    return fileURL.split("/").reverse()[0].split("?")[0];
  }
};

/*
* A function checking whether a certain video can be played in the current browser.
* If no codec is provided, the function returns true.
*
* Currently implemented checks:
* - H.265/HEVC which is only supported by Safari and Safari on IOS
*/
export const isCodecSupportedByBrowser = (codec: string | null | undefined): boolean => {
  const hevcCodecNames = ["hevc", "h.265", "mpeg-h part 2"]

  if (codec) {
    if (hevcCodecNames.includes(codec.toLowerCase())) {
      return isSafari || isMobileSafari;
    }
  }

  return true;
}

// extracted by mini-css-extract-plugin
export var h1Typography = "employeeList-module--h1-typography--EyvpN";
export var h2Typography = "employeeList-module--h2-typography--1MPq3";
export var h3Typography = "employeeList-module--h3-typography--2jLz3";
export var h4Typography = "employeeList-module--h4-typography--LKgIN";
export var h5Typography = "employeeList-module--h5-typography--i-ojY";
export var h6Typography = "employeeList-module--h6-typography--1xIX1";
export var pTypography = "employeeList-module--p-typography--2A6cl";
export var info = "employeeList-module--info--2PvKI";
export var buttonTypography = "employeeList-module--button-typography--1C260";
export var formLabelTypography = "employeeList-module--form-label-typography--2jxP5";
export var menuTabTypography = "employeeList-module--menu-tab-typography--1lrQr";
export var linkStyle = "employeeList-module--link-style--3zS-n";
export var postTypography = "employeeList-module--post-typography--3etpZ";
export var userListContainer = "employeeList-module--user-list-container--1-Y17";
export var paginationContainer = "employeeList-module--paginationContainer--3Xupn";
export var paginationInfo = "employeeList-module--pagination-info--3ezNW";
export var navBar = "employeeList-module--nav-bar--3wPgY";
export var navButtonLast = "employeeList-module--nav-button-last--jK70Y";
export var navButton = "employeeList-module--nav-button--25Fcn";
export var uncheck = "employeeList-module--uncheck--2eYQ1";
export var uncheckHidden = "employeeList-module--uncheck-hidden--ln1XK";
export var buttonContainer = "employeeList-module--button-container--21GAK";
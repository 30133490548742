import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import muiStylesUserAvatar from "./muiStylesUserAvatar";
import { useIsSmall } from "../../../utils";

const useStyles = makeStyles(muiStylesUserAvatar);

interface UserAvatarProps {
  firstName?: string;
  lastName?: string;
  checked?: boolean;
  large?: boolean;
}

const UserAvatar: FC<UserAvatarProps> = ({
  firstName,
  lastName,
  checked,
  large,
}) => {
  const classes = useStyles();
  const isSmall = useIsSmall();

  return (
    <div className={classes.root}>
      {checked && isSmall ? (
        <Avatar className={classes.colorChecked}>✔</Avatar>
      ) : (
        <Avatar className={large ? classes.large : classes.color}>
          {firstName != null ? firstName[0] : ""}
          {lastName != null ? lastName[0] : ""}
        </Avatar>
      )}
    </div>
  );
};

export default UserAvatar;

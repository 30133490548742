import React, { FC } from "react";
import { isLoggedIn, loginPath, loginRedirectTo } from "../../utils/auth";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import { selectAuthUser } from "../../redux/selectors/authSelectors";
import { useSelector } from "react-redux";

interface PrivateRouteProps extends RouteComponentProps {
  component: FC;
  companyAdminRestricted?: boolean;
  employeeRestricted?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  companyAdminRestricted,
  employeeRestricted,
  location,
  ...rest
}) => {
  const userData = useSelector(selectAuthUser);

  if (!isLoggedIn() && location?.pathname !== loginPath) {
    void navigate(loginPath);

    return null;
  }

  if (companyAdminRestricted && !userData?.isCompanyAdmin) {
    void navigate(loginRedirectTo);

    return null;
  }

  if (employeeRestricted && userData?.isCompanyAdmin) {
    void navigate(loginRedirectTo);

    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;

import { createStyles } from "@material-ui/core/styles";
import {
  backgroundBadgeColor,
  fontColorPrimary,
  fontColorTertiary,
  primaryColor,
  secondaryColor,
  secondaryColorHover,
} from "../../../styles/mui/theme";
import { Theme } from "@material-ui/core";

const muiStylesUserProfile = (theme: Theme) =>
  createStyles({
    userAvatar: {
      "width": "100px",
      "height": "100px",
      "color": primaryColor,
      "backgroundColor": backgroundBadgeColor,
      "font-size": "32px",
    },
    buttonActivity: {
      "width": "fit-content",
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
      "& + &": {
        marginLeft: "auto",
        padding: "6px 40px",
      },
    },
    text: {
      "color": fontColorTertiary,
      "text-transform": "capitalize",
      "font-size": "0.8em",
    },
    textName: {
      "font-weight": "900",
    },
    textData: {
      color: fontColorPrimary,
    },
  });

export default muiStylesUserProfile;

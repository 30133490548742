import * as styles from "../form.module.scss";
import {
  CreateActivityData,
  createActivity,
  updateActivity,
} from "../../../../redux/actions/activityCRUDActions";
import {
  Button,
  Divider,
  FormControl,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActivityIcon from "../../../../icons/activity-form/icon-activity.svg";
import ActivitySchema from "../schema";
import ActivityTopBar from "../../ActivityTopBar";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import ClosedActivity from "../../../../icons/activity-form/icon-event-closed.svg";
import DescriptionIcon from "../../../../icons/activity-form/icon-description.svg";
import InviteIcon from "../../../../icons/activity-form/icon-invite.svg";
import LocationIcon from "../../../../icons/activity-form/icon-location.svg";
import MomentUtils from "@date-io/moment";
import OpenActivity from "../../../../icons/activity-form/icon-event-opened.svg";
import TimeIcon from "../../../../icons/activity-form/icon-time.svg";
import { fetchUsers } from "../../../../redux/actions/userActions";
import { makeStyles } from "@material-ui/core/styles";
import muiStylesForm from "../muiStylesForm";
import { selectUsers } from "../../../../redux/selectors/usersSelectors";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Activity } from "../../ActivityPreview";
import Pickers from "../Pickers";
import { changeAttendees } from "../../../../redux/actions/activityFormActions";
import { selectAttendees } from "../../../../redux/selectors/activityFormSelectors";
import { fetchActivityIcon, useIsSmall } from "../../../../utils";
import { selectActivityTypes } from "../../../../redux/selectors/activityTypesSelectors";
import { fetchActivityTypes } from "../../../../redux/actions/activityTypesActions";
import UserAvatar from "../../../Common/UserAvatar";
import { selectAuthUser } from "../../../../redux/selectors/authSelectors";
import UserBox from "../../User";
import { getUserIds } from "../../../../utils";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { muiStylesButtons } from "../../../CompanySettings/muiStylesCompanySettings";
import { StyledCheckbox } from "../../../Common/Checkbox";

const useStyles = makeStyles(muiStylesForm);
const useButtonStyles = makeStyles(muiStylesButtons);

interface Props {
  activity?: Activity;
  handleClose: () => void;
  editMode?: boolean;
  open: boolean;
}

const AddActivityForm: FC<Props> = (props) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();
  const userData = useSelector(selectUsers);
  const authUser = useSelector(selectAuthUser);
  const selectedUserIds = useSelector(selectAttendees);
  const activityTypes = useSelector(selectActivityTypes);

  const [activityTypeId, setActivityTypeId] = useState(
    props.activity?.activityType.id || 1
  );
  const [closedEvent, setClosedEvent] = useState(
    props.activity?.closed || false
  );
  const [location, setLocation] = useState(props.activity?.location || "");

  const isSmall = useIsSmall();

  const getInitialInvitedUsers = () => {
    if (selectedUserIds) {
      return selectedUserIds
        .map((userId) =>
          userData.find(
            (user) =>
              user.id === userId && user.isActive && user.id !== authUser?.id
          )
        )
        .filter(Boolean)
        .map(
          (user) => `${user ? user.firstName : ""} ${user ? user.lastName : ""}`
        );
    }

    return [];
  };

  const [selectedUsers, setSelectedUsers] = React.useState<string[]>(
    getInitialInvitedUsers()
  );

  useEffect(() => {
    dispatch(fetchUsers({}));
    dispatch(fetchActivityTypes());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateActivityData>({
    defaultValues: {
      title: "",
      activityTypeId: 1,
      closed: false,
      startDate: new Date().toISOString(),
      startTime: new Date().toString().slice(16, 21),
      endTime: new Date().toString().slice(16, 21),
      showToAll: false,
    },
    mode: "onTouched",
    resolver: yupResolver(ActivitySchema),
  });

  const showToAllValue = watch("showToAll");
  const closedValue = watch("closed");

  const showToAllDisabled =
    (selectedUsers && selectedUsers.length > 0) || closedValue;

  const updateClosedEventValue = (val: boolean) => {
    val && setValue("showToAll", false);
    setClosedEvent(val);
  };

  const onUsersChange = (val: string[]) => {
    if (val && val.length > 0 && showToAllValue) {
      setValue("showToAll", false);
    }

    setSelectedUsers(val);
    const ids = getUserIds(val, userData);

    dispatch(changeAttendees(ids));
  };

  const handleActivityChange = (event: ChangeEvent<{ value: unknown }>) => {
    setActivityTypeId(event.target.value as number);
  };

  const onSubmit = handleSubmit((data) => {
    data = {
      ...data,
      ...(props.activity ? { id: props.activity.id } : {}),
      ...{ attendeesIds: selectedUserIds },
    };

    props.editMode
      ? dispatch(updateActivity(data))
      : dispatch(createActivity(data));

    // remove selected attendees from redux to prevent preserving attendees between activities creations
    !props.editMode && dispatch(changeAttendees([]));

    props.handleClose();
  });

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={styles.formContainer}>
        {isSmall ? (
          <ActivityTopBar
            handleClose={props.handleClose}
            organizer
            editForm={props.editMode}
          />
        ) : (
          <div className={styles.closeContainer}>
            <IconButton
              aria-label="close"
              className={classes.iconClose}
              color="inherit"
              edge="end"
              onClick={props.handleClose}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        )}

        <form id={"activity-form"} onSubmit={onSubmit} className={styles.form}>
          <Controller
            name="title"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                type="text"
                placeholder="Add title"
                error={!!errors.title}
                helperText={errors?.title?.message}
                className={classes.inputTitle}
                InputProps={{
                  className: classes.titleSize,
                }}
                {...rest}
              />
            )}
          />

          <div className={styles.formTime}>
            <img className={styles.icon} src={TimeIcon} alt="icon-time" />

            <Pickers control={control} errors={errors} />
          </div>

          <Divider />
          <div className={styles.formActivity}>
            <img
              className={styles.icon}
              src={ActivityIcon}
              alt="icon-activity"
            />
            <FormControl variant="filled" className={classes.select}>
              <Select
                {...register("activityTypeId")}
                value={activityTypeId}
                margin="dense"
                onChange={handleActivityChange}
                MenuProps={{
                  getContentAnchorEl: null,
                }}
              >
                {activityTypes.map(({ id, name }) => {
                  return (
                    <MenuItem key={id} value={id}>
                      {name !== "Custom" ? (
                        <ListItemIcon className={classes.icon}>
                          <img
                            src={fetchActivityIcon(name, "blue")}
                            alt="activity-type-icon"
                          />
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className={classes.icon}>
                          <HelpOutlineIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                      )}
                      <ListItemText primary={name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <p>{errors.activityTypeId?.message}</p>
          </div>

          <div className={styles.formActivity}>
            <img className={styles.icon} src={InviteIcon} alt="icon-invite" />
            <Autocomplete
              value={selectedUsers}
              onChange={(event: any, newValue: string[]) => {
                onUsersChange(newValue);
              }}
              autoSelect
              multiple
              freeSolo
              className={classes.input}
              options={userData
                .filter((user) => user.isActive && user.id !== authUser?.id)
                .map((user) => `${user.firstName} ${user.lastName}`)}
              renderOption={(option) => (
                <>
                  <UserAvatar
                    firstName={option.split(" ", 1)[0]}
                    lastName={option.split(" ", 2)[1]}
                  />
                  <p>{option}</p>
                </>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="Add invites"
                />
              )}
            />
          </div>

          {(authUser?.isSuperuser || authUser?.isCompanyAdmin) && (
            <div className={styles.formActivityShowToAll}>
              <Controller
                name="showToAll"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <StyledCheckbox
                    {...rest}
                    uiScale={1}
                    disabled={showToAllDisabled}
                  />
                )}
              />

              <p
                className={
                  showToAllDisabled
                    ? styles.formActivityShowToAllDisabled
                    : undefined
                }
              >
                Show To All
              </p>
            </div>
          )}

          <div className={styles.formActivityAttendees}>
            <div className={styles.ownerContainer}>
              {authUser && (
                <UserBox
                  user={{ ...authUser, status: "ACCEPTED" }}
                  isOrganizer
                />
              )}
            </div>
          </div>

          <div className={styles.formActivityAttendees}>
            {getUserIds(selectedUsers, userData).map((userId) => {
              const selectedUser = userData.find((user) => user.id === userId);

              if (!selectedUser) {
                return <div />;
              }

              return (
                <div key={userId} className={styles.attendeeContainer}>
                  <UserBox
                    user={{ ...selectedUser, status: null }}
                    isOrganizer={false}
                  />
                  <IconButton
                    aria-label="close"
                    className={classes.removeAttendeeButton}
                    color="inherit"
                    edge="end"
                    onClick={() => {
                      onUsersChange(
                        selectedUsers.filter(
                          (fullName) =>
                            fullName !==
                            `${selectedUser.firstName} ${selectedUser.lastName}`
                        )
                      );
                    }}
                  >
                    <CloseIcon className={classes.removeAttendeeIcon} />
                  </IconButton>
                </div>
              );
            })}
          </div>

          <div className={styles.formLocation}>
            <img
              className={styles.icon}
              src={LocationIcon}
              alt="icon-location"
            />
            <TextField
              {...register("location")}
              className={classes.input}
              onChange={(event) => setLocation(event.target.value)}
              type="text"
              placeholder="Add Location"
              value={location}
              variant="filled"
            />
          </div>
          <div className={styles.formDescription}>
            <img
              className={styles.icon}
              src={DescriptionIcon}
              alt="icon-description"
            />
            <Controller
              name="description"
              control={control}
              render={({ field: { ref, ...rest } }) => (
                <TextField
                  className={classes.input}
                  multiline
                  placeholder="Add Description"
                  rows={4}
                  rowsMax={4}
                  variant="filled"
                  {...rest}
                />
              )}
            />
          </div>

          <div className={styles.formClosed}>
            {!closedEvent ? (
              <img
                alt="icon-event-opened"
                className={styles.iconClose}
                src={OpenActivity}
              />
            ) : (
              <img
                alt="icon-event-closed"
                className={styles.iconClose}
                src={ClosedActivity}
              />
            )}

            <Controller
              name="closed"
              control={control}
              render={({ field }) => (
                <Switch
                  className={classes.switch}
                  color="primary"
                  onChange={(value) => {
                    field.onChange(value.target.checked);
                    updateClosedEventValue(value.target.checked);
                  }}
                  checked={field.value}
                />
              )}
            />
            <p>
              This activity is {closedEvent ? "closed" : "open"} for others to
              join
            </p>
          </div>

          {isSmall ? null : (
            <>
              <Divider />
              <div className={styles.formButtons}>
                <Button
                  onClick={props.handleClose}
                  className={classes.btnCancel}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button className={buttonClasses.primaryButton} type="submit">
                  Save
                </Button>
              </div>
            </>
          )}
        </form>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default AddActivityForm;

// extracted by mini-css-extract-plugin
export var h1Typography = "emptystatePosts-module--h1-typography--3UhDE";
export var h2Typography = "emptystatePosts-module--h2-typography--UWMUk";
export var h3Typography = "emptystatePosts-module--h3-typography--AX4bG";
export var h4Typography = "emptystatePosts-module--h4-typography--3zpLr";
export var h5Typography = "emptystatePosts-module--h5-typography--2t9tO";
export var h6Typography = "emptystatePosts-module--h6-typography--2C2fX";
export var pTypography = "emptystatePosts-module--p-typography--d2cQq";
export var info = "emptystatePosts-module--info--SYvnZ";
export var buttonTypography = "emptystatePosts-module--button-typography--1EH8N";
export var formLabelTypography = "emptystatePosts-module--form-label-typography--1sALD";
export var menuTabTypography = "emptystatePosts-module--menu-tab-typography--2BYgE";
export var linkStyle = "emptystatePosts-module--link-style--2TgbW";
export var postTypography = "emptystatePosts-module--post-typography--TVtro";
export var container = "emptystatePosts-module--container--2wTiD";
export var text = "emptystatePosts-module--text--21CCm";
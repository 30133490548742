import Day, { DayProps } from "../day";
import React, { FC } from "react";
import * as styles from "./month.module.scss";

export interface MonthProps {
  monthNumber: number;
  monthName: string;
  year: number;
  days: DayProps[];
}

const Month: FC<MonthProps> = (props) => {
  return (
    <>
      <div
        className={styles.monthHeader}
        data-name={`${props.monthName} ${props.year}`}
      >
        {props.monthName}
      </div>
      <div
        className={styles.month}
        data-name={`${props.monthName} ${props.year}`}
      >
        {props.days.map((day) => {
          return (
            <Day
              key={`day_${props.year}_${props.monthNumber}_${day.dayNumber}`}
              {...day}
            />
          );
        })}
      </div>
    </>
  );
};

export default Month;

import * as styles from "./SideBar.module.scss";
import React, { FC } from "react";
import { useLocation } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import MotkoLogo from "../../../images/motko-logo.svg";
import { Link } from "gatsby";
import { selectAuthUser } from "../../../redux/selectors/authSelectors";
import muiStyles from "./muiStylesSideBar";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AddActivityForm from "../../Activity/ActivityForms/AddActivityForm";
import {
  Avatar,
  Button,
  Collapse,
  Dialog,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  makeStyles,
} from "@material-ui/core";
import CalendarStatic from "../../../icons/sidebar/static/calendar.svg";
import CalendarActive from "../../../icons/sidebar/active/calendar.svg";
import NetworkStatic from "../../../icons/sidebar/static/network.svg";
import NetworkActive from "../../../icons/sidebar/active/network.svg";
import SettingsStatic from "../../../icons/sidebar/static/settings.svg";
import SettingsActive from "../../../icons/sidebar/active/settings.svg";
import PulseIcon from "../../../images/svg/pulse.svg";
import {
  accountSettingsPath,
  allEmployeesPath,
  calendarPath,
  companySettingsPath,
  networkStructurePath,
  organizationStructurePath,
  profilePath,
  pulsePath,
} from "../../../utils/paths";
import { logout } from "../../../redux/actions/authActions";
import { menuSelectedHighlight } from "../../../styles/mui/theme";

const useStyles = makeStyles(muiStyles);

interface Props {
  handleDrawerToggle: () => void;
  open: boolean;
  color: string;
}

const SideBar: FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userData = useSelector(selectAuthUser);

  const [openForm, setOpenForm] = React.useState(false);

  const handleClickOpenForm = () => {
    setOpenForm(true);
  };

  const handleClickCloseForm = () => {
    setOpenForm(false);
  };

  const getInitialDropdownState = (): boolean =>
    pathname === allEmployeesPath || pathname === organizationStructurePath;

  const [collapseOpen, setCollapseOpen] = React.useState(
    getInitialDropdownState()
  );

  const handleClick = () => {
    setCollapseOpen(!collapseOpen);
  };

  const menuItemHighLight = (localization: string) => {
    return pathname === localization
      ? { backgroundColor: menuSelectedHighlight }
      : undefined;
  };

  const drawerItems = (
    <div>
      <div className={styles.logoSection}>
        <div className={styles.logo}>
          <MotkoLogo />
        </div>
        <Divider />
      </div>
      {props.open ? (
        <div className={styles.user}>
          <Avatar className={classes["user-photo"]}>
            {userData
              ? `${userData?.firstName[0] ?? ""}${userData?.lastName[0] ?? ""}`
              : ""}
          </Avatar>
          <div className={styles.userInfo}>
            <p className={styles.userName}>
              {`${userData?.firstName ?? ""} ${userData?.lastName ?? ""}`}
            </p>
            <p className={styles.userJob}>{userData?.role ?? ""}</p>
          </div>
          <Divider />
        </div>
      ) : null}
      <MenuList aria-label="menu-buttons">
        {props.open ? (
          <Divider />
        ) : (
          <MenuItem
            disableRipple
            alignItems="center"
            className={classes["menu-item"]}
          >
            {/*
            the non-breaking space symbol fixes the "n" input in add activities. See more:
            https://stackoverflow.com/questions/64646173/react-js-input-field-losing-focus-when-typing-letters-c-or-n
            */}
            &#8288;
            <Button
              variant="contained"
              className={classes["btn-new-activity"]}
              onClick={handleClickOpenForm}
            >
              New Activity
            </Button>
            <Dialog fullScreen open={openForm} onClose={handleClickCloseForm}>
              <AddActivityForm
                open={openForm}
                handleClose={handleClickCloseForm}
              />
            </Dialog>
          </MenuItem>
        )}
        <Link to={pulsePath} className={styles.linkStyle}>
          <MenuItem
            selected={pathname === pulsePath}
            style={menuItemHighLight(pulsePath)}
            classes={{ selected: classes.menuHighlight }}
            className={classes.menuText}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PulseIcon
                className={
                  menuItemHighLight(pulsePath) ? styles.iconActive : styles.icon
                }
              />
            </ListItemIcon>
            <ListItemText primary="Pulse" />
          </MenuItem>
        </Link>
        <Link to={calendarPath} className={styles.linkStyle}>
          <MenuItem
            selected={pathname === calendarPath}
            style={menuItemHighLight(calendarPath)}
            classes={{ selected: classes.menuHighlight }}
            className={classes.menuText}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <img
                alt="calendar"
                src={
                  menuItemHighLight(calendarPath)
                    ? CalendarActive
                    : CalendarStatic
                }
              />
            </ListItemIcon>
            <ListItemText primary={"Calendar"} />
          </MenuItem>
        </Link>

        {userData?.isCompanyAdmin ? (
          <div>
            <ListItem button onClick={handleClick} className={styles.linkStyle}>
              <ListItemIcon>
                <img
                  alt="network"
                  src={
                    menuItemHighLight(allEmployeesPath) ||
                    menuItemHighLight(organizationStructurePath)
                      ? NetworkActive
                      : NetworkStatic
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary="Network"
                className={classes.dropDownText}
              />
              {collapseOpen ? (
                <ExpandLess className={classes.menuText} />
              ) : (
                <ExpandMore className={classes.menuText} />
              )}
            </ListItem>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to={allEmployeesPath} className={styles.linkStyle}>
                  <MenuItem
                    selected={pathname === allEmployeesPath}
                    className={classes.nested}
                    style={menuItemHighLight(allEmployeesPath)}
                    classes={{ selected: classes.menuHighlight }}
                  >
                    <ListItemText primary="Community" />
                  </MenuItem>
                </Link>

                <Link
                  to={organizationStructurePath}
                  className={styles.linkStyle}
                >
                  <MenuItem
                    selected={pathname === organizationStructurePath}
                    className={classes.nested}
                    style={menuItemHighLight(organizationStructurePath)}
                    classes={{ selected: classes.menuHighlight }}
                  >
                    <ListItemText primary="Tenancy Schedule" />
                  </MenuItem>
                </Link>
              </List>
            </Collapse>
          </div>
        ) : null}

        {!userData?.isCompanyAdmin ? (
          <Link to={networkStructurePath} className={styles.linkStyle}>
            <MenuItem
              button
              selected={pathname === networkStructurePath}
              style={menuItemHighLight(networkStructurePath)}
              classes={{ selected: classes.menuHighlight }}
              className={classes.menuText}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <img
                  alt="network"
                  src={
                    menuItemHighLight(networkStructurePath)
                      ? NetworkActive
                      : NetworkStatic
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Network" />
            </MenuItem>
          </Link>
        ) : null}

        {userData?.isCompanyAdmin ? (
          <Link to={companySettingsPath} className={styles.linkStyle}>
            <MenuItem
              button
              selected={pathname === companySettingsPath}
              style={menuItemHighLight(companySettingsPath)}
              classes={{ selected: classes.menuHighlight }}
              className={classes.menuText}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <img
                  alt="settings"
                  src={
                    menuItemHighLight(companySettingsPath)
                      ? SettingsActive
                      : SettingsStatic
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Building Settings" />
            </MenuItem>
          </Link>
        ) : null}

        {props.open ? (
          <div>
            <Divider />
            <Link
              to={`${profilePath}/${userData ? userData.id : ""}`}
              className={styles.linkStyle}
              onClick={props.handleDrawerToggle}
            >
              <MenuItem
                button
                selected={
                  pathname === `${profilePath}/${userData ? userData.id : ""}`
                }
                style={menuItemHighLight(
                  `${profilePath}/${userData ? userData.id : ""}`
                )}
                classes={{ selected: classes.menuHighlight }}
                className={classes.menuText}
              >
                <ListItemText primary="Profile" />
              </MenuItem>
            </Link>

            <Link to={accountSettingsPath} className={styles.linkStyle}>
              <MenuItem
                button
                selected={pathname === accountSettingsPath}
                style={menuItemHighLight(accountSettingsPath)}
                classes={{ selected: classes.menuHighlight }}
                className={classes.menuText}
              >
                <ListItemText primary="Account Settings" />
              </MenuItem>
            </Link>

            <MenuItem
              className={classes.menuText}
              button
              onClick={() => dispatch(logout())}
            >
              <ListItemText primary="Log out" />
            </MenuItem>
          </div>
        ) : null}
      </MenuList>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="sidebar-menu">
      <Hidden lgUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          className={classes.drawer}
          classes={{
            paper: classes["drawer-paper"],
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          open={props.open}
        >
          {drawerItems}
        </Drawer>
      </Hidden>
      <Hidden mdDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          className={classes.drawer}
          classes={{
            paper: classes["drawer-paper"],
          }}
        >
          {drawerItems}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default SideBar;

// extracted by mini-css-extract-plugin
export var h1Typography = "activities-module--h1-typography--2Hy4U";
export var h2Typography = "activities-module--h2-typography--UKKVI";
export var h3Typography = "activities-module--h3-typography--2dXnf";
export var h4Typography = "activities-module--h4-typography--2XZTO";
export var h5Typography = "activities-module--h5-typography--2i8Hi";
export var h6Typography = "activities-module--h6-typography--245bh";
export var pTypography = "activities-module--p-typography--2M54f";
export var info = "activities-module--info--1fOYT";
export var buttonTypography = "activities-module--button-typography--3NRe_";
export var formLabelTypography = "activities-module--form-label-typography--1VyPn";
export var menuTabTypography = "activities-module--menu-tab-typography--1_W3B";
export var linkStyle = "activities-module--link-style--3E9Q0";
export var postTypography = "activities-module--post-typography--20Acp";
export var activitiesContainer = "activities-module--activities-container--1jxOe";
export var activityContainer = "activities-module--activity-container--294vN";
export var activityIcon = "activities-module--activity-icon--3O5Hs";
export var activityIconText = "activities-module--activity-icon-text--q1Kf5";
// extracted by mini-css-extract-plugin
export var h1Typography = "sportBadge-module--h1-typography--zsnZL";
export var h2Typography = "sportBadge-module--h2-typography--19xhm";
export var h3Typography = "sportBadge-module--h3-typography--YSXzO";
export var h4Typography = "sportBadge-module--h4-typography--2bH3S";
export var h5Typography = "sportBadge-module--h5-typography--38Ksw";
export var h6Typography = "sportBadge-module--h6-typography--3K0iS";
export var pTypography = "sportBadge-module--p-typography--1hRky";
export var info = "sportBadge-module--info--3RrZJ";
export var buttonTypography = "sportBadge-module--button-typography--3-CHc";
export var formLabelTypography = "sportBadge-module--form-label-typography--rnW6w";
export var menuTabTypography = "sportBadge-module--menu-tab-typography--33vM1";
export var linkStyle = "sportBadge-module--link-style--79f2Q";
export var postTypography = "sportBadge-module--post-typography--hVvMh";
export var badge = "sportBadge-module--badge--1jBhU";
export var sport = "sportBadge-module--sport--umxXN";
import * as styles from "./searchResults.module.scss";
import { Typography, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import UserResult from "./UserResult";
import { User } from "../../../api/usersAPI";
import { Link } from "@reach/router";
import muiStylesMobileSearchResults from "./muiUserResults";
import { profilePath } from "../../../utils/paths";

const useStyles = makeStyles(muiStylesMobileSearchResults);

interface Props {
  results: User[];
  searchValue: string;
  handleClose?: () => void;
}

const SearchResults: FC<Props> = ({ results, searchValue, handleClose }) => {
  const classes = useStyles(useStyles);

  return (
    <div className={styles.container}>
      <Typography className={classes.text} variant="h3">
        {results.length
          ? "Search Results"
          : searchValue.length
            ? `No search results for "${searchValue}"`
            : "Search Results"}
      </Typography>
      <div>
        {results.map((result) => (
          <Link
            to={`${profilePath}/${result.id}`}
            key={result.id}
            className={styles.linkStyle}
            onClick={handleClose}
          >
            <UserResult key={result.id} user={result} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SearchResults;

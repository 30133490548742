import React, { FC } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { ButtonProps } from "../types";
import muiStylesButtons from "../muiStylesButtons";

const useStyles = makeStyles(muiStylesButtons);

interface Props extends ButtonProps {
  icon: JSX.Element;
  component?: React.ElementType;
}

const IconButton: FC<Props> = ({ text, disabled, event, icon, component }) => {
  const classes = useStyles();

  const element = (
    <Button
      className={classes.iconButton}
      disabled={!!disabled}
      onClick={event}
      startIcon={icon}
    >
      {text}
    </Button>
  );

  return component ? React.cloneElement(element, { component }) : element;
};

export default IconButton;

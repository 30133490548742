import { RootState } from "../../store";
import { PostData } from "../../../api/pulseAPI";

export const selectPostCUDLoading = (state: RootState) =>
  state.pulsePosts.CUD.loading;

export const selectPostById = (postId?: number) => (store: {
  pulsePosts: { posts: PostData[] };
}) => {
  return store.pulsePosts.posts.find(({ id }) => id === postId);
};

export const selectPosts = (state: RootState) => state.pulsePosts.posts;

export const selectPostsFetchArgs = (state: RootState) =>
  state.pulsePosts.nextParams;

export const selectPostsFetchLoading = (state: RootState) =>
  state.pulsePosts.fetch.loading;

import * as styles from "./emptystatePosts.module.scss";
import React, { FC } from "react";

const EmptyStatePosts: FC = () => {
  return (
    <div className={styles.container}>
      <h4 className={styles.text}>
        There aren&#39;t any posts yet. This is your chance to shine!
      </h4>
    </div>
  );
};

export default EmptyStatePosts;

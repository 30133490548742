// extracted by mini-css-extract-plugin
export var h1Typography = "network-module--h1-typography--1w2a1";
export var h2Typography = "network-module--h2-typography--pR5D9";
export var h3Typography = "network-module--h3-typography--2mxcP";
export var h4Typography = "network-module--h4-typography--YFQ7R";
export var h5Typography = "network-module--h5-typography--2FPqn";
export var h6Typography = "network-module--h6-typography--2dbAQ";
export var pTypography = "network-module--p-typography--2tMkL";
export var info = "network-module--info--2Nc8z";
export var buttonTypography = "network-module--button-typography--2Kuoz";
export var formLabelTypography = "network-module--form-label-typography--1JnLI";
export var menuTabTypography = "network-module--menu-tab-typography--1V1pJ";
export var linkStyle = "network-module--link-style--1INFC";
export var postTypography = "network-module--post-typography--t8LX9";
export var dropdownContainer = "network-module--dropdown-container--3FXh1";
export var header = "network-module--header--7v0fy";
export var headerDesktop = "network-module--header-desktop--1SdOm";
export var filterButton = "network-module--filter-button--w7OqZ";
export var networkContainer = "network-module--network-container--307YU";
import * as styles from "./organizationStructure.module.scss";
import {
  AccordionDetails,
  Button,
  Hidden,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link } from "@reach/router";
import clsx from "clsx";

import React, {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Team } from "../../api/departmentsAPI";
import {
  clearDepartmentsErrors,
  fetchDepartments,
  removeDepartment,
  removeTeam,
} from "../../redux/actions/departmentsActions";
import { selectDepartments } from "../../redux/selectors/departmentsSelectors";
import { AddDepartmentDialog } from "./addDepartmentDialog";
import { AddUpdateTeamDialog } from "./addUpdateTeamDialog";
import { DepartmentMenu } from "./departmentMenu";
import {
  muiStylesButtons,
  muiStylesOrganizationStructure,
} from "./muiStylesOrganizationStructure";
import { RemoveDialog } from "./removeDialog";
import { TeamMenu } from "./teamMenu";
import { Accordion, AccordionSummary, AddTeamButton, Collapse } from "./utils";
import { profilePath } from "../../utils/paths";

const useButtonStyles = makeStyles(muiStylesButtons);
const useStyles = makeStyles(muiStylesOrganizationStructure);

export const OrganizationStructure = () => {
  const buttonClasses = useButtonStyles();
  const classes = useStyles();
  const dispatch = useDispatch();

  const departments = useSelector(selectDepartments);

  const [isAddDepartmentOpen, setIsAddDepartmentOpen] = useState(false);
  const [isTeamDialogOpen, setIsTeamDialogOpen] = useState(false);
  const [dialogDepartmentId, setDialogDepartmentId] = useState<number>();
  const [dialogDepartmentName, setDialogDepartmentName] = useState<string>();
  const [dialogTeam, setDialogTeam] = useState<Team>();
  const [isRemoveTeamDialogOpen, setIsRemoveTeamDialogOpen] = useState(false);
  const [
    isRemoveDepartmentDialogOpen,
    setIsRemoveDepartmentDialogOpen,
  ] = useState(false);

  const openAddDepartment = () => {
    dispatch(clearDepartmentsErrors());
    setIsAddDepartmentOpen(true);
  };
  const onAddDepartmentClose = () => setIsAddDepartmentOpen(false);
  const openAddTeam = (departmentId: number) => (
    event: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDialogDepartmentId(departmentId);
    setIsTeamDialogOpen(true);
    event.stopPropagation();
  };

  const onAddTeamClose = () => {
    setIsTeamDialogOpen(false);
    setDialogDepartmentId(undefined);
    setDialogTeam(undefined);
  };

  const removeDepartmentHandler = (
    id: number,
    departmentName: string
  ) => () => {
    setDialogDepartmentId(id);
    setDialogDepartmentName(departmentName);
    setIsRemoveDepartmentDialogOpen(true);
  };
  const removeTeamHandler = (departmentId: number, team: Team) => () => {
    setDialogDepartmentId(departmentId);
    setDialogTeam(team);
    setIsRemoveTeamDialogOpen(true);
  };
  const handleRemoveTeamConfirm = () => {
    if (dialogDepartmentId && dialogTeam) {
      dispatch(
        removeTeam({ departmentId: dialogDepartmentId, id: dialogTeam.id })
      );
    }
  };
  const handleRemoveDepartmentConfirm = () => {
    if (dialogDepartmentId) {
      dispatch(removeDepartment(dialogDepartmentId));
    }
  };
  const editTeamHandler = (departmentId: number, team: Team) => () => {
    setDialogDepartmentId(departmentId);
    setDialogTeam(team);
    setIsTeamDialogOpen(true);
  };

  useEffect(() => {
    dispatch(fetchDepartments());
    // assuming dispatch never changes so it will be called onece on first render
  }, [dispatch]);

  return (
    <>
      <AddDepartmentDialog
        isOpen={isAddDepartmentOpen}
        onClose={onAddDepartmentClose}
      />
      <AddUpdateTeamDialog
        team={dialogTeam}
        departmentId={dialogDepartmentId || 0}
        isOpen={isTeamDialogOpen}
        onClose={onAddTeamClose}
      />
      <RemoveDialog
        isOpen={isRemoveTeamDialogOpen}
        onClose={() => {
          setIsRemoveTeamDialogOpen(false);
          setDialogTeam(undefined);
          setDialogDepartmentId(undefined);
        }}
        action={handleRemoveTeamConfirm}
        header={`Remove department, “${dialogTeam ? dialogTeam.name : ""}”?`}
        description={`Occupants belonging to “${
          dialogTeam ? dialogTeam.name : ""
        }” will no longer have a department. This cannot be undone.`}
      />
      <RemoveDialog
        isOpen={isRemoveDepartmentDialogOpen}
        onClose={() => {
          setIsRemoveDepartmentDialogOpen(false);
          setDialogDepartmentId(undefined);
          setDialogDepartmentName(undefined);
        }}
        action={handleRemoveDepartmentConfirm}
        header={`Remove company, “${dialogDepartmentName || ""}”?`}
        description={`All departments within this company will also be removed. Occupants belonging to “${
          dialogDepartmentName || ""
        }” will no longer have a company or department. This cannot be undone.`}
      />
      <div className={classes.pageHeader}>
        <h3>Tenancy Schedule</h3>
        <Hidden mdDown implementation="css">
          <Button
            color="secondary"
            variant="contained"
            className={clsx(
              classes.btnAddDepartment,
              buttonClasses.secondaryButton
            )}
            onClick={openAddDepartment}
          >
            Add Company
          </Button>
        </Hidden>
      </div>
      <Hidden lgUp implementation="css">
        <Paper elevation={0} className={classes.desktopNotice}>
          Please use a desktop computer or laptop to manage the Tenancy Schedule
        </Paper>
      </Hidden>
      <Hidden mdDown implementation="css">
        <div className={classes.root}>
          {departments.length ? (
            departments.map(({ id: departmentId, name: department, teams }) => (
              <Accordion key={`${department}${departmentId}`}>
                <AccordionSummary
                  className={classes.accordionSummary}
                  expandIcon={<ArrowDropDownIcon />}
                  IconButtonProps={{ edge: "start" }}
                  aria-controls={`panel-${department}-content`}
                  id={`panel-${department}-header`}
                >
                  <Typography className={classes.heading}>
                    {department}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {`${teams.length} ${
                      teams.length === 1 ? "Department" : "Departments"
                    }`}
                  </Typography>
                  <AddTeamButton
                    color="inherit"
                    size="small"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={openAddTeam(departmentId)}
                    onFocus={(event) => event.stopPropagation()}
                  >
                    Add Department
                  </AddTeamButton>
                  <DepartmentMenu
                    removeDepartment={removeDepartmentHandler(
                      departmentId,
                      department
                    )}
                  />
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsLevel1}>
                  {teams.map(({ id: teamId, name: team, members }, index) => (
                    <Accordion
                      square
                      key={`${team}${teamId}`}
                      TransitionComponent={Collapse}
                    >
                      <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ArrowDropDownIcon />}
                        IconButtonProps={{ edge: "start" }}
                        aria-controls={`panel-${team}-content`}
                        id={`panel-${team}-header`}
                      >
                        <Typography className={classes.innerHeading}>
                          {`${team}`}
                        </Typography>
                        <Typography className={classes.innerSecondaryHeading}>
                          {`${members.length} ${
                            members.length === 1 ? "Occupant" : "Occupants"
                          }`}
                        </Typography>
                        <TeamMenu
                          removeTeam={removeTeamHandler(
                            departmentId,
                            teams[index]
                          )}
                          editTeam={editTeamHandler(departmentId, teams[index])}
                        />
                      </AccordionSummary>
                      <AccordionDetails className={classes.memberContainer}>
                        {members.map(({ id, firstName, lastName }) => (
                          <Link
                            to={`${profilePath}/${id}`}
                            key={`${teamId}-${id}`}
                            className={styles.linkStyle}
                          >
                            <Typography
                              className={classes.memberRow}
                              key={`${teamId}-${id}`}
                            >{`${firstName} ${lastName}`}</Typography>
                          </Link>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Paper elevation={1} className={classes.noDeparmentsNotice}>
              <h3>There is no Tenancy Schedule yet.</h3>
              <Button
                color="primary"
                variant="contained"
                className={buttonClasses.primaryButton}
                onClick={openAddDepartment}
              >
                Add Company
              </Button>
            </Paper>
          )}
        </div>
      </Hidden>
    </>
  );
};

import * as styles from "./mobileSearchTopBar.module.scss";
import {
  AppBar,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import React, { FC } from "react";
import muiStyles from "./muiStylesActivityTopBar";
import IconBack from "../../../../icons/navigation/icon-back.svg";
import IconSearch from "../../../../icons/navigation/icon-search.svg";
import IconClear from "../../../../icons/navigation/icon-close-small.svg";

const useStyles = makeStyles(muiStyles);

interface Props {
  clearSearchInput: () => void;
  handleClose: () => void;
  handleSearchInputChanges: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  searchValue: string;
}

const MobileSearchTopBar: FC<Props> = ({
  clearSearchInput,
  handleClose,
  handleSearchInputChanges,
  searchValue,
}) => {
  const classes = useStyles(useStyles);

  return (
    <AppBar className={classes.root} elevation={0}>
      <Toolbar className={styles.topbarCustom}>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="close"
          onClick={handleClose}
        >
          <img src={IconBack} alt="icon back" />
        </IconButton>
        <FormControl className={classes.search}>
          <TextField
            className={classes.input}
            id="input-with-icon-textfield"
            onChange={handleSearchInputChanges}
            placeholder="Search community by users"
            value={searchValue}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <img src={IconSearch} alt="icon search" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearchInput}>
                    <img src={IconClear} alt="icon clear" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Toolbar>
    </AppBar>
  );
};

export default MobileSearchTopBar;

import * as styles from "../comments.module.scss";
import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "../../../../redux/selectors/authSelectors";
import PrimaryButton from "../../../Common/Buttons/PrimaryButton";
import SecondaryButton from "../../../Common/Buttons/SecondaryButton";
import UserAvatar from "../../../Common/UserAvatar";
import { makeStyles, TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { muiStylesCommentForm } from "../muiStylesComments";
import {
  addComment,
  editComment,
} from "../../../../redux/actions/pulse/pulseCommentActions";
import { schema } from "./schema";
import { yupResolver } from '@hookform/resolvers/yup';
import {Comment} from "../../../../api/pulseAPI";

const useStyles = makeStyles(muiStylesCommentForm);

interface CommentForm {
  body: string;
}
interface Props {
  comment?: Comment;
  postId: number;
  handleEditFormClose?: () => void;
}

const CreateComment: FC<Props> = ({
  comment,
  postId,
  handleEditFormClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectAuthUser);

  const [buttonsVisiblity, setButtonsVisiblity] = useState(false);

  const { control, handleSubmit, reset, formState: {isValid, isDirty} } = useForm<CommentForm>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: handleEditFormClose ? { body: comment?.body } : {body: ""},
  });

  const handleClearForm = () => {
    setButtonsVisiblity(false);
    reset();
  };

  const submit = handleSubmit((data: CommentForm) => {
    if (comment?.id) {
      dispatch(
        editComment({
          id: comment.id,
          post: postId,
          body: data.body,
        })
      );
      handleEditFormClose?.();
    } else {
      dispatch(addComment({ post: postId, ...data }));
      handleClearForm();
    }
  });

  return (
    <div className={styles.commentContainer}>
      <UserAvatar
        firstName={currentUser?.firstName}
        lastName={currentUser?.lastName}
      />
      <form id="comment-form" className={styles.commentForm}>
        <Controller
          name="body"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              multiline
              rowsMax={4}
              placeholder="Comment..."
              className={classes.bodyInput}
              onClick={() => setButtonsVisiblity(true)}
              InputProps={{ className: classes.noBorderBottom }}
              {...rest}
            />
          )}
        />
        {(buttonsVisiblity || handleEditFormClose) && (
          <div className={styles.commentFormButtons}>
            <PrimaryButton text="Post" submit event={submit} disabled={!isValid || !isDirty}/>
            {handleEditFormClose && (
              <SecondaryButton
                text="Cancel"
                event={() =>
                  (handleEditFormClose
                    ? handleEditFormClose?.()
                    : setButtonsVisiblity(false))
                }
              />
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default CreateComment;

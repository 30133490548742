// extracted by mini-css-extract-plugin
export var h1Typography = "TopBar-module--h1-typography--2Vhe7";
export var h2Typography = "TopBar-module--h2-typography--3uEu8";
export var h3Typography = "TopBar-module--h3-typography--KoAqh";
export var h4Typography = "TopBar-module--h4-typography--3XUB3";
export var h5Typography = "TopBar-module--h5-typography--2YNtr";
export var h6Typography = "TopBar-module--h6-typography--2pXuF";
export var pTypography = "TopBar-module--p-typography--1wpJg";
export var info = "TopBar-module--info--1ZO9s";
export var buttonTypography = "TopBar-module--button-typography--2OR4C";
export var formLabelTypography = "TopBar-module--form-label-typography--cRIY7";
export var menuTabTypography = "TopBar-module--menu-tab-typography--1F0ih";
export var linkStyle = "TopBar-module--link-style--1JLLO";
export var postTypography = "TopBar-module--post-typography--2BemO";
export var searchSection = "TopBar-module--search-section--3la42";
export var menuContainer = "TopBar-module--menu-container--36OmG";
export var items = "TopBar-module--items--3wFt5";
export var itemsHeader = "TopBar-module--items-header--2las4";
export var item = "TopBar-module--item--1zezO";
export var userSection = "TopBar-module--user-section--3o5IW";
export var userInfo = "TopBar-module--user-info--3IqFT";
export var userName = "TopBar-module--user-name--1rj2R";
export var userJob = "TopBar-module--user-job--2SEPx";
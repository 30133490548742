import * as React from "react";
import * as styles from "./mobileFiltering.module.scss";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { FC } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import muiStylesMobileFiltering from "./muiStylesMobileFiltering";

export interface MobileFilteringProps {
  toggleFiltering(): void;
  changeFilterByMe(arg0: boolean): void;
  filterByMe: boolean;
}

const useStyles = makeStyles(muiStylesMobileFiltering);

const MobileFiltering: FC<MobileFilteringProps> = ({
  toggleFiltering,
  changeFilterByMe,
  filterByMe,
}) => {
  const classes = useStyles();

  const [myEventsCheckbox, setMyEventsCheckbox] = React.useState(filterByMe);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMyEventsCheckbox(event.target.checked);
  };

  const applyFilter = () => {
    changeFilterByMe(myEventsCheckbox);
    toggleFiltering();
  };

  return (
    <div className={styles.filteringContainer}>
      <div>
        <div className={styles.filteringContainerInput}>
          <FormControlLabel
            control={
              <Checkbox
                checked={myEventsCheckbox}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Show My events only"
          />
        </div>

        <div>
          <div className={classes.root}>
            <Button
              onClick={toggleFiltering}
              className={classes.cancel}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={applyFilter}
              className={classes.apply}
              variant="contained"
              color="primary"
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFiltering;

// extracted by mini-css-extract-plugin
export var h1Typography = "addEmployeeDialog-module--h1-typography--3foW2";
export var h2Typography = "addEmployeeDialog-module--h2-typography--yLQdL";
export var h3Typography = "addEmployeeDialog-module--h3-typography--2nMXd";
export var h4Typography = "addEmployeeDialog-module--h4-typography--Pzmgu";
export var h5Typography = "addEmployeeDialog-module--h5-typography--g3rN3";
export var h6Typography = "addEmployeeDialog-module--h6-typography--1NZ7K";
export var pTypography = "addEmployeeDialog-module--p-typography--vjbCi";
export var info = "addEmployeeDialog-module--info--onPGs";
export var buttonTypography = "addEmployeeDialog-module--button-typography--HcmVT";
export var formLabelTypography = "addEmployeeDialog-module--form-label-typography--2dSvu";
export var menuTabTypography = "addEmployeeDialog-module--menu-tab-typography--3jCUh";
export var linkStyle = "addEmployeeDialog-module--link-style--2XZk3";
export var postTypography = "addEmployeeDialog-module--post-typography--1lifx";
import { Theme, createStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  secondaryColor,
  secondaryColorHover,
} from "../../../styles/mui/theme";

const muiStylesActivityTopBar = (theme: Theme) =>
  createStyles({
    buttonDelete: {
      "margin-right": "10px",
      "color": primaryColor,
      "border": `1px solid ${theme.palette.divider}`,
      "backgroundColor": secondaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: secondaryColorHover,
      },
    },
    dropdownControl: {
      minWidth: 120,
    },
    form: theme.mixins.toolbar,
    iconClose: {
      "width": "fit-content",
      "margin-right": "15px",
      "cursor": "pointer",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    root: {
      position: "sticky",
      top: "0",
    },
  });

export default muiStylesActivityTopBar;

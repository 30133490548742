import StandardLayout from "../components/Layout/StandardLayout";
import React from "react";
import Seo from "../components/seo";
import Loader from "../components/AppLoader";
import { useSelector } from "react-redux";
import { selectUsersLoading } from "../redux/selectors/usersSelectors";
import AllEmployees from "../components/AllEmployees";
import { selectBulkUploadLoading } from "../redux/selectors/bulkUploadSelectors";

const NetworkStructurePage = () => {
  const usersLoading = useSelector(selectUsersLoading);
  const bulkUploadLoading = useSelector(selectBulkUploadLoading);

  return (
    <StandardLayout>
      <Seo title="Network" />
      <Loader loading={usersLoading || bulkUploadLoading} />
      <AllEmployees />
    </StandardLayout>
  );
};

export default NetworkStructurePage;

import { createStyles } from "@material-ui/core/styles";
import {
  fontColorSecondary,
  fontColorTertiary,
  primaryColor,
  primaryColorDisabled,
  primaryColorHover,
  primaryColorPressed,
  secondaryColor,
  typographyButtons,
  warningColor,
  warningColorDisabled,
  warningColorFocused,
  warningColorHover,
} from "../../../styles/mui/theme";

const commonButtonProperties = {
  "height": "40px",
  "width": "130px",
  "padding": "10px 16px",
  "border-radius": "4px",
  "text-align": "center",
  "text-transform": "capitalize",
  ...typographyButtons,
};

const muiStylesButtons = () =>
  createStyles({
    primaryButton: {
      ...commonButtonProperties,
      "backgroundColor": primaryColor,
      "color": fontColorSecondary,
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
      "&:disabled": {
        backgroundColor: primaryColorDisabled,
        color: fontColorSecondary,
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: primaryColorPressed,
      },
    },
    secondaryButton: {
      ...commonButtonProperties,
      backgroundColor: secondaryColor,
      color: primaryColor,
    },
    iconButton: {
      "height": "40px",
      "min-width": "75px",
      "max-height": "130px",
      "text-align": "center",
      "text-transform": "capitalize",
      "backgroundColor": secondaryColor,
      "color": fontColorTertiary,
    },
    tertiaryButton: {
      ...commonButtonProperties,
      "backgroundColor": warningColor,
      "color": secondaryColor,
      "&:hover": {
        backgroundColor: warningColorHover,
      },
      "&:disabled": {
        backgroundColor: warningColorDisabled,
        color: fontColorSecondary,
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: warningColorFocused,
      },
    },
  });

export default muiStylesButtons;

// extracted by mini-css-extract-plugin
export var h1Typography = "postLoader-module--h1-typography--faetv";
export var h2Typography = "postLoader-module--h2-typography--2fwpU";
export var h3Typography = "postLoader-module--h3-typography--3DSZN";
export var h4Typography = "postLoader-module--h4-typography--3-C2X";
export var h5Typography = "postLoader-module--h5-typography--2VtOP";
export var h6Typography = "postLoader-module--h6-typography--WqcH1";
export var pTypography = "postLoader-module--p-typography--3dLGX";
export var info = "postLoader-module--info--u7wng";
export var buttonTypography = "postLoader-module--button-typography--2m9TC";
export var formLabelTypography = "postLoader-module--form-label-typography--14FU8";
export var menuTabTypography = "postLoader-module--menu-tab-typography--2oh-J";
export var linkStyle = "postLoader-module--link-style--KDe79";
export var postTypography = "postLoader-module--post-typography--2jzZ1";
export var loaderContainer = "postLoader-module--loader-container--2YST_";
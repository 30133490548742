// extracted by mini-css-extract-plugin
export var h1Typography = "navigation-module--h1-typography--3Iu5r";
export var h2Typography = "navigation-module--h2-typography--3AVad";
export var h3Typography = "navigation-module--h3-typography--3xBG1";
export var h4Typography = "navigation-module--h4-typography--1v92c";
export var h5Typography = "navigation-module--h5-typography--2RRBp";
export var h6Typography = "navigation-module--h6-typography--Eg2AS";
export var pTypography = "navigation-module--p-typography--1BR08";
export var info = "navigation-module--info--EkaLx";
export var buttonTypography = "navigation-module--button-typography--3Rqb2";
export var formLabelTypography = "navigation-module--form-label-typography--2Yv5V";
export var menuTabTypography = "navigation-module--menu-tab-typography--35g2e";
export var linkStyle = "navigation-module--link-style--1ETcP";
export var postTypography = "navigation-module--post-typography--f0El2";
export var navigationToolbar = "navigation-module--navigation-toolbar--2YOZP";
export var navigationLabel = "navigation-module--navigation-label--3O_1P";
export var userProfileNavigationLabel = "navigation-module--user-profile-navigation-label--FQtPa";
export var previousButton = "navigation-module--previous-button--KTn8M";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { User } from "../../../api/usersAPI";
import { selectUsers } from "../../../redux/selectors/usersSelectors";
import MobileSearchTopBar from "./MobileSearchTopBar";
import SearchResults from "../SearchResults";
import { Divider } from "@material-ui/core";

interface Props {
  handleClose: () => void;
}

const MobileSearch: FC<Props> = (props) => {
  const userData = useSelector(selectUsers);

  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState<User[]>([]);

  const handleSearchInputChanges = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const searchedString = event.target.value;

    setSearchValue(searchedString);

    const searchResults = userData.filter((user: User) => {
      const info = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`;

      return info.includes(searchedString.toLowerCase());
    });

    setResults(searchResults);
  };

  const clearSearchInput = () => {
    setSearchValue("");
  };

  return (
    <div>
      <MobileSearchTopBar
        clearSearchInput={clearSearchInput}
        handleClose={props.handleClose}
        handleSearchInputChanges={handleSearchInputChanges}
        searchValue={searchValue}
      />
      <Divider />
      <SearchResults
        results={results}
        searchValue={searchValue}
        handleClose={props.handleClose}
      />
    </div>
  );
};

export default MobileSearch;

// extracted by mini-css-extract-plugin
export var h1Typography = "mobileFiltering-module--h1-typography--UGDle";
export var h2Typography = "mobileFiltering-module--h2-typography--myj2X";
export var h3Typography = "mobileFiltering-module--h3-typography--18Ap0";
export var h4Typography = "mobileFiltering-module--h4-typography--3CWxW";
export var h5Typography = "mobileFiltering-module--h5-typography--2TlKb";
export var h6Typography = "mobileFiltering-module--h6-typography--2mNv3";
export var pTypography = "mobileFiltering-module--p-typography--35h4-";
export var info = "mobileFiltering-module--info--2jX5u";
export var buttonTypography = "mobileFiltering-module--button-typography--159Hf";
export var formLabelTypography = "mobileFiltering-module--form-label-typography--3Ow2-";
export var menuTabTypography = "mobileFiltering-module--menu-tab-typography--3j2ok";
export var linkStyle = "mobileFiltering-module--link-style--24A6l";
export var postTypography = "mobileFiltering-module--post-typography--1MzRt";
export var mobileFilteringContainer = "mobileFiltering-module--mobile-filtering-container--1Of2m";
export var filteringContainer = "mobileFiltering-module--filtering--container--11hnY";
export var filteringContainerInput = "mobileFiltering-module--filtering--container-input--SzQkC";
// extracted by mini-css-extract-plugin
export var h1Typography = "user-module--h1-typography--3_FLl";
export var h2Typography = "user-module--h2-typography--35dJ2";
export var h3Typography = "user-module--h3-typography--3mhNm";
export var h4Typography = "user-module--h4-typography--X8go7";
export var h5Typography = "user-module--h5-typography--2afry";
export var h6Typography = "user-module--h6-typography--3fp2R";
export var pTypography = "user-module--p-typography--2Tpg8";
export var info = "user-module--info--3U4p_";
export var buttonTypography = "user-module--button-typography--2jLds";
export var formLabelTypography = "user-module--form-label-typography--38IgQ";
export var menuTabTypography = "user-module--menu-tab-typography--28CoB";
export var linkStyle = "user-module--link-style--dvQlg";
export var postTypography = "user-module--post-typography--2Kiug";
export var container = "user-module--container--AIh5d";
export var userPhoto = "user-module--userPhoto--26Lfq";
export var userInfo = "user-module--user-info--g_j1Y";
export var organizer = "user-module--organizer--3MG_z";
import { createStyles } from "@material-ui/core/styles";
import {
  primaryColor,
  secondaryColor,
  warningColor,
  warningColorHover,
} from "../../../styles/mui/theme";

const muiStylesEmployeeList = () =>
  createStyles({
    button: {
      backgroundColor: primaryColor,
      color: secondaryColor,
      textTransform: "capitalize",
      width: "150px",
      height: "45px",
      fontSize: "17px",
    },
    buttonDisable: {
      "backgroundColor": warningColor,
      "color": secondaryColor,
      "border": `1px solid ${warningColor}`,
      "marginLeft": "20px",
      "textTransform": "capitalize",
      "width": "150px",
      "height": "45px",
      "&:hover": {
        backgroundColor: warningColorHover,
      },
    },
    buttonHidden: {
      visibility: "hidden",
    },
  });

export default muiStylesEmployeeList;

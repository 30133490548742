import { Theme, createStyles } from "@material-ui/core/styles";
import { desktopBreakpoint } from "../../../../styles/utils";
import { inputBackgroundColor } from "../../../../styles/mui/theme";

const backgroundBorderPadding = {
  "& .MuiFilledInput-root": {
    background: inputBackgroundColor,
  },
  "& .MuiFilledInput-underline:before": {
    "border-bottom": "1px solid transparent",
  },
  "& .MuiFilledInput-input": {
    padding: "15px 12px 10px",
  },
};

const muiStylesPickers = (theme: Theme) =>
  createStyles({
    container: {
      "display": "flex",
      "flex-direction": "column",
      "align-items": "flex-start",
      [desktopBreakpoint(theme)]: {
        "display": "flex",
        "flex-direction": "row",
      },
      "& p": {
        "text-align": "left",
        "margin-top": "10px",
        "margin-bottom": "10px",
        "margin-left": "20px",
        [desktopBreakpoint(theme)]: {
          margin: "auto",
        },
      },
    },
    inputDate: {
      "margin-left": "20px",
      "margin-top": "10px",
      "text-align": "center",
      "width": "190px",
      [desktopBreakpoint(theme)]: {
        "flex-grow": "1",
        "margin-top": "0px",
      },
      ...backgroundBorderPadding,
    },
    inputTime: {
      "margin-left": "20px",
      "margin-top": "10px",
      "text-align": "center",
      "width": "190px",
      [desktopBreakpoint(theme)]: {
        "flex-grow": "1",
        "margin-top": "0px",
        "width": "160px",
      },
      ...backgroundBorderPadding,
    },
  });

export default muiStylesPickers;

import * as styles from "./activities.module.scss";
import React, { FC, useEffect } from "react";
import muiStylesActivities from "./muiStylesActivities";
import Tooltip from "@material-ui/core/Tooltip";
import { ActivityType } from "../../../../../api/activityTypesAPI";
import { useSelector } from "react-redux";
import { selectActivityTypes } from "../../../../../redux/selectors/activityTypesSelectors";
import { useIsSmall } from "../../../../../utils";
import { fetchActivityIcon } from "../../../../../utils";
import { customActivityTypeName } from "../../../../../utils";
import { makeStyles, ClickAwayListener } from "@material-ui/core";

export interface ActivitiesProps {
  activityTypes: number[] | undefined;
}

const useStyles = makeStyles(muiStylesActivities);

let activitiesToShow: number[];
let activitiesToHide: number[];

const Activities: FC<ActivitiesProps> = ({ activityTypes }) => {
  let numberOfActivitiesToShow;
  const allActivityTypes: ActivityType[] = useSelector(selectActivityTypes);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const getHiddenActivityNames = () => {
    const tooltipText = activitiesToHide
      .map((hiddenActivityId) => getActivityType(hiddenActivityId))
      .filter((activityType) => Boolean)
      .map((activityType) => activityType?.name)
      .join(" \n");

    return tooltipText;
  };

  const isSmall = useIsSmall(1400 - 1);

  const getActivityType = (id: number): ActivityType | undefined => {
    const activityType = allActivityTypes.find((actType) => {
      return actType.id === id;
    });

    return activityType;
  };

  const updateIcons = () => {
    numberOfActivitiesToShow = isSmall ? 3 : 5;
    const toShow =
      activityTypes && activityTypes.slice(0, numberOfActivitiesToShow);
    const toHide = activityTypes?.slice(numberOfActivitiesToShow);

    if (toShow) {
      activitiesToShow = toShow;
    }

    if (toHide) {
      activitiesToHide = toHide;
    }
  };

  updateIcons();

  useEffect(() => {
    updateIcons();
  }, [isSmall]);

  return (
    <div className={styles.activitiesContainer}>
      {activitiesToShow
        .map((activityId) => getActivityType(activityId))
        .filter(
          (activityType) =>
            activityType && activityType.name !== customActivityTypeName
        )
        .map((activityType) => (
          <span key={activityType?.id} className={styles.activityContainer}>
            <img
              role="button"
              className={styles.activityIcon}
              src={
                activityType && fetchActivityIcon(activityType.name, "#0C3DEB")
              }
              alt="Activity_icon"
            />
          </span>
        ))}
      {activitiesToHide.length > 0 && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <span className={styles.activityContainer}>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={getHiddenActivityNames()}
            >
              <div
                onClick={handleTooltipOpen}
                className={styles.activityIconText}
              >
                +{activitiesToHide.length}
              </div>
            </Tooltip>
          </span>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Activities;

import { Theme, createStyles } from "@material-ui/core/styles";
import { secondaryColor } from "../../../styles/mui/theme";

const muiStylesDesktopCalendar = (theme: Theme) =>
  createStyles({
    formControl: {
      "margin": theme.spacing(1),
      "width": 150,
      "backgroundColor": secondaryColor,
      "borderRadius": "4px",
      "textAlign": "center",
      "& .MuiOutlinedInput-input": {
        padding: "10px 32px 10px 10px;",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        "border-color": "transparent",
      },
    },
    formControlLastChild: {
      marginRight: 0,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
      borderRadius: "10px",
    },
  });

export default muiStylesDesktopCalendar;

// extracted by mini-css-extract-plugin
export var h1Typography = "activity-module--h1-typography--3R8YB";
export var h2Typography = "activity-module--h2-typography--2snEC";
export var h3Typography = "activity-module--h3-typography--AdwIB";
export var h4Typography = "activity-module--h4-typography--1XCAl";
export var h5Typography = "activity-module--h5-typography--3h_LH";
export var h6Typography = "activity-module--h6-typography--3UAj7";
export var pTypography = "activity-module--p-typography--SxpBg";
export var info = "activity-module--info--3YO8o";
export var buttonTypography = "activity-module--button-typography--2TT54";
export var formLabelTypography = "activity-module--form-label-typography--1xMSo";
export var menuTabTypography = "activity-module--menu-tab-typography--1BtE8";
export var linkStyle = "activity-module--link-style--3956s";
export var postTypography = "activity-module--post-typography--3CtV9";
export var activity = "activity-module--activity--3qHus";
export var activityActive = "activity-module--activity-active--3WaH1";
export var activityName = "activity-module--activity--name--2hOBY";
export var activityTime = "activity-module--activity--time--3Qpxf";
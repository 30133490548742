import * as styles from "./companySettings.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Avatar,
  Button,
  FormControlLabel,
  InputLabel,
  Paper,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Company } from "../../api/companyAPI";
import icon from "../../images/motko-icon.png";
import { editCompany, fetchCompany } from "../../redux/actions/companyActions";
import { selectCompany } from "../../redux/selectors/companySelectors";
import { DiscardDialog } from "./discardDialog";
import {
  muiStylesButtons,
  muiStylesCompanySettings,
  muiStylesFormControlLabel,
} from "./muiStylesCompanySettings";
import { companySettingsSchema } from "./schema";

const useStyles = makeStyles(muiStylesCompanySettings);
const useInputStyles = makeStyles(muiStylesFormControlLabel);
const useButtonStyles = makeStyles(muiStylesButtons);

export const CompanySettings = () => {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    reset,
    control,
  } = useForm<Company>({
    mode: "onChange",
    resolver: yupResolver(companySettingsSchema),
    defaultValues: company,
  });
  const submit = handleSubmit((data) => {
    dispatch(editCompany(data));
  });
  const handleDiscard = () => {
    setIsDiscardDialogOpen(true);
  };

  // a Trick for updating existing data, for useForm to notice real values
  useEffect(() => reset(company), [company, reset]);
  useEffect(() => {
    dispatch(fetchCompany());
    // assuming dispatch never changes so it will be called onece on first render
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <DiscardDialog
        isOpen={isDiscardDialogOpen}
        action={() => reset(company)}
        onClose={() => setIsDiscardDialogOpen(false)}
      />
      <div className={classes.pageHeader}>
        <h3>Building Settings</h3>
      </div>
      <div className={classes.root}>
        <Paper elevation={0} className={classes.logo}>
          <InputLabel htmlFor="">Building</InputLabel>
          <Paper elevation={0} className={classes.logoContainer}>
            <Avatar variant="square" alt="company logo" src={icon} />
          </Paper>
        </Paper>
        <Paper elevation={0} className={classes.details}>
          <form id="company-settings-form" onSubmit={submit}>
            <Controller
              control={control}
              name="name"
              render={({ field: { onChange, value: formValue, ref } }) => (
                <FormControlLabel
                  control={
                    <TextField
                      ref={ref}
                      onChange={onChange}
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      variant="filled"
                      value={formValue}
                      fullWidth
                    />
                  }
                  classes={inputClasses}
                  labelPlacement="top"
                  label="Building Name"
                />
              )}
            />
            <Controller
              control={control}
              name="address"
              render={({ field: { onChange, value: formValue, ref } }) => (
                <FormControlLabel
                  control={
                    <TextField
                      ref={ref}
                      onChange={onChange}
                      type="text"
                      error={!!errors.address}
                      helperText={errors?.address?.message}
                      variant="filled"
                      value={formValue}
                      fullWidth
                    />
                  }
                  classes={inputClasses}
                  labelPlacement="top"
                  label="Address"
                />
              )}
            />
            <Controller
              control={control}
              name="domain"
              render={({ field: { onChange, value: formValue, ref } }) => (
                <FormControlLabel
                  control={
                    <TextField
                      ref={ref}
                      onChange={onChange}
                      type="text"
                      error={!!errors.domain}
                      helperText={errors?.domain?.message}
                      variant="filled"
                      value={formValue}
                      fullWidth
                    />
                  }
                  classes={inputClasses}
                  labelPlacement="top"
                  label="Main Contact"
                />
              )}
            />
            <div className={classes.buttons}>
              <Button
                onClick={handleDiscard}
                className={buttonClasses.secondaryButton}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                form="company-settings-form"
                className={buttonClasses.primaryButton}
                disabled={!isValid || isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    </div>
  );
};

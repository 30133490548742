// extracted by mini-css-extract-plugin
export var h1Typography = "userProfile-module--h1-typography--2JkPm";
export var h2Typography = "userProfile-module--h2-typography--1CCoi";
export var h3Typography = "userProfile-module--h3-typography--3wddA";
export var h4Typography = "userProfile-module--h4-typography--oYN6D";
export var h5Typography = "userProfile-module--h5-typography--1xByS";
export var h6Typography = "userProfile-module--h6-typography--1fAUc";
export var pTypography = "userProfile-module--p-typography--1jHKE";
export var info = "userProfile-module--info--1LDgz";
export var buttonTypography = "userProfile-module--button-typography--3ZQEt";
export var formLabelTypography = "userProfile-module--form-label-typography--11AE4";
export var menuTabTypography = "userProfile-module--menu-tab-typography--eLHqq";
export var linkStyle = "userProfile-module--link-style--Qu54Y";
export var postTypography = "userProfile-module--post-typography--20m1y";
export var userContainer = "userProfile-module--user-container--2O20t";
export var userInfo = "userProfile-module--user-info--1b97X";
export var userName = "userProfile-module--user-name--36WE4";
export var userData = "userProfile-module--user-data--24S_b";
export var userSports = "userProfile-module--user-sports--1zQpC";
import { MenuItem } from "@material-ui/core";
import * as styles from "./comments.module.scss";
import React, {
  FC,
  useState,
  MouseEvent,
  useRef,
  useEffect,
  Children,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import KebabMenu from "../../Common/KebabMenu";
import DeletePopup from "../../Common/Popups/DeletePopup";
import CommentWrapper from "./CommentWrapper";
import CreateComment from "./CreateComment";
import {
  selectPostComments,
  selectCommentsFetchArgs,
  selectCommentsFetchLoading,
} from "../../../redux/selectors/pulse/pulseCommentsSelectors";
import {
  CommentParams,
  deleteComment,
  fetchComments,
} from "../../../redux/actions/pulse/pulseCommentActions";
import PulseContentLoader from "../ContentLoader";
import { Comment } from "../../../api/pulseAPI";
import {selectAuthUser} from "../../../redux/selectors/authSelectors";

const commentFetchingNumberInitial = 3;
const commentFetchingNumber = 10;

interface Props {
  postId: number;
}

const CommentsList: FC<Props> = ({ postId }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const currentComment = useRef<undefined | number>(undefined);

  const currentUser = useSelector(selectAuthUser);
  const isCommentsLoading = useSelector(selectCommentsFetchLoading(postId));
  const selectFetchParams = useSelector(selectCommentsFetchArgs(postId));
  const comments = useSelector(selectPostComments(postId));

  const handleFetchComments = ({ post, limit, offset }: CommentParams) =>
    dispatch(fetchComments({ post, limit, offset }));

  useEffect(() => {
    handleFetchComments({
      post: postId,
      limit: commentFetchingNumberInitial,
    });
  }, [postId]);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>, id?: number) => {
    if (!isEditMode) {
      // prevent opening menu of another comment, when edit mode is already ON
      setAnchorEl(event.currentTarget);
      currentComment.current = id;
    }
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleDeleteComment = (id?: number) => {
    id &&
      currentComment.current &&
      dispatch(
        deleteComment({ post: postId, commentId: currentComment.current })
      );
    setOpenDeletePopup(false);
    handleMenuClose();
  };

  const kebabMenuContent = () => {
    const currentCommentData = comments?.reduce<Comment | undefined>((prev, curr) => {
      if (currentComment.current === curr.id) {
        return curr;
      }

      return prev;
    }, undefined);

    if (!currentCommentData) {
      return null;
    }

    const editMenuItem = (
      <MenuItem
        onClick={() => {
          setIsEditMode(true);
          handleMenuClose();
        }}
      >
              Edit comment
      </MenuItem>
    );

    const deleteMenuItem = (
      <span className={styles.warningText}>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            setOpenDeletePopup(true);
          }}
        >
          Delete comment
        </MenuItem>
      </span>
    );

    if (currentCommentData.owner.id === currentUser?.id) {
      return (
        <div>
          {editMenuItem}
          {deleteMenuItem}
        </div>
      );
    } else if (currentUser?.isSuperuser || currentUser?.isCompanyAdmin) {
      return <div>{deleteMenuItem}</div>;
    }

    return null;
  };

  return (
    <>
      <div>
        <CreateComment postId={postId} />
        {!!comments?.length && comments?.length > 0 && (
          <>
            {Children.toArray(
              comments.map((comment) => (
                <>
                  <CommentWrapper
                    key={comment.id}
                    comment={comment}
                    postId={postId}
                    edit={comment.id === currentComment.current && isEditMode}
                    handleMenuOpen={handleMenuOpen}
                    handleEditFormClose={() => setIsEditMode(false)}
                  />
                </>
              ))
            )}
          </>
        )}
        {isCommentsLoading ? (
          <PulseContentLoader text="Loading new comments..." />
        ) : (
          (selectFetchParams?.offset || selectFetchParams?.offset === 0) && (
            <div
              className={styles.commentFetchMore}
              role="button"
              onClick={() =>
                handleFetchComments({
                  post: postId,
                  limit: commentFetchingNumber,
                  offset: selectFetchParams?.offset,
                })
              }
            >
              Load more comments...
            </div>
          )
        )}
      </div>
      <KebabMenu
        anchor={anchorEl}
        open={isMenuOpen}
        handleOpen={handleMenuOpen}
        handleClose={handleMenuClose}
      >
        {kebabMenuContent()}
      </KebabMenu>
      <DeletePopup
        content="Are you sure you'd like to delete this comment?"
        header="Delete Comment?"
        open={openDeletePopup}
        deleteMode
        onAcceptButton={() => handleDeleteComment(postId)}
        onCancelButton={() => setOpenDeletePopup(false)}
      />
    </>
  );
};

export default CommentsList;

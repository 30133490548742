import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";
import {
  muiStylesButtons,
  muiStylesDialog,
} from "./muiStylesAddEmployeeDialog";
import { EmployeeBulkUpload } from "../../EmployeeBulkUpload";
import * as styles from "./addEmployeeDialog.module.scss";

const useButtonStyles = makeStyles(muiStylesButtons);
const useDialogStyles = makeStyles(muiStylesDialog);

interface AddEmployeeDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const AddEmployeeDialog: FC<AddEmployeeDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const buttonClasses = useButtonStyles();
  const classes = useDialogStyles();

  const bulkUploadUrl = `${process.env.API_URL ?? ""}/api/users/bulk_upload/`;

  return (
    <Dialog
      className={classes.backgroundDialog}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>Add occupants</h5>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <p className={styles.info}>
          Use the Bulk Add CSV to quickly add all of your occupants.
        </p>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Paper elevation={0} className={classes.bulkInfo}>
          <p>Download the template occupant CSV</p>
          <Button
            href={bulkUploadUrl}
            download
            className={buttonClasses.secondaryButton}
            variant="outlined"
          >
            Download CSV
          </Button>
        </Paper>

        <Paper elevation={0} className={classes.bulkInfo}>
          <p>Upload bulk occupant CSV from your file system.</p>
          <EmployeeBulkUpload onUpload={onClose} />
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

import * as styles from "./user.module.scss";
import React, { FC, useEffect } from "react";
import Activities from "./Activities";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { StyledMenu, StyledMenuItem } from "./muiStylesUser";
import UserAvatar from "../../../Common/UserAvatar";
import { useLongPress } from "use-long-press";
import clsx from "clsx";
import { Link } from "gatsby";
import { useIsSmall } from "../../../../utils";
import { profilePath } from "../../../../utils/paths";
import { StyledCheckbox } from "../../../Common/Checkbox";

const options = ["View profile"];

export interface UserProps {
  firstName: string;
  lastName: string;
  email: string;
  id: number;
  activityTypes?: number[];
  role?: string | null;
  team?: string | null;
  checkedUsers: number[];
  modifyCheckedUsers(arg0: boolean, arg1: number): void;
}

const User: FC<UserProps> = ({
  firstName,
  lastName,
  activityTypes,
  role,
  team,
  id,
  checkedUsers,
  modifyCheckedUsers,
}) => {
  const isSmall = useIsSmall();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isUserChecked = (): boolean =>
    checkedUsers.some((iteradedId: number) => iteradedId === id);
  const [checked, setChecked] = React.useState<boolean>(isUserChecked());

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    modifyCheckedUsers(event.target.checked, id);
  };

  const handleThreeDotMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    setChecked(isUserChecked());
  }, [checkedUsers]);

  const bind = useLongPress(() => {
    if (isSmall) {
      setChecked(!checked);
      modifyCheckedUsers(!checked, id);
    }
  });

  return (
    <div
      className={clsx(styles.userContainer, {
        [styles.userContainerChecked]: checked,
      })}
      {...bind}
    >
      <div className={styles.checkboxContainer}>
        <StyledCheckbox value={checked} onChange={handleCheckboxChange} />
      </div>
      <div className={styles.avatar}>
        <UserAvatar
          firstName={firstName}
          lastName={lastName}
          checked={checked}
        />
      </div>
      <div className={styles.userInfo}>
        <div className={styles.userName}>
          {firstName} {lastName}
        </div>
        <div className={styles.userDepartment}>
          {team} {!!team && !!role && "/"} {role}
        </div>
        <span className={styles.activityContainer}>
          <Activities activityTypes={activityTypes} />
        </span>
      </div>
      <div className={styles.menuButton}>
        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleThreeDotMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {options.map((option) => {
              return option === "View profile" ? (
                <Link
                  key={option}
                  to={`${profilePath}/${id}`}
                  className={styles.linkStyle}
                >
                  <StyledMenuItem onClick={handleClose}>
                    {option}
                  </StyledMenuItem>
                </Link>
              ) : (
                <StyledMenuItem key={option} onClick={handleClose}>
                  {option}
                </StyledMenuItem>
              );
            })}
          </StyledMenu>
        </div>
      </div>
    </div>
  );
};

export default User;

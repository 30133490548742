// extracted by mini-css-extract-plugin
export var h1Typography = "mobileCalendar-module--h1-typography--19bTB";
export var h2Typography = "mobileCalendar-module--h2-typography--1t57R";
export var h3Typography = "mobileCalendar-module--h3-typography--34wXm";
export var h4Typography = "mobileCalendar-module--h4-typography--1fZlR";
export var h5Typography = "mobileCalendar-module--h5-typography--1U0Y1";
export var h6Typography = "mobileCalendar-module--h6-typography--2SbhR";
export var pTypography = "mobileCalendar-module--p-typography--MJoKG";
export var info = "mobileCalendar-module--info--2KC5r";
export var buttonTypography = "mobileCalendar-module--button-typography--1AQV1";
export var formLabelTypography = "mobileCalendar-module--form-label-typography--1nihn";
export var menuTabTypography = "mobileCalendar-module--menu-tab-typography--1lA4a";
export var linkStyle = "mobileCalendar-module--link-style--jn9rr";
export var postTypography = "mobileCalendar-module--post-typography--28HN1";
export var calendarBackground = "mobileCalendar-module--calendar--background--JJs56";
export var calendarWrapper = "mobileCalendar-module--calendar--wrapper--1Weli";
export var filterButton = "mobileCalendar-module--filter-button--21tpz";
export var noActivities = "mobileCalendar-module--no-activities--3CECQ";
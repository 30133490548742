import * as styles from "./activityPreview.module.scss";
import { Button, Dialog, Divider, IconButton } from "@material-ui/core";
import { ChangeEvent, FC, useState } from "react";
import ActivityIcon from "../../../icons/activity-form/icon-activity.svg";
import ActivityTopBar from "../ActivityTopBar";
import CloseIcon from "@material-ui/icons/Close";
import ClosedActivity from "../../../icons/activity-form/icon-event-closed.svg";
import DescriptionIcon from "../../../icons/activity-form/icon-description.svg";
import InviteIcon from "../../../icons/activity-form/icon-invite.svg";
import LocationIcon from "../../../icons/activity-form/icon-location.svg";
import OpenActivity from "../../../icons/activity-form/icon-event-opened.svg";
import SportBadge from "../SportBadge";
import UserBox from "../User";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import muiStylesActivityPreview from "./muiStylesActivityPreview";
import {
  selectActivityById,
  selectActivityModificationLoading,
} from "../../../redux/selectors/activityCRUDSelectors";
import { selectAuthUser } from "../../../redux/selectors/authSelectors";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteActivity,
  updateActivityStatus,
} from "../../../redux/actions/activityCRUDActions";
import { Attendee, MinifiedUser } from "../../../api/usersAPI";
import Dropdown from "../ActivityTopBar/StatusDropdown";
import UpdateActivityForm from "../ActivityForms/UpdateActivityForm";
import Loader from "../../AppLoader";
import { selectActivitiesLoading } from "../../../redux/selectors/activitiesSelectors";
import { useIsSmall } from "../../../utils";
import { muiStylesButtons } from "../../CompanySettings/muiStylesCompanySettings";

const useStyles = makeStyles(muiStylesActivityPreview);
const useButtonStyles = makeStyles(muiStylesButtons);

interface Props {
  handleClose: () => void;
  id: number;
  open: boolean;
}

interface ActivityType {
  id: number;
  name: string;
}

export interface Owner extends MinifiedUser {
  status: "going";
}
export interface Activity {
  activityType: ActivityType;
  attendees: Attendee[];
  closed: boolean;
  description: string;
  endDate: string;
  id: number;
  location: string;
  owner: Owner;
  startDate: string;
  title: string;
  showToAll?: boolean;
}

const ActivityPreview: FC<Props> = (props) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const userData = useSelector(selectAuthUser);
  const dispatch = useDispatch();
  const activity = useSelector(selectActivityById(props.id));
  const activityLoading = useSelector(selectActivityModificationLoading);
  const fetchingActivitiesInProgress = useSelector(selectActivitiesLoading);

  const isSmall = useIsSmall();

  const isOrganizer = userData?.id === activity?.owner.id;

  // show attendee dropdown only for open activities which are not finished yet
  const isDropdownVisible =
    activity?.startDate && Date.parse(activity.endDate) > new Date().getTime()
      ? activity?.attendees.some(({ id }) => id === userData?.id) ||
        !activity?.closed
      : false;

  const currentUserInAttendees = activity?.attendees.find(
    (element) => element.id === userData?.id
  );

  // show Edit-Delete buttons only to organizer for activities which are not finished yet
  const isEditDeleteVisible =
    activity?.startDate && Date.parse(activity.endDate) > new Date().getTime()
      ? isOrganizer
      : false;

  const [status, setStatus] = useState(currentUserInAttendees?.status || "");
  const [openForm, setOpenForm] = useState(false);

  const handleDeleteActivity = () => {
    if (activity) {
      dispatch(deleteActivity(activity.id));
    }
    props.handleClose();
  };

  const handleEditActivity = () => {
    setOpenForm(true);
  };

  const handleCloseEditActivity = () => {
    setOpenForm(false);
  };

  const handleStatusChange = (event: ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
    dispatch(
      updateActivityStatus({
        id: activity?.id,
        status: event.target.value as string,
      })
    );
  };

  if (!activity) {
    props.handleClose();

    return <div />;
  }

  return (
    <div className={styles.container}>
      <Loader loading={activityLoading || fetchingActivitiesInProgress} />
      {isSmall ? (
        <ActivityTopBar
          handleClose={props.handleClose}
          organizer={isOrganizer}
          activity={activity.id}
          closed={activity.closed}
          status={status}
          handleStatusChange={handleStatusChange}
          userData={userData}
          preview
        />
      ) : (
        <div className={styles.closeContainer}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
            className={classes.iconClose}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
      )}

      <div>
        <div className={styles.previewTitle}>
          <h3>{activity.title}</h3>
          <div>
            <div className={styles.previewTime}>
              <div className={styles.containerTime}>
                <p className={styles.pTime}>
                  {moment(activity.startDate).format("ddd, MMMM DD, YYYY")}
                </p>
                <p className={styles.pTime}>
                  {moment(activity.startDate).format("hh:mm A")} -{" "}
                  {moment(activity.endDate).format("hh:mm A")}
                </p>
              </div>

              {!isSmall ? (
                isEditDeleteVisible ? (
                  <div>
                    <Button
                      variant="outlined"
                      className={classes.buttonDelete}
                      form="activity-form"
                      onClick={handleDeleteActivity}
                    >
                      Delete
                    </Button>
                    <Button
                      className={buttonClasses.primaryButton}
                      form="activity-form"
                      onClick={handleEditActivity}
                    >
                      Edit
                    </Button>
                    <Dialog
                      fullScreen
                      open={openForm}
                      onClose={handleCloseEditActivity}
                    >
                      <UpdateActivityForm
                        activity={activity}
                        open={openForm}
                        handleClose={handleCloseEditActivity}
                        editMode
                      />
                    </Dialog>
                  </div>
                ) : isDropdownVisible ? (
                  <Dropdown
                    handleStatusChange={handleStatusChange}
                    status={status}
                  />
                ) : null
              ) : null}
            </div>
          </div>
        </div>

        {isSmall ? null : <Divider />}
        <div className={styles.previewActivity}>
          <img src={ActivityIcon} alt="icon-activity" className="icon" />
          <SportBadge sport={activity.activityType.name} />
        </div>

        <div className={styles.previewActivity}>
          <img src={InviteIcon} alt="icon-invite" className="icon" />
          <p className={styles.textDetails}>
            {activity.attendees.length} invites
          </p>
        </div>
        <div className={styles.previewUsers}>
          <UserBox user={activity.owner} isOrganizer />
          {activity.attendees
            .filter((attendee) => attendee.id !== activity.owner.id)
            .map((attendee) => (
              <UserBox key={attendee.id} user={attendee} isOrganizer={false} />
            ))}
        </div>

        <div className={styles.previewLocation}>
          <img className="icon" src={LocationIcon} alt="icon-location" />
          <p className={styles.textDetails}>{activity.location}</p>
        </div>

        <div className={styles.previewDescription}>
          <img className="icon" src={DescriptionIcon} alt="icon-description" />
          <p className={styles.textDetails}>{activity.description}</p>
        </div>

        {!activity.closed ? (
          <div className={styles.previewClosed}>
            <img
              className={styles.iconClose}
              src={OpenActivity}
              alt="icon-event-opened"
            />
            <p className={styles.textDetails}>
              This activity is open to others
            </p>
          </div>
        ) : (
          <div className={styles.previewClosed}>
            <img
              className={styles.iconClose}
              src={ClosedActivity}
              alt="icon-event-closed"
            />
            <p className={styles.textDetails}>
              This activity is closed to others
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityPreview;

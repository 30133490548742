import * as styles from "./userResult.module.scss";
import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { User } from "../../../../api/usersAPI";
import UserAvatar from "../../../Common/UserAvatar";

interface Props {
  user: User;
}
const UserResult: FC<Props> = ({ user }) => {
  return (
    <div className={styles.container}>
      <UserAvatar firstName={user.firstName} lastName={user.lastName} />
      <div className={styles.userInfo}>
        <Typography variant="button">{`${user.firstName} ${user.lastName}`}</Typography>
        <Typography variant="overline" className={styles.text}>
          {user.role ?? ""}
        </Typography>
      </div>
    </div>
  );
};

export default UserResult;

// extracted by mini-css-extract-plugin
export var h1Typography = "post-module--h1-typography--2ig9P";
export var h2Typography = "post-module--h2-typography--23ywn";
export var h3Typography = "post-module--h3-typography--2iIc1";
export var h4Typography = "post-module--h4-typography--39Sol";
export var h5Typography = "post-module--h5-typography--rrE6x";
export var h6Typography = "post-module--h6-typography--1sTHY";
export var pTypography = "post-module--p-typography--2OQTN";
export var info = "post-module--info--3iuFq";
export var buttonTypography = "post-module--button-typography--1JP_V";
export var formLabelTypography = "post-module--form-label-typography--bCzBT";
export var menuTabTypography = "post-module--menu-tab-typography--sixg-";
export var linkStyle = "post-module--link-style--2BqLw";
export var postTypography = "post-module--post-typography--2-EUn";
export var topbarUser = "post-module--topbar-user--nynry";
export var time = "post-module--time--2qJM5";
export var text = "post-module--text--1hj_G";
export var container = "post-module--container--axjVu";
export var topbar = "post-module--topbar--PxH5N";
export var username = "post-module--username--2QBmA";
export var imgContainer = "post-module--img-container--2CP3L";
import { createAsyncThunk } from "@reduxjs/toolkit";
import client, { AsyncThunkConfig } from "../../api/axiosConfig";
import { dispatchEnqueueSnackbar } from "./notificationsActions";
import { handleAxiosError } from "../../utils/redux";

export interface ReminderUserArgs {
  id: number;
}

export const reminderUser = createAsyncThunk<
  void,
  ReminderUserArgs,
  AsyncThunkConfig
>("users/reminderUser", async ({ id }, thunkApi) => {
  try {
    await client.post(`/api/users/${id}/activation_reminder/`);

    dispatchEnqueueSnackbar({
      message: "Activation reminder was sent succesfully.",
    });
  } catch (err) {
    return handleAxiosError(err, thunkApi);
  }
});

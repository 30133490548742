// extracted by mini-css-extract-plugin
export var h1Typography = "createpost-module--h1-typography--1ZE2X";
export var h2Typography = "createpost-module--h2-typography--4lPuN";
export var h3Typography = "createpost-module--h3-typography--1SrOP";
export var h4Typography = "createpost-module--h4-typography--5tDMO";
export var h5Typography = "createpost-module--h5-typography--d2VPC";
export var h6Typography = "createpost-module--h6-typography--2IW8o";
export var pTypography = "createpost-module--p-typography--1snBj";
export var info = "createpost-module--info--1Flps";
export var buttonTypography = "createpost-module--button-typography--1d2bj";
export var formLabelTypography = "createpost-module--form-label-typography--278SZ";
export var menuTabTypography = "createpost-module--menu-tab-typography--3yeKI";
export var linkStyle = "createpost-module--link-style---BPO-";
export var postTypography = "createpost-module--post-typography--3yqqp";
export var container = "createpost-module--container--15unC";
export var textarea = "createpost-module--textarea--p36Tz";
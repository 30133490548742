import * as styles from "./employee.module.scss";
import React, { FC, useEffect, useState } from "react";
import Activities from "./Activities";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { StyledMenu, StyledMenuItem } from "./muiStylesEmployee";
import UserAvatar from "../../../Common/UserAvatar";
import { useLongPress } from "use-long-press";
import clsx from "clsx";
import { Link } from "gatsby";
import { useIsSmall } from "../../../../utils";
import EmployeeDisableConfirmationDialog from "../EmployeeDisableConfirmationDialog";
import { useDispatch } from "react-redux";
import { reminderUser } from "../../../../redux/actions/reminderUserActions";
import { profilePath } from "../../../../utils/paths";
import { IconButton } from "@material-ui/core";
import { StyledCheckbox } from "../../../Common/Checkbox";

const options = ["View profile", "Disable User"];

export interface UserProps {
  firstName: string;
  lastName: string;
  email: string;
  id: number;
  activityTypes?: number[];
  role?: string | null;
  team?: string | null;
  isActive?: boolean;
  checkedUsers: number[];
  modifyCheckedUsers(arg0: boolean, arg1: number): void;
}

const Employee: FC<UserProps> = ({
  firstName,
  lastName,
  activityTypes,
  isActive,
  role,
  team,
  id,
  checkedUsers,
  modifyCheckedUsers,
}) => {
  const dispatch = useDispatch();
  const isSmall = useIsSmall();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isUserChecked = (): boolean =>
    checkedUsers.some((iteradedId: number) => iteradedId === id);
  const [checked, setChecked] = React.useState<boolean>(isUserChecked());

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    modifyCheckedUsers(event.target.checked, id);
  };

  const handleThreeDotMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [disableConfirmation, setDisableConfirmation] = useState(false);
  const openDisableConfirmation = () => {
    setDisableConfirmation(true);
  };
  const onDisableConfirmationClose = () => setDisableConfirmation(false);

  const handleClose = (e: Event) => {
    const option = (e.target as HTMLInputElement).innerText;

    if (option === "Disable User") {
      openDisableConfirmation();
    }

    if (option === "Send Reminder") {
      dispatch(reminderUser({ id }));
    }

    setAnchorEl(null);
  };

  useEffect(() => {
    setChecked(isUserChecked());
  }, [checkedUsers]);

  const bind = useLongPress(() => {
    if (isSmall) {
      setChecked(!checked);
      modifyCheckedUsers(!checked, id);
    }
  });

  return (
    <>
      <EmployeeDisableConfirmationDialog
        ids={id}
        isOpen={disableConfirmation}
        onClose={onDisableConfirmationClose}
      />
      <div
        className={clsx(styles.userContainer, {
          [styles.userContainerChecked]: checked,
        })}
        {...bind}
      >
        <div className={styles.checkboxContainer}>
          <StyledCheckbox value={checked} onChange={handleCheckboxChange} />
        </div>
        <div className={styles.avatar}>
          <UserAvatar
            firstName={firstName}
            lastName={lastName}
            checked={checked}
          />
        </div>
        <div className={styles.userInfo}>
          <div className={styles.userName}>
            {firstName} {lastName}
            {isSmall ? (
              <span
                className={`${styles.isUserActiveCircle} ${
                  isActive ? styles.active : styles.pending
                }`}
              />
            ) : (
              ""
            )}
          </div>
          <div className={styles.userDepartment}>
            {team} {!!team && !!role && "/"} {role}
          </div>
          <span className={styles.activityContainer}>
            <Activities activityTypes={activityTypes} />
          </span>
        </div>
        {!isSmall ? (
          <div
            className={`${styles.isUserActiveContainer} ${
              isActive ? styles.active : styles.pending
            }`}
          >
            <div>{isActive ? "Active" : "Pending"}</div>
          </div>
        ) : (
          ""
        )}

        <div className={styles.menuButton}>
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleThreeDotMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {!isActive ? (
                <StyledMenuItem
                  onClick={(e) => handleClose((e as unknown) as Event)}
                >
                  Send Reminder
                </StyledMenuItem>
              ) : null}
              {options.map((option) => {
                return option === "View profile" ? (
                  <Link
                    key={`${option}${id}`}
                    to={`${profilePath}/${id}`}
                    className={styles.linkStyle}
                  >
                    <StyledMenuItem
                      key={option}
                      onClick={(e) => handleClose((e as unknown) as Event)}
                    >
                      {option}
                    </StyledMenuItem>
                  </Link>
                ) : (
                  <StyledMenuItem
                    className={styles.disableUser}
                    key={`${option}${id}`}
                    onClick={(e) => handleClose((e as unknown) as Event)}
                  >
                    {option}
                  </StyledMenuItem>
                );
              })}
            </StyledMenu>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employee;

import { Dialog, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { muiStyles } from "./muiStyles";
import DeletePopup from "../../../Common/Popups/DeletePopup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createPost,
  editPost,
} from "../../../../redux/actions/pulse/pulsePostActions";
import { selectPostById } from "../../../../redux/selectors/pulse/pulsePostsSelectors";
import { Header } from "./Header";
import { Content, PostForm } from "./Content";
import { Footer } from "./Footer";
import { extractFilenameFromURL } from "../../../../utils/pulse";
import { dispatchEnqueueSnackbar } from "../../../../redux/actions/notificationsActions";
import { replaceOldCDN } from "../../../../utils/emoji";

const useStyles = makeStyles(muiStyles);

interface Props {
  open: boolean;
  onClose: () => void;
  postId?: number;
}

const defaultBody = "<p><br></p>";

export const PostModal: FC<Props> = ({ open, onClose, postId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const postData = useSelector(selectPostById(postId));
  const [openDiscardConfirmation, setOpenDiscardConfirmation] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
  } = useForm<PostForm>({
    mode: "all",
  });

  useEffect(() => {
    register("body"); // react-quill integration
  }, [register]);

  const mediumValue = watch("medium"); // a value of 'medium' input
  const bodyValue = watch("body"); // a value of 'body' - required for integration with react-quill

  useEffect(() => {
    if (open) {
      // set defaults on dialog opening
      setValue("body", replaceOldCDN(postData?.body) || defaultBody);
      setValue("medium", postData?.medium ? null : undefined); // for EDITING: differentiate between file-not-changed and file-removed
      // Comment: since a file uploaded to server is visible as the URL only in the Post data, it's impossible to
      //          set a default value for the file input (`medium`) to match what's on the server. The implemented
      //          solution uses different `medium` defaults:
      //          - `null` -> the medium is present on the server; if `medium` input in the form is not changed
      //                      (no file is uploaded in the edit form AND the file's representation was not removed
      //                      using the `Card` component), the updating PATCH request won't contain `medium` key.
      //          - `undefined` -> the medium is not present on the server.
    }
  }, [open]);

  // const isBodyDirty = bodyValue !== defaultBody;
  const isBodyDirty = postData?.body
    ? bodyValue !== postData.body
    : bodyValue !== defaultBody;
  const isMediumDirty = postData?.medium
    ? mediumValue !== null
    : mediumValue !== undefined;
  const isDirty = isBodyDirty || isMediumDirty;

  const submit = handleSubmit((data: PostForm) => {
    if (postId) {
      // Edit the Post

      // --- handle medium in the edit form ---
      let medium; // by default: medium is undefined -> won't be changed by edit post's PATCH request

      if (data.medium && data.medium.length) {
        // eslint-disable-next-line prefer-destructuring
        medium = data.medium[0]; // if a file was provided in the form -> update medium with the provided file
      } else if (data.medium === undefined) {
        medium = null; // if the file was deleted in the form -> PATCH the Post with `null` in medium to remove the file from the server
      }
      // ---

      dispatch(
        editPost({
          id: postId,
          body: data.body,
          medium,
        })
      );
    } else {
      // Create a Post

      dispatch(
        createPost({
          body: data.body,
          medium:
            data.medium && data.medium.length ? data.medium[0] : undefined,
        })
      );
    }

    dispatchEnqueueSnackbar({
      message: "Wohoo! Your post is getting uploaded...",
    });
    onClose();
  });

  const onClosePostModal = () => {
    // display a discard confirmation, if a user started completing the form
    isDirty ? setOpenDiscardConfirmation(true) : onClose();
  };

  const onDiscardConfirm = () => {
    setOpenDiscardConfirmation(false);
    onClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={(event, reason) =>
          reason === "escapeKeyDown" && onClosePostModal()
        }
        className={classes.backgroundDialog}
        PaperProps={{ className: classes.dialogPaper }}
      >
        <Header onClose={onClosePostModal} />
        <Content
          register={register}
          setValue={setValue}
          body={bodyValue}
          mediumName={
            mediumValue === null
              ? extractFilenameFromURL(postData?.medium)
              : mediumValue && mediumValue[0] && mediumValue[0]?.name
          }
        />
        <Footer
          submit={submit}
          submitDisabled={!isDirty || isSubmitting} // manual form validation - react-quill does not work properly with react-hook-form validators
        />
      </Dialog>

      <DeletePopup
        content={"Are you sure you'd like to Discard this post?"}
        header={"Discard Post?"}
        open={openDiscardConfirmation}
        onAcceptButton={onDiscardConfirm}
        onCancelButton={() => setOpenDiscardConfirmation(false)}
      />
    </>
  );
};

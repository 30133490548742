import { createStyles } from "@material-ui/core/styles";
import { inputBackgroundColor } from "../../../../styles/mui/theme";

const muiStylesMobileSearchTopBar = () =>
  createStyles({
    root: {
      position: "sticky",
      top: "0",
    },
    search: {
      "margin-top": "10px",
      "margin-bottom": "10px",
      "width": "100%",
      "margin-left": "25px",
    },
    input: {
      "& .MuiFilledInput-input": {
        padding: "15px 10px",
      },
      "& .MuiFilledInput-adornedStart": {
        "padding-left": "0px",
      },
      "& .MuiFilledInput-root": {
        background: inputBackgroundColor,
      },
      "& .MuiFilledInput-underline:before": {
        "border-bottom": "1px solid transparent",
      },
    },
  });

export default muiStylesMobileSearchTopBar;

import { Theme, createStyles } from "@material-ui/core/styles";

const muiStylesActivities = () =>
  createStyles({
    tooltip: {
      whiteSpace: "break-spaces",
      fontSize: "1.2em",
    },
  });

export default muiStylesActivities;

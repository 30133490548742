import { Avatar, createStyles, withStyles } from "@material-ui/core";

const StatusBadge = withStyles(() =>
  createStyles({
    root: {
      width: 20,
      height: 20,
    },
  })
)(Avatar);

export default StatusBadge;

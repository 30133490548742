import { createStyles } from "@material-ui/core/styles";

const muiStylesMobileSearchResults = () =>
  createStyles({
    text: {
      "font-size": "2rem",
      "margin-bottom": "15px",
    },
  });

export default muiStylesMobileSearchResults;

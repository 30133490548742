import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { FC } from "react";
import { muiStylesDialog } from "../OrganizationStructure/muiStylesOrganizationStructure";
import { muiStylesButtons } from "./muiStylesCompanySettings";

const useButtonStyles = makeStyles(muiStylesButtons);
const useDialogStyles = makeStyles(muiStylesDialog);

interface DiscardDialogProps {
  isOpen: boolean;
  onClose: () => void;
  action: () => void;
}

export const DiscardDialog: FC<DiscardDialogProps> = ({
  isOpen,
  onClose,
  action,
}) => {
  const buttonClasses = useButtonStyles();
  const classes = useDialogStyles();
  const confirmAction = () => {
    onClose();
    action();
  };

  return (
    <Dialog
      className={classes.backgroundDialog}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <h5>Discard Changes?</h5>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography paragraph color="textSecondary" variant="subtitle1">
          Leaving this page will result in canceled changes.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={onClose}
          className={buttonClasses.secondaryButton}
          variant="outlined"
        >
          Keep Editing
        </Button>
        <Button onClick={confirmAction} className={buttonClasses.warningButton}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { ActivitiesParams, Activity } from "../../api/activitiesAPI";
import { RootState } from "../store";
import { isToday } from "../../utils";
import { MinifiedUser } from "../../api/usersAPI";

interface ProcessedMonth {
  days: DayObject[];
  monthName: string;
  monthNumber: number;
  year: number;
}

interface DayObject {
  activities: ProcessedActivity[];
  dayName: string;
  dayNumber: number;
  isToday: boolean;
}

export interface ProcessedActivity {
  endDate: string;
  endTime: string;
  id: number;
  location: string;
  startDate: string;
  startTime: string;
  title: string;
  attendees: MinifiedUser[];
  owner: MinifiedUser;
  closed: boolean;
}

interface FullRecord {
  id: number;
  location: string;
  title: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  year: number;
  monthNumber: string;
  monthName: string;
  dayName: string;
  dayNumber: number;
  isToday: boolean;
  attendees: MinifiedUser[];
  owner: MinifiedUser;
  closed: boolean;
}

const fullRecordsToDayRecord: (acc: DayObject, fr: FullRecord) => DayObject = (
  acc,
  {
    dayName,
    dayNumber,
    isToday: _isToday,
    endDate,
    endTime,
    id,
    location,
    startDate,
    startTime,
    title,
    attendees,
    owner,
    closed,
  }
) => {
  if (acc.activities) {
    acc.activities.push({
      id,
      title,
      location,
      startDate,
      startTime,
      endDate,
      endTime,
      attendees,
      owner,
      closed,
    });
  } else {
    acc = {
      activities: [
        {
          id,
          title,
          location,
          startDate,
          startTime,
          endDate,
          endTime,
          attendees,
          owner,
          closed,
        },
      ],
      dayName,
      dayNumber,
      isToday: _isToday,
    };
  }

  return acc;
};

const keyFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

const getDayKey = (startDate: string) => {
  const date: Date = new Date(startDate);
  const {
    year,
    month,
    day,
  }: Record<string, string> = keyFormatter
    .formatToParts(date)
    .reduce(
      (acc: Partial<Record<Intl.DateTimeFormatPartTypes, string>>, cur) =>
        Object.assign(acc, { [cur.type]: cur.value }),
      {}
    );

  return `${year}${month}${day}`;
};

const dateStringToTime = (date: string) =>
  new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

const getDateTimeProps = (startDate: string, endDate: string) => {
  const date = new Date(startDate);

  return {
    startDate,
    endDate,
    startTime: dateStringToTime(startDate),
    endTime: dateStringToTime(endDate),
    year: date.getFullYear(),
    monthNumber: `${date.getFullYear()}${date.getMonth()}`,
    monthName: date.toLocaleString("en-US", { month: "long" }),
    dayName: date
      .toLocaleDateString("en-US", { weekday: "long" })
      .substring(0, 3),
    dayNumber: date.getDate(),
    isToday: isToday(date),
  };
};

export const selectMobileCalendarActivities = (
  state: RootState
): ProcessedMonth[] => {
  const activitiesGroupedByDay = state.activities.activities.reduce(
    (acc: Record<string, FullRecord[]>, activity) => {
      const dayKey = getDayKey(activity.startDate);

      if (!(dayKey in acc)) {
        acc[dayKey] = [];
      }

      acc[dayKey].push({
        id: activity.id,
        location: activity.location,
        title: activity.title,
        attendees: activity.attendees,
        owner: activity.owner,
        closed: activity.closed,
        ...getDateTimeProps(activity.startDate, activity.endDate),
      });

      return acc;
    },
    {}
  );

  let lastAddedMonthNumber = "";

  return Object.entries(activitiesGroupedByDay)
    .sort(([keyA], [keyB]) => Number(keyA) - Number(keyB))
    .reduce((acc: ProcessedMonth[], [, dayActivities]) => {
      const [{ monthNumber }] = dayActivities;

      if (lastAddedMonthNumber === monthNumber) {
        acc[acc.length - 1].days.push(
          dayActivities.reduce(fullRecordsToDayRecord, <DayObject>{})
        );
      } else {
        lastAddedMonthNumber = monthNumber;
        const [{ monthName, year }] = dayActivities;

        acc.push({
          days: [dayActivities.reduce(fullRecordsToDayRecord, <DayObject>{})],
          monthName,
          monthNumber: Number(monthNumber),
          year,
        });
      }

      return acc;
    }, []);
};

export const selectNextMobileCalendarRequestParams = (
  state: RootState
): ActivitiesParams => {
  if (state.activities && state.activities.nextParams) {
    const { limit, offset } = state.activities.nextParams;

    return { limit, offset };
  }

  return {};
};

export const selectDesktopCalendarActivities = (state: RootState) =>
  state.activities.activities.map((activity: Activity) => ({
    id: activity.id,
    title: activity.title,
    allDay: false,
    start: new Date(activity.startDate),
    end: new Date(activity.endDate),
    owner: activity.owner,
    attendees: activity.attendees,
    closed: activity.closed,
  }));

export const selectActivitiesLoading = (state: RootState) =>
  state.activities.loading;

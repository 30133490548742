// extracted by mini-css-extract-plugin
export var h1Typography = "accountSettings-module--h1-typography--2x1Hi";
export var h2Typography = "accountSettings-module--h2-typography--3hixr";
export var h3Typography = "accountSettings-module--h3-typography--3ASi-";
export var h4Typography = "accountSettings-module--h4-typography--bXlcK";
export var h5Typography = "accountSettings-module--h5-typography--3f63k";
export var h6Typography = "accountSettings-module--h6-typography--16wDr";
export var pTypography = "accountSettings-module--p-typography--UeDOe";
export var info = "accountSettings-module--info--1gbEe";
export var buttonTypography = "accountSettings-module--button-typography--1_sTc";
export var formLabelTypography = "accountSettings-module--form-label-typography--3bjC_";
export var menuTabTypography = "accountSettings-module--menu-tab-typography---PKAw";
export var linkStyle = "accountSettings-module--link-style--1yZLc";
export var postTypography = "accountSettings-module--post-typography--2m7GY";
export var header = "accountSettings-module--header--2tsRL";
export var headerDesktop = "accountSettings-module--header-desktop--214gh";
export var email = "accountSettings-module--email--1eiPL";
export var accountSettingsPageContainer = "accountSettings-module--account-settings-page-container--2heXa";
export var accountSettingsContainer = "accountSettings-module--account-settings-container--3KiOt";
export var section = "accountSettings-module--section--b892R";
export var sectionNav = "accountSettings-module--section-nav--9Yqa_";
export var sectionHeader = "accountSettings-module--section-header--14DZH";
export var sectionText = "accountSettings-module--section-text--18kNy";
export var navButton = "accountSettings-module--nav-button--3SDOO";
export var avatarContainer = "accountSettings-module--avatar-container--rHaZJ";
export var infoContainer = "accountSettings-module--info-container--2hWhG";
export var lastHeader = "accountSettings-module--last-header--M67sZ";
export var contact = "accountSettings-module--contact--17OXH";
export var sectionTeam = "accountSettings-module--section-team--3BIpn";
export var profileContainer = "accountSettings-module--profile-container--3krP_";
export var sectionNac = "accountSettings-module--section-nac--gJKKi";
export var departmentContainer = "accountSettings-module--department-container--1sbWn";
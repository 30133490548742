import React, { FC } from "react";
import PulseFeed from "../components/Pulse";
import Seo from "../components/seo";
import Div100vh from "react-div-100vh";
import StandardLayout from "../components/Layout/StandardLayout";

const PulsePage: FC = () => (
  <Div100vh>
    <StandardLayout>
      <Seo title="Pulse" />
      <PulseFeed />
    </StandardLayout>
  </Div100vh>
);

export default PulsePage;

import client, {
  ApiError,
  ApiResponse,
  isAxiosError,
} from "../../api/axiosConfig";
import { useCallback, useState } from "react";
import { Method } from "axios";
import { dispatchGeneralErrorNotification } from "../redux";
import { dispatchEnqueueSnackbar } from "../../redux/actions/notificationsActions";

interface Payload {
  readonly [key: string]: any;
}

interface UseRequestData {
  url: string;
  method?: Method;
  payload?: Payload;
}

interface Response<T> {
  data?: T;
  error?: string;
  isLoading: boolean;
}

export const useRequest = <T>({
  url,
  payload,
  method,
}: UseRequestData): [Response<T>, () => void] => {
  const defaultRes = {
    data: undefined,
    error: undefined,
    isLoading: false,
  };

  const [res, setRes] = useState<Response<T>>(defaultRes);

  const callApi: () => void = useCallback(() => {
    setRes({...defaultRes, isLoading:true});
    client
      .request<ApiResponse<T>>({ url, method, data: payload })
      .then((response) => {
        setRes({
          data: response.data.results,
          isLoading: false,
          error: undefined,
        });

        return;
      })
      .catch((error) => {
        if (isAxiosError(error) && error.response != null) {
          const errResp = error.response.data as ApiError;

          // show error message to the user
          dispatchEnqueueSnackbar({
            message: `Your request failed: ${errResp.message}`,
          });

          setRes({
            data: undefined,
            isLoading: false,
            error: errResp.message,
          });
        } else {
          dispatchGeneralErrorNotification();

          setRes({
            data: undefined,
            isLoading: false,
            error: "Unrecognized error",
          });
          // show general error message to the user for an unrecognized error
        }
      });
  }, [url, method, payload]);

  return [res, callApi];
};

import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectAuthUser } from "../../../../redux/selectors/authSelectors";
import UserAvatar from "../../../Common/UserAvatar";
import * as styles from "./createpost.module.scss";

interface Props {
  onClick: () => void;
}

const CreatePost: FC<Props> = ({ onClick }) => {
  const userData = useSelector(selectAuthUser);

  return (
    <div className={styles.container}>
      <UserAvatar
        firstName={userData?.firstName}
        lastName={userData?.lastName}
      />
      <div className={styles.textarea} onClick={onClick}>
        Go for it, {userData?.firstName}
      </div>
    </div>
  );
};

export default CreatePost;

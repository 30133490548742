import { DialogTitle, IconButton, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { muiStyles } from "../muiStyles";

const useStyles = makeStyles(muiStyles);

interface Props {
  onClose: () => void;
}

export const Header: FC<Props> = ({ onClose }) => {
  const classes = useStyles();

  return (
    <DialogTitle disableTypography className={classes.dialogTitle}>
      <h5>Share Pulse</h5>
      <IconButton size="small" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

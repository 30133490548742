import { Theme, createStyles } from "@material-ui/core/styles";
import {
  modalBackgroundColor,
  primaryColor,
  primaryColorHover,
  secondaryColor,
  textInputBackground,
} from "../../styles/mui/theme";

export const muiStylesButtons = () =>
  createStyles({
    primaryButton: {
      "color": secondaryColor,
      "border": `1px solid ${primaryColor}`,
      "backgroundColor": primaryColor,
      "textTransform": "initial",
      "&:hover": {
        backgroundColor: primaryColorHover,
      },
    },
  });

export const muiStylesDialog = (theme: Theme) =>
  createStyles({
    backgroundDialog: {
      "& .MuiBackdrop-root": { "background-color": modalBackgroundColor },
    },
    dialogPaper: {
      borderRadius: 8,
    },
    dialogContent: {
      "padding": "0 40px 0 24px",
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
      "& .MuiTypography-paragraph": {
        padding: "0 1rem",
      },
      "& .MuiFormHelperText-root.Mui-error:first-letter": {
        textTransform: "uppercase",
      },
      "& .MuiFilledInput-root": {
        "background": textInputBackground,
        "&:hover": {
          background: textInputBackground,
        },
        "&:focus": {
          background: textInputBackground,
        },
        "&:autofill": {
          background: textInputBackground,
        },
        "&&&:before": {
          borderBottom: "none",
        },
      },
    },
    dialogTitle: {
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "flex-start",
      "paddingLeft": "40px",
      "paddingBottom": 0,
      "& .MuiIconButton-root": {
        position: "relative",
        left: ".5rem",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "1rem",
      },
      "& h5": {
        marginTop: "1.75rem",
        marginBottom: "1rem",
        [theme.breakpoints.down("sm")]: {
          margin: 0,
        },
      },
    },
    dialogActions: {
      "padding": "20px 40px",
      [theme.breakpoints.down("sm")]: {
        padding: "1rem",
      },
      "& .MuiButton-root": {
        width: "130px",
        padding: "6px 40px",
        [theme.breakpoints.down("sm")]: {
          padding: "6px 12px",
        },
      },
    },
  });
